import { PaginatedColumn } from "../types/app";
import { WhatsappRequest, WhatsappRequestTouched } from "../types/whatsapp";

export const WHATSAPP_TABLE_COLUMNS_NAME: PaginatedColumn[] = [
  {
    label: 'Nombre',
    key: 'name',
    minWidth: 180,
    flex: 1
  },
  {
    label: 'Ejecutado',
    key: 'executed',
    centered: true,
    minWidth: 100,
    maxWidth: 100,
  },
  {
    label: 'Creado',
    key: 'created',
    centered: true,
    minWidth: 140,
    maxWidth: 140,
  },
  {
    label: 'Acciones',
    key: 'actions',
    centered: true,
    minWidth: 120,
    maxWidth: 120,
  },
];

export const WHATSAPP_RECORDS_TABLE_COLUMNS_NAME: PaginatedColumn[] = [
  {
    label: 'Numero',
    key: 'number',
    minWidth: 160,
    flex: 1
  },
  {
    label: 'Errores',
    key: 'erros',
    minWidth: 160,
    flex: 1
  },
  {
    label: 'Es valido',
    key: 'isValid',
    centered: true,
    minWidth: 100,
    maxWidth: 100,
  },
  {
    label: 'Procesado',
    key: 'processedAt',
    centered: true,
    minWidth: 140,
    maxWidth: 140,
  },
];

export const WHATSAPP_FILE_EXAMPLE = 'public/whatsappCampaignExamples/whatsapp_campaign.csv'

export const WHATSAPP_DEFAULTS: WhatsappRequest = {
  name: '',
  template: '',
};

export const WHATSAPP_TOUCHED_DEFAULTS: WhatsappRequestTouched = {
  nameTouched: false,
  fileTouched: false,
  templateTouched: false,
};
