import { EllipsedText, FlexColumn, LabeledData } from '@gorila-shared-ui/components';
import { useMemo } from 'react';
import { WEBHOOK_METHODS, WEBHOOK_TYPES } from '../../constants/webhooks';
import { Webhook } from '../../types/webhook';

type Props = {
  webhook: Webhook;
};
export function WebhookInfo({ webhook }: Readonly<Props>) {
  const LABEL_WIDTH = '90px';

  const jsonStringValue = useMemo(() => {
    try {
      return JSON.stringify(webhook.headers);
    } catch (error) {
      return '{}';
    }
  }, [webhook]);

  return (
    <FlexColumn>
      <LabeledData
        label={'Url:'}
        content={webhook.endpoint}
        labelWidth={LABEL_WIDTH}
      />
      <LabeledData
        label={'Metodo:'}
        content={WEBHOOK_METHODS[webhook.method] ?? webhook.method}
        labelWidth={LABEL_WIDTH}
      />
      <LabeledData
        label={'Encabezados:'}
        content={<EllipsedText maxLines={0}>{jsonStringValue}</EllipsedText>}
        labelWidth={LABEL_WIDTH}
      />
      <LabeledData
        label={'Tipo:'}
        content={WEBHOOK_TYPES[webhook.webhookType] ?? webhook.webhookType}
        labelWidth={LABEL_WIDTH}
      />
      <LabeledData
        label={'Id externo:'}
        content={webhook.externalId ?? '-'}
        labelWidth={LABEL_WIDTH}
      />
    </FlexColumn>
  );
}
