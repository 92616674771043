import { EmptyState, FlexColumn, Loading } from '@gorila-shared-ui/components';
import { useStyles } from '../../hooks/useStyles';
import { PaginatedColumn, PaginatedData } from '../../types/app';
import { StyledNoPaginatedTable } from './StyledNoPaginatedTable';
import { StyledPaginatedTable } from './StyledPaginatedTable';

type Props = {
  columns: PaginatedColumn[];
  data?: PaginatedData[];
  hasData: boolean;
  isEmpty: boolean;
  loading: boolean;
  noDataDescription: string;
  onPageChange?: (nextPage: number) => void;
  page?: number;
  totalItems?: number;
  hasSearch?: boolean;
};

export function ListWrapper({
  columns,
  data = [],
  hasData,
  isEmpty,
  loading,
  noDataDescription,
  onPageChange,
  page,
  totalItems,
  hasSearch = true,
}: Readonly<Props>) {
  const { css, classes, concatenateClasses } = useStyles();

  const wrapperStyles = concatenateClasses(classes.flexFill, css({ overflow: 'hidden' }));
  const isPaginated = !!onPageChange && !!page && !!totalItems;

  return (
    <FlexColumn classNames={wrapperStyles}>
      {!hasData && !loading && <EmptyState title={noDataDescription} />}
      {!hasData && loading && <Loading />}
      {hasData && (
        <>
          {!isEmpty && isPaginated && (
            <StyledPaginatedTable
              columns={columns}
              data={data}
              totalItems={totalItems}
              page={page}
              onPageChange={onPageChange}
              loading={loading}
            />
          )}
          {!isEmpty && !isPaginated && (
            <StyledNoPaginatedTable
              columns={columns}
              data={data}
              loading={loading}
            />
          )}
          {isEmpty && (
            <EmptyState
              title="No se encontraron resultados"
              description={hasSearch ? 'Intenta con otra busqueda' : undefined}
            />
          )}
        </>
      )}
    </FlexColumn>
  );
}
