import { EnhancedLabeledSelect } from '@gorila-shared-ui/components';
import { OnChangeParams } from 'baseui/select';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDebounceFunction } from '../../../hooks/useDebounce';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import { useLoading } from '../../../hooks/useLoading';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { getSubBrandsList } from '../../../services/vehicleService';
import { SubBrandCore } from '../../../types/subBrand';

type Props = {
  subBrand?: SubBrandCore;
  selectedBrandId?: string;
  selectedSubBrandId?: string;
  onChangeSubBrand: (subBrandId?: string) => void;
  isFilter?: boolean;
  label?: string;
  disabled?: boolean;
  error?: React.ReactNode;
  onBlur?: () => void;
  inline?: boolean;
  clearable?: boolean;
  required?: boolean;
};
export function SubBrandSelect({
  selectedBrandId,
  selectedSubBrandId,
  onChangeSubBrand,
  subBrand,
  isFilter = true,
  label = 'Submarca:',
  disabled = false,
  error,
  onBlur,
  inline,
  clearable = false,
  required = false,
}: Readonly<Props>) {
  const [subBrandsList, setSubBrandsList] = useState<SubBrandCore[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [page, setPage] = useState<number | undefined>(undefined);
  const [hasNextPage, setHasNextPage] = useState(false);
  const { search, setSearch, debouncedSearch } = useDebouncedSearch('');
  const [brandId, setBrandId] = useState<string>();
  const [selectedSubBrandOption, setSelectedSubBrandOption] = useState<{ id: string; label: string }>();
  const [initialBrandSearch, setInitialBrandSearch] = useState(false);
  const requestIdRef = useRef(0);

  useUpdateEffect(() => {
    setPage(0);
  }, [debouncedSearch, brandId]);

  useEffect(() => {
    if (page === undefined) return;
    if (page === 0) {
      setPage(1);
      return;
    }
    const loadSubBrands = async () => {
      startLoading();
      const requestId = ++requestIdRef.current;
      const { subBrands, error, hasNext } = await getSubBrandsList(page, search, brandId);
      if (requestId === requestIdRef.current) {
        if (!error && subBrands) {
          if (page > 1 && subBrandsList) {
            setSubBrandsList([...subBrandsList, ...subBrands]);
            setHasNextPage(hasNext);
          } else {
            setSubBrandsList(subBrands);
            setHasNextPage(hasNext);
          }
        } else {
          setSubBrandsList(undefined);
          setHasNextPage(false);
        }
        stopLoading();
      }
    };
    loadSubBrands();
  }, [page]);

  useEffect(() => {
    if (brandId && selectedSubBrandId) onChangeSubBrand();
    setBrandId(selectedBrandId);
  }, [selectedBrandId]);

  const subBrandOptions = useMemo(() => {
    if (!subBrandsList) return;
    const subBrandsOptions: { id: string | undefined; label: string }[] = subBrandsList.map((subBrand) => ({
      id: subBrand._id,
      label: subBrand.name,
    }));
    if (!required) {
      subBrandsOptions?.unshift({
        id: undefined,
        label: isFilter ? 'Todas' : 'Ninguna',
      });
    }
    if (subBrand) {
      if (subBrand._id && !subBrandsOptions.find((c) => c.id === subBrand._id)) {
        subBrandsOptions.push({
          id: subBrand._id,
          label: subBrand.name,
        });
      } else if (subBrand.name && !subBrand._id) {
        const subBrandFind = subBrandsOptions.find(
          (c) => c.label.toLowerCase().trim() === subBrand.name.toLowerCase().trim()
        );
        if (subBrandFind) {
          onChangeSubBrand(subBrandFind.id);
          setSelectedSubBrandOption(subBrandFind as { id: string; label: string });
        } else if (search !== subBrand.name && !initialBrandSearch) {
          setSearch(subBrand.name);
          setInitialBrandSearch(true);
        } else {
          setSearch('');
          onChangeSubBrand(undefined);
        }
      }
    }
    if (selectedSubBrandOption) {
      if (!subBrandsOptions.find((c) => c.id === selectedSubBrandOption.id))
        subBrandsOptions.push(selectedSubBrandOption);
    }
    return subBrandsOptions;
  }, [subBrandsList]);

  const onSubBrandChange = (params: OnChangeParams) => {
    params.option?.id ? onChangeSubBrand(params.option.id as string) : onChangeSubBrand(undefined);
    setSelectedSubBrandOption(params.option as { id: string; label: string });
    setSearch(undefined);
  };

  const fetchData = () => {
    if (hasNextPage) setPage((page ?? 0) + 1);
  };

  const handleInputChange = useDebounceFunction(function (term: string) {
    setSearch(term);
  }, 500);

  return (
    <EnhancedLabeledSelect
      label={label}
      options={subBrandOptions}
      value={selectedSubBrandId !== undefined ? [{ id: selectedSubBrandId }] : undefined}
      onChange={onSubBrandChange}
      onInputChange={(e) => {
        handleInputChange(e.target.value);
      }}
      fullWidth
      infiniteScroll
      fetchData={fetchData}
      fetchDataLoading={loading}
      hasNext={hasNextPage}
      onBlur={() => {
        setSearch(undefined);
        onBlur && onBlur();
      }}
      searchable
      inline={inline}
      inset={inline}
      error={error}
      required={required}
      clearable={clearable}
      placeholder="Selecciona una submarca"
      disabled={disabled}
    />
  );
}
