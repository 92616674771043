import { FlexRow, MaterialIcon, StyledSearchBar, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { memo, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useDebouncedSearch } from '../../hooks/useDebouncedSearch';
import { useStyles } from '../../hooks/useStyles';
import { commandsSearchFilterState } from '../../storage/commandFiltersState';
import CommandFilters from './CommandFilters';
import { useCommands } from './hooks/useCommands';

function CommandFiltersBar() {
  const { css, theme } = useStyles();
  const { resetFilters, onReload, hasActiveFilters, resetAllFilters, onDownload } = useCommands();
  const { search, setSearch } = useDebouncedSearch(useRecoilState(commandsSearchFilterState));

  useEffect(() => {
    return () => {
      resetAllFilters();
    };
  }, []);

  return (
    <FlexRow
      classNames={css({ alignItems: 'center' })}
      gap={'4px'}
    >
      <FlexRow
        classNames={css({ flex: '1', alignItems: 'center' })}
        gap={'4px'}
      >
        <span className={css({ flexGrow: '0.5' })}>
          <StyledSearchBar
            name={'command-search'}
            value={search}
            onChange={setSearch}
            clearable
          />
        </span>
        <CommandFilters />
        <StyledTooltip
          content={'Limpiar filtros'}
          showArrow={false}
        >
          <Button
            size="mini"
            shape="round"
            onClick={resetFilters}
            kind="tertiary"
            disabled={!hasActiveFilters}
          >
            <MaterialIcon
              name={'clear'}
              size="mini"
              color={!hasActiveFilters ? theme.colors.contentStateDisabled : undefined}
            />
          </Button>
        </StyledTooltip>
      </FlexRow>
      <StyledTooltip
        content={'Descargar'}
        showArrow={false}
      >
        <Button
          size="mini"
          shape="round"
          onClick={onDownload}
          kind="tertiary"
        >
          <MaterialIcon
            name={'download_for_offline'}
            size="mini"
          />
        </Button>
      </StyledTooltip>
      <StyledTooltip
        content={'Recargar tabla'}
        showArrow={false}
      >
        <Button
          size="compact"
          shape="square"
          onClick={onReload}
          kind="tertiary"
        >
          <MaterialIcon name={'refresh'} />
        </Button>
      </StyledTooltip>
    </FlexRow>
  );
}

export default memo(CommandFiltersBar);
