import { FlexColumn, LabeledIconData } from '@gorila-shared-ui/components';
import { ASSET_TYPE } from '../../constants/asset';
import { useMoment } from '../../hooks/useMoment';
import { useStyles } from '../../hooks/useStyles';
import { Asset } from '../../types/asset';

type Props = {
  asset: Asset;
  fullData?: boolean;
};
export function AssetDataResume({ asset, fullData = true }: Readonly<Props>) {
  const { classes } = useStyles();
  const { formatDateFromTs } = useMoment();

  return (
    <FlexColumn
      classNames={classes.flexFill}
      gap={'8px'}
    >
      {asset.device && (
        <LabeledIconData
          label="Dispositivo:"
          data={asset.device.ident}
          icon="tablet"
        />
      )}
      {!!asset.secondaries?.length && (
        <LabeledIconData
          label="Dispositivos Secundarios:"
          data={asset.secondaries.map((secondarie) => secondarie.ident)}
          icon="devices"
        />
      )}
      {asset.assetType && (
        <LabeledIconData
          label="Tipo:"
          data={ASSET_TYPE[asset.assetType] ?? asset.assetType}
          icon="devices_other"
        />
      )}
      {asset.client && (
        <LabeledIconData
          label="Cliente:"
          data={asset.client.name}
          icon="business_center"
        />
      )}
      {asset.subClient && (
        <LabeledIconData
          label="Subcliente:"
          data={asset.subClient.name}
          icon="business_center"
        />
      )}
      {fullData && (
        <>
          <LabeledIconData
            label="En trayecto:"
            data={asset?.inJourney ? 'Si' : 'No'}
            icon="assistant_direction"
          />
          {asset.journeyId && asset.inJourney && (
            <LabeledIconData
              label="Trayecto (ID):"
              data={asset.journeyId}
              icon="location_on"
            />
          )}
        </>
      )}
      <LabeledIconData
        label="Eliminado:"
        data={asset?.deleted ? 'Si' : 'No'}
        icon="delete"
      />
      <LabeledIconData
        label="Seguimiento:"
        data={asset?.monitoring ? 'Si' : 'No'}
        icon="nest_cam_outdoor"
      />
      {asset?.insuranceNumber && (
        <LabeledIconData
          label="Póliza:"
          data={asset?.insuranceNumber}
          icon="description"
        />
      )}
      {asset?.insuranceStartDate && (
        <LabeledIconData
          label="Inicio vigencia:"
          data={formatDateFromTs(asset?.insuranceStartDate)}
          icon="calendar_clock"
        />
      )}
      {asset?.insuranceEndDate && (
        <LabeledIconData
          label="Fin vigencia:"
          data={formatDateFromTs(asset?.insuranceEndDate)}
          icon="calendar_clock"
        />
      )}
    </FlexColumn>
  );
}
