import { Card } from 'baseui/card';
import { ConfigurationOverride } from 'baseui/helpers/overrides';

type Props = {
  children: React.ReactNode;
  rootStyleOverrides?: ConfigurationOverride;
  contentsStyleOverrides?: ConfigurationOverride;
  bodyStyleOverrides?: ConfigurationOverride;
};

export function StyledCard({
  children,
  rootStyleOverrides = {},
  contentsStyleOverrides = {},
  bodyStyleOverrides = {},
}: Props) {
  return (
    <Card
      overrides={{
        Root: {
          style: {
            flexShrink: 0,
            boxSizing: 'border-box',
            borderLeftWidth: '1px',
            borderRightWidth: '1px',
            borderTopWidth: '1px',
            borderBottomWidth: '1px',
            ...rootStyleOverrides,
          },
        },
        Contents: {
          style: {
            marginTop: 0,
            marginRight: 0,
            marginBottom: 0,
            marginLeft: 0,
            ...contentsStyleOverrides,
          },
        },
        Body: {
          style: {
            marginBottom: 0,
            ...bodyStyleOverrides,
          },
        },
      }}
    >
      {children}
    </Card>
  );
}
