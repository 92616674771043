import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useStyles } from '../../../hooks/useStyles';
import { getHistoricCommands } from '../../../services/commandService';
import {
  commandsDateRangeState,
  commandsDownloadListState,
  commandsHasAnyFilterState,
  commandsOriginFilterState,
  commandsReloadListState,
  commandsSearchFilterState,
  commandsStatusFilterState,
  commandsTypeFilterState,
} from '../../../storage/commandFiltersState';
import { CommandOrigin } from '../../../types/command';

export function useCommands() {
  const { theme } = useStyles();
  const search = useRecoilValue(commandsSearchFilterState);
  const resetSearch = useResetRecoilState(commandsSearchFilterState);
  const [type, setType] = useRecoilState(commandsTypeFilterState);
  const resetType = useResetRecoilState(commandsTypeFilterState);
  const [dates, setDates] = useRecoilState(commandsDateRangeState);
  const resetDates = useResetRecoilState(commandsDateRangeState);
  const [status, setStatus] = useRecoilState(commandsStatusFilterState);
  const resetStatus = useResetRecoilState(commandsStatusFilterState);
  const [reload, setReload] = useRecoilState(commandsReloadListState);
  const hasActiveFilters = useRecoilValue(commandsHasAnyFilterState);
  const [origin, setOrigin] = useRecoilState(commandsOriginFilterState);
  const resetOrigin = useResetRecoilState(commandsOriginFilterState);
  const [downloadPage, setDownloadPage] = useRecoilState(commandsDownloadListState);

  const resetAllFilters = () => {
    resetSearch();
    resetFilters();
  };

  const resetFilters = () => {
    resetType();
    resetDates();
    resetStatus();
    resetOrigin();
  };

  const onReload = () => {
    setReload((prev) => !prev);
  };

  const onDownload = () => {
    setDownloadPage(true);
  };

  const loadHistoricCommands = async (
    page: number,
    search?: string,
    startDate?: number | null,
    endDate?: number | null,
    status?: string,
    commandType?: string,
    commandOrigin?: CommandOrigin,
    download?: boolean
  ) => {
    const { commands, error, total } = await getHistoricCommands(
      page,
      search,
      startDate,
      endDate,
      status,
      commandType,
      commandOrigin,
      download
    );
    return {
      commands,
      error,
      total,
    };
  };

  const getCommandStatusColor = (status?: string) => {
    if (status === 'success') return theme.colors.positive;
    if (status === 'pending') return theme.colors.warning;
    if (status === 'expired') return theme.colors.negative;
    return theme.colors.contentSecondary;
  };

  return {
    downloadPage,
    getCommandStatusColor,
    hasActiveFilters,
    loadHistoricCommands,
    onChangeCommandOrigin: setOrigin,
    onChangeCommandType: setType,
    onChangeDates: setDates,
    onChangeStatus: setStatus,
    onDownload,
    onReload,
    reload,
    resetAllFilters,
    resetFilters,
    search,
    selectedCommandOrigin: origin,
    selectedCommandsDateRange: dates,
    selectedCommandType: type,
    selectedStatus: status,
    setDownloadPage,
  };
}
