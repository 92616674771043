import { Centered, FlexColumn, Loading } from '@gorila-shared-ui/components';
import { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLoading } from '../../../hooks/useLoading';
import { useStyles } from '../../../hooks/useStyles';
import { getWhatsappDetail } from '../../../services/whatsappService';
import { Whatsapp } from '../../../types/whatsapp';
import { StyledModal } from '../../shared/StyledModal';
import { WhatsappDetailData } from '../WhatsappDetailData';

type Props = {
  isOpen: boolean;
  onClose: (update?: boolean) => void;
  whatsappId: string;
};

function DetailCampaignModal({ onClose, isOpen, whatsappId }: Props) {
  const [whatsapp, setWhatsapp] = useState<Whatsapp>();
  const { loading, startLoading, stopLoading } = useLoading();
  const { css } = useStyles();

  useEffect(() => {
    const loadData = async () => {
      startLoading();
      const { whatsapp, error } = await getWhatsappDetail(whatsappId);
      if (!error && whatsapp) {
        setWhatsapp(whatsapp);
      } else {
        setWhatsapp(undefined);
      }
      stopLoading();
    };
    loadData();
  }, []);

  return createPortal(
    <StyledModal
      isOpen={isOpen}
      onClose={onClose}
      closeable
      title={`Camapaña: ${whatsapp?.name}`}
      size="auto"
      content={
        <FlexColumn
          classNames={css({
            width: '75vw',
            maxWidth: '1200px',
            minWidth: '350px',
            boxSizing: 'border-box',
            padding: '1rem',
            borderTop: '1px solid rgb(226, 226, 226)',
            maxHeight: '80vh',
            overflow: 'hidden',
          })}
        >
          {loading && (
            <Centered>
              <Loading />
            </Centered>
          )}
          {!loading && whatsapp && <WhatsappDetailData whatsapp={whatsapp} />}
        </FlexColumn>
      }
    />,
    document.body
  );
}

export default memo(DetailCampaignModal);
