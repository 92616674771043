export function useClearStorage() {
  // const atomsToReset = [

  // ];

  const clearStorage = () => {
    // atomsToReset.forEach((resetAtom) => resetAtom());
    localStorage.clear();
  };

  return clearStorage;
}
