import { MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { CLIENT_USERS_TABLE_COLUMNS_NAME } from '../../../../constants/clients';
import { useLoading } from '../../../../hooks/useLoading';
import { useModal } from '../../../../hooks/useModal';
import useUpdateEffect from '../../../../hooks/useUpdateEffect';
import { getUsers } from '../../../../services/userService';
import { clientUsersSearchFilterState } from '../../../../storage/clients';
import { User } from '../../../../types/user';
import { ListWrapper } from '../../../shared/ListWrapper';
import UserDetailModal from '../../../users/modal/UserDetailModal';

type Props = {
  clientId: string;
  reloadPage?: boolean;
};

export function ClientUserList({ clientId, reloadPage }: Readonly<Props>) {
  const [users, setUsers] = useState<User[]>();
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const { loading, startLoading, stopLoading } = useLoading();
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const { openModal, closeModal, showModal } = useModal();
  const search = useRecoilValue(clientUsersSearchFilterState);

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage, search]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadUsersTableData = async () => {
      const response = await getUsers({
        clientId: clientId,
        page,
        q: search,
      });
      if (response?.error) {
        setUsers(undefined);
        setTotalItems(0);
      } else {
        setUsers(response?.users);
        setTotalItems(response?.total ?? 0);
      }
      stopLoading();
    };
    loadUsersTableData();
  }, [page]);

  const openUserModal = (userId: string) => {
    setSelectedUserId(userId);
    openModal();
  };

  const closeUserModal = (updateTable?: boolean) => {
    setSelectedUserId(undefined);
    closeModal();
    if (updateTable) setPage(0);
  };

  return (
    <>
      <ListWrapper
        columns={CLIENT_USERS_TABLE_COLUMNS_NAME}
        data={users?.map((user, i) => {
          return {
            items: [
              user.username,
              user?.client?.name ?? '-',
              user?.subClient?.name ?? '-',
              user?.subAccount?.name ?? '-',
              <StyledTooltip
                key={`user-action-${i}`}
                content={'Ver detalles'}
                showArrow={false}
              >
                <Button
                  size="compact"
                  shape="square"
                  onClick={() => openUserModal(user._id)}
                  kind="tertiary"
                >
                  <MaterialIcon name={'visibility'} />
                </Button>
              </StyledTooltip>,
            ],
          };
        })}
        hasData={users !== undefined}
        isEmpty={!users?.length}
        loading={loading}
        noDataDescription="Todavía no tienes ningun usuario"
        onPageChange={setPage}
        page={page}
        totalItems={totalItems}
      />
      {showModal && (
        <UserDetailModal
          onClose={(updateTable) => closeUserModal(updateTable)}
          isOpen={showModal}
          selectedUserId={selectedUserId}
          clientId={clientId}
        />
      )}
    </>
  );
}
