import { atom, selector } from 'recoil';
import { persistedAtom } from '../utils/persistence';
import { ADMIN_PAGE_ID, ALL_PAGES, EMISSIONS_PAGE_ID, EQUIPMENT_PAGE_ID, ROOT_PAGES, VEHICLE_PAGE_ID } from '../constants/app';
import { AuthUser } from '../types/authUser';
import { Key } from 'react';

export const appNameState = atom({
  key: 'appName',
  default: 'Gorila',
});

export const isLoggedInState = persistedAtom('app.loggedIn', false);

export const selectWorkspaceState = atom({
  key: 'app.workspace.selection',
  default: false,
});

export const sidenavIsPinnedState = persistedAtom('app.sidenav.pinned', true);

export const pagesState = selector({
  key: 'app.pages',
  get: () => {
    return ROOT_PAGES;
  },
});

export const pageState = persistedAtom<string>('app.page', '');

export const currentPageState = selector({
  key: 'app.pages.current',
  get: ({ get }) => {
    const currentPage = get(pageState);
    return ALL_PAGES.find((page) => page.id === currentPage) || ALL_PAGES[0];
  },
});

export const currentPagePathState = atom<string>({
  key: "app.page.current.path",
  default: undefined,
});


export const authUserState = persistedAtom<AuthUser | undefined>('app.auth.user', undefined);

export const sidenavSectionsState = atom<Key[]>({
  key: 'app.sidenav.sections',
  default: [ADMIN_PAGE_ID, EQUIPMENT_PAGE_ID, VEHICLE_PAGE_ID, EMISSIONS_PAGE_ID],
});
