export const WEB_SERVICE_LOGS_TABLE_COLUMNS_NAME = [
  {
    label: 'Tipo',
    key: 'wsType',
  },
  {
    label: 'Ident',
    key: 'ident',
  },
  {
    label: 'Creado',
    key: 'created',
  },
  {
    label: 'Acciones',
    key: 'actions',
  },
];
