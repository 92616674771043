import { FlexColumn, LabeledData } from '@gorila-shared-ui/components';
import { LabelMedium } from 'baseui/typography';
import { memo } from 'react';
import { useStyles } from '../../hooks/useStyles';
import { Container } from '../../types/container';

type Props = {
  container: Container;
};

function AssetVehicleData({ container }: Readonly<Props>) {
  const { theme, classes } = useStyles();

  return (
    <FlexColumn gap={theme.sizing.scale600}>
      <LabelMedium className={classes.dataHeaderClass}>Datos del contenedor</LabelMedium>
      <FlexColumn
        gap={theme.sizing.scale300}
        padding={'0px 0px 0px 2rem'}
      >
        {container.identifier && (
          <LabeledData
            label={<LabelMedium minWidth={'110px'}>{'Identificador:'}</LabelMedium>}
            content={container.identifier}
          />
        )}
      </FlexColumn>
    </FlexColumn>
  );
}

export default memo(AssetVehicleData);
