import { BaseProvider } from 'baseui';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import './App.css';
import { AppRoutes } from './components/routing/AppRoutes';
import { CustomTheme } from './theme/customTheme';
import { datadogLogs } from '@datadog/browser-logs';
import ErrorBoundary from './components/shared/ErrorBoundary';
import { environment } from './environments/environment';
import { discardError } from './utils/apiErrorResponse';

const engine = new Styletron();

datadogLogs.onReady(() => {
  if (environment.datadogClientToken) {
    datadogLogs.init({
      clientToken: environment.datadogClientToken,
      site: environment.datadogSite,
      service: environment.datadogService,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      
      beforeSend: (log) => {
        return !discardError(log)
      }
    });
  }
})

function App() {
  return (
    <ErrorBoundary>
      <StyletronProvider value={engine}>
        <BaseProvider theme={CustomTheme}>
          <AppRoutes />
        </BaseProvider>
      </StyletronProvider>
    </ErrorBoundary>
  );
}

export default App;
