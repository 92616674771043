import { OnChangeParams } from "baseui/select";
import { useState, useMemo } from "react";
import { AlarmTypes } from "../../../types/safeGpt";
import { useRecoilValue } from "recoil";
import { alarmTypesState } from "../../../storage/safeGpt";
import { EnhancedLabeledSelect } from "@gorila-shared-ui/components";


type Props = {
  alarmTypes?: AlarmTypes[],
  selectedAlarmTypeIds?: number[],
  onChangeAlarmTypeIds?: (alarmTypeIds?: number[]) => void
  onChangeAlarmTypes?: (alarmTypes?: AlarmTypes[]) => void
  isFilter?: boolean,
  label?: string,
  placeholder?: string,
  error?: React.ReactNode;
  onBlur?: () => void;
  inline?: boolean;
  clearable?: boolean;
  required?: boolean;
}
export function AlarmTypesSelect({
  selectedAlarmTypeIds, 
  onChangeAlarmTypeIds,
  onChangeAlarmTypes,
  alarmTypes,
  isFilter = true,
  label = 'Tipo de alarma',
  placeholder = 'Seleciona una o mas tipos de alarmas',
  error,
  onBlur,
  inline,
  clearable = false,
  required = false,
}: Readonly<Props>) {
  const alarmTypeList = useRecoilValue(alarmTypesState);
  const [selectedAlarmTypeOptions, setSelectedAlarmTypeOptions] = useState<{ id: number, label: string}[]>();

  const alarmTypesOptions = useMemo(() => {
    if (!alarmTypeList) return;
    const alarmTypeOptions: { 
      id: number | undefined, label: string }[] = alarmTypeList.map((alarmTypes) => ({ id: alarmTypes.code, label: alarmTypes.name }))
    if (!required) {
      alarmTypeOptions?.unshift({
        id: undefined,
        label: isFilter ? 'Todas' : 'Ninguna',
      });
    }
    if (alarmTypes) {
      alarmTypes.forEach((alarmType) => {
        if (alarmType.code && !alarmTypeOptions.find((c) => c.id === alarmType.code)) {
          alarmTypeOptions.push({
            id: alarmType.code,
            label: alarmType.name
          })
        }
      })
    }
    if (selectedAlarmTypeOptions) {
      selectedAlarmTypeOptions.forEach((selectedAlarmType) => {
        if (!alarmTypeOptions.find((c) => c.id === selectedAlarmType.id))
          alarmTypeOptions.push(selectedAlarmType)
      })
    }
    return alarmTypeOptions
  }, [alarmTypeList]);

  const onAlarmTypesChange = (params: OnChangeParams) => {
    if (params.value.length) {
      onChangeAlarmTypeIds && onChangeAlarmTypeIds(params.value.map((val) => val.id).filter((id) => id !== undefined) as number[]);
      onChangeAlarmTypes && onChangeAlarmTypes(params.value.map((val) => ({ code: val.id, name: val.label })).filter((alarm) => alarm.code !== undefined) as AlarmTypes[])
      setSelectedAlarmTypeOptions(
        params.value.map((val) => val).filter((val) => val.id !== undefined) as {id: number, label: string}[]
      );
    } else {
      onChangeAlarmTypeIds && onChangeAlarmTypeIds(undefined);
      onChangeAlarmTypes && onChangeAlarmTypes(undefined);
      setSelectedAlarmTypeOptions(undefined);
    }
  };

  return (
    <EnhancedLabeledSelect
      label={label}
      options={alarmTypesOptions}
      value={selectedAlarmTypeIds ? selectedAlarmTypeIds.map((alarm) => ({ id: alarm })) : undefined}
      onChange={onAlarmTypesChange}
      fullWidth
      onBlur={() => {
        onBlur && onBlur();
      }}
      searchable
      inline={inline}
      inset={inline}
      error={error}
      required={required}
      clearable={clearable}
      placeholder={placeholder}
      isMulti
    />
  )
}