import { FlexColumn, FlexRow, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useStyles } from '../../../hooks/useStyles';
import { Device } from '../../../types/device';
import { DeviceForm } from '../DeviceForm';
import { DeviceInfo } from '../DeviceInfo';

type Props = {
  isOpen: boolean;
  selectedDevice?: Device;
  onClose: (update?: boolean) => void;
};
function DeviceDetailModal({ onClose, isOpen, selectedDevice }: Props) {
  const { css } = useStyles();
  const [device, setDevice] = useState<Device>();
  const [editingMode, setEditingMode] = useState(false);

  useEffect(() => {
    if (isOpen && selectedDevice) setEditingMode(false);
  }, [isOpen]);

  useEffect(() => {
    if (!selectedDevice) {
      setDevice(undefined);
      setEditingMode(true);
      return;
    } else {
      setDevice(selectedDevice);
    }
  }, [selectedDevice]);

  return createPortal(
    <Modal
      animate
      closeable
      onClose={() => onClose()}
      isOpen={isOpen}
      size="auto"
    >
      <FlexRow
        gap={0}
        classNames={css({
          justifyContent: 'space-between',
          margin: '6px 2rem 0px 0px',
        })}
      >
        <ModalHeader
          className={css({
            marginBottom: '0 !important',
          })}
        >
          {device?.ident ?? 'Nuevo'}
        </ModalHeader>
        {!editingMode && (
          <StyledTooltip
            content={'Editar'}
            showArrow={false}
          >
            <Button
              size="compact"
              shape="square"
              onClick={() => setEditingMode(true)}
              kind="tertiary"
            >
              <MaterialIcon
                name={'edit'}
                size="xs-mini"
              />
            </Button>
          </StyledTooltip>
        )}
      </FlexRow>
      <ModalBody>
        <FlexColumn
          classNames={css({
            width: '70vw',
            maxWidth: '1200px',
            minWidth: '350px',
            boxSizing: 'border-box',
            padding: '1rem',
            borderTop: '1px solid rgb(226, 226, 226)',
            maxHeight: '80vh',
          })}
        >
          {!editingMode && device && <DeviceInfo device={device} />}
          {editingMode && (
            <DeviceForm
              device={device}
              onCancel={() => setEditingMode(false)}
              afterSave={() => onClose(true)}
            />
          )}
        </FlexColumn>
      </ModalBody>
    </Modal>,
    document.body
  );
}

export default memo(DeviceDetailModal);
