import { FlexColumn } from '@gorila-shared-ui/components';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { memo } from 'react';
import { createPortal } from 'react-dom';
import { useStyles } from '../../../hooks/useStyles';
import { UserFilters } from '../UserFilters';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function UserFiltersModal({ onClose, isOpen }: Props) {
  const { css, theme } = useStyles();

  return createPortal(
    <Modal
      animate
      closeable
      onClose={onClose}
      isOpen={isOpen}
      size="auto"
    >
      <ModalHeader>Filtros</ModalHeader>
      <ModalBody>
        <FlexColumn
          gap={theme.sizing.scale800}
          classNames={css({
            width: '70vw',
            maxWidth: '1280px',
            minWidth: '300px',
            boxSizing: 'border-box',
          })}
        >
          <UserFilters />
        </FlexColumn>
      </ModalBody>
    </Modal>,
    document.body
  );
}

export default memo(UserFiltersModal);
