import { LabeledIconData } from '@gorila-shared-ui/components';
import { USER_TYPES } from '../../constants/users';
import { User } from '../../types/user';
import { PermissionTree } from '../shared/permissionTree/PermissionTree';

type Props = {
  user: User;
};
export function UserInfo({ user }: Readonly<Props>) {
  const labelWidth = '190px';
  return (
    <>
      {user.email && (
        <LabeledIconData
          label="Correo electrónico:"
          data={user.email}
          icon="alternate_email"
          labelWidth={labelWidth}
        />
      )}
      {user.userType && (
        <LabeledIconData
          label="Rol:"
          data={USER_TYPES[user.userType]}
          icon="mood"
          labelWidth={labelWidth}
        />
      )}
      {user.pusherKey && (
        <LabeledIconData
          label="Clave pusher:"
          data={user.pusherKey}
          icon="key"
          labelWidth={labelWidth}
        />
      )}
      {user.pusherStatus !== undefined && (
        <LabeledIconData
          label="Estado pusher:"
          data={user.pusherStatus ? 'Conectado' : 'Desconectado'}
          icon={user.pusherStatus ? 'power_settings_new' : 'do_not_disturb_on'}
          labelWidth={labelWidth}
        />
      )}
      {user.client && (
        <LabeledIconData
          label="Cliente:"
          data={user.client.name}
          icon="business_center"
          labelWidth={labelWidth}
        />
      )}
      {user.subClient && (
        <LabeledIconData
          label="Subcliente:"
          data={user.subClient.name}
          icon="business_center"
          labelWidth={labelWidth}
        />
      )}
      {user.subAccount && (
        <LabeledIconData
          label="Subcuenta:"
          data={user.subAccount.name}
          icon="person"
          labelWidth={labelWidth}
        />
      )}
      {user.permissions && (
        <PermissionTree
          userPermissions={user.permissions}
          userId={user._id}
          disableScroll
          useFilteredPermission
        />
      )}
    </>
  );
}
