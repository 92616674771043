import { FullContainer } from '@gorila-shared-ui/components';
import { useStyles } from '../../../hooks/useStyles';
import CommandFiltersBar from '../../commands/CommandFiltersBar';
import { CommandList } from '../../commands/CommandList';

export function CommandsPage() {
  const { css, theme } = useStyles();

  return (
    <FullContainer
      verticalFlex
      classNames={`${css({
        padding: theme.sizing.scale400,
        gap: theme.sizing.scale500,
        boxSizing: 'border-box',
      })}`}
    >
      <CommandFiltersBar />
      <CommandList />
    </FullContainer>
  );
}
