import { StyledSearchBar, FlexRow } from "@gorila-shared-ui/components";
import { useStyles } from "../../../hooks/useStyles";
import { SetterOrUpdater } from "recoil";
import { SubClientFilters } from "../../../types/subClient";
import SubClientsFilters from "./SubClientsFilters";


type Props = {
  search?: string;
  onChangeSearch: (search?: string) => void;
  filters?: SubClientFilters,
  setFilters?: SetterOrUpdater<SubClientFilters>
};
export function SubClientFiltersBar({ search, onChangeSearch, filters, setFilters }: Readonly<Props>) {
  const enabledFilters = !!filters && !! setFilters;
  const { css } = useStyles();

  return (
    <FlexRow
      classNames={css({
        justifyContent: 'space-between',
        width: '100%',
      })}
    >
      <FlexRow classNames={css({
        width: '100%',
      })}>
        <StyledSearchBar
          placeholder="Buscar subclientes"
          value={search ?? ''}
          onChange={onChangeSearch}
          clearable
        />
      </FlexRow>
      {enabledFilters && <SubClientsFilters 
        filters={filters}
        setFilters={setFilters}
      />}
    </FlexRow>
  );
}
