import { PaginatedResponse } from '../types/api';
import { ApiError } from '../types/apiResponseError';
import { IdName } from '../types/app';
import { MinimalSubClient, SubClient } from '../types/subClient';
import { getRequest, postRequest } from './api';
import { URLS } from './urls/urls';

export const getSubClients = async ({
  page,
  q,
  clientId,
}: {
  page?: number;
  q?: string;
  clientId?: string;
}): Promise<{ subClients?: SubClient[]; error?: ApiError; total: number; hasNext: boolean }> => {
  const params = {
    page: page ?? 1,
    q,
    clientId,
  };
  const response = await getRequest(URLS.subClients.all, {
    queryParams: params,
  });
  return {
    subClients: response?.data?.items,
    total: response?.data?.total ?? 0,
    hasNext: response?.data?.hasNext ?? false,
    error: response?.error,
  };
};

export const createSubClient = async (subClient: MinimalSubClient): Promise<{ id?: string; error?: ApiError }> => {
  const body: { [key: string]: any } = {
    ...subClient,
    clientId: subClient.client?._id,
  };
  const response = await postRequest(URLS.subClients.create, {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const updateSubClient = async (subClient: MinimalSubClient): Promise<{ id?: string; error?: ApiError }> => {
  const body: { [key: string]: any } = {
    ...subClient,
    clientId: subClient.client?._id,
  };
  const response = await postRequest(URLS.subClients.update(subClient._id), {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const getSubClient = async (subClientId: string): Promise<{ subClient?: SubClient; error?: ApiError }> => {
  const response = await getRequest(URLS.subClients.detail(subClientId));
  return {
    subClient: response?.data,
    error: response?.error,
  };
};

export const getSubClientsSearch = async (subClientParams: {
  page?: number,
  q?: string,
  include?: string[],
  exclude?: string[],
  clientId?: string,
  safeGPT?: boolean, 
}): Promise<{ error?: ApiError; subClientsPaginated: PaginatedResponse<IdName>}> => {
  const body = {
    ...subClientParams,
    page: subClientParams.page ?? 1,
    safeGPT: subClientParams.safeGPT ?? false
  };
  const response = await postRequest(URLS.subClients.search, {
    body,
  });
  return {
    subClientsPaginated: response?.data,
    error: response?.error,
  };
};