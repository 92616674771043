import { FlexRow, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { DatePicker } from 'baseui/datepicker';
import { memo } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useStyles } from '../../hooks/useStyles';
import { reportDateRangeState, reportReloadListState } from '../../storage/reports';

function ReportsFilters() {
  const { css } = useStyles();
  const setReloadPage = useSetRecoilState(reportReloadListState);
  const [reportDateRange, setReportDateRange] = useRecoilState(reportDateRangeState);
  const resetReportsDateRange = useResetRecoilState(reportDateRangeState);

  const resetFilters = () => {
    resetReportsDateRange();
  };

  const startDate = reportDateRange && reportDateRange[0] ? new Date(reportDateRange[0]) : undefined;
  const endDate = reportDateRange && reportDateRange[1] ? new Date(reportDateRange[1]) : undefined;

  return (
    <FlexRow
      classNames={css({ alignItems: 'center' })}
      gap={'4px'}
    >
      <FlexRow
        classNames={css({ flex: '1', alignItems: 'center' })}
        gap={'4px'}
      >
        <div>
          <DatePicker
            placeholder="Fecha inicio - Fecha fin"
            maxDate={new Date()}
            range
            size="compact"
            value={[startDate, endDate]}
            onChange={({ date: dates }) => {
              if (dates instanceof Array) {
                if (dates[0] && dates[1]) {
                  setReportDateRange([dates[0].getTime(), dates[1].getTime()]);
                } else if (dates[0]) {
                  setReportDateRange([dates[0].getTime(), null]);
                }
              }
            }}
          />
        </div>
        <StyledTooltip
          content={'Limpiar filtros'}
          showArrow={false}
        >
          <Button
            size="mini"
            shape="round"
            onClick={resetFilters}
            kind="tertiary"
          >
            <MaterialIcon
              name={'clear'}
              size="mini"
            />
          </Button>
        </StyledTooltip>
      </FlexRow>
      <StyledTooltip
        content={'Actualizar lista'}
        showArrow={false}
      >
        <Button
          size="mini"
          shape="round"
          onClick={() => setReloadPage((prev) => !prev)}
          kind="tertiary"
        >
          <MaterialIcon
            name={'refresh'}
            size="mini"
          />
        </Button>
      </StyledTooltip>
    </FlexRow>
  );
}

export default memo(ReportsFilters);
