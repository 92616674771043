import { useEffect, useState } from 'react';
import { useMatches } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { getAppPermissions } from '../../services/appService';
import { isLoggedInState, pageState, sidenavIsPinnedState } from '../../storage/app';
import { appPermissionState } from '../../storage/permissions';

export function AppProvider() {
  const { isSmall } = useBreakpoints();
  const isLoggedIn = useRecoilValue(isLoggedInState);
  const setAppPermission = useSetRecoilState(appPermissionState);
  const setSidenavIsPinned = useSetRecoilState(sidenavIsPinnedState);
  const setPage = useSetRecoilState(pageState);
  const [isInitialLoad, setInitialLoad] = useState(true);
  const matches = useMatches();

  useEffect(() => {
    if (!isLoggedIn) return;
    const loadAppPermissions = async () => {
      const { appPermissions, error } = await getAppPermissions();
      if (!error && appPermissions) {
        setAppPermission(appPermissions);
      }
    };

    loadAppPermissions();
  }, [isLoggedIn]);

  useEffect(() => {
    if (isInitialLoad) {
      setInitialLoad(false);
      return
    }
    if (isSmall) {
      setSidenavIsPinned(false);
    }
  }, [isSmall]);

  useEffect(() => {
    if (matches.length) {
      setPage(matches[matches.length - 1].id);
    } else {
      setPage('');
    }
  }, [matches]);

  return null;
}
