import { FlexColumn, LabeledInput, MaterialIcon } from '@gorila-shared-ui/components';
import { HeadingXLarge } from 'baseui/typography';
import { useStyles } from '../../hooks/useStyles';

type Props = {
  user?: string | number;
  password?: string | number;
  onChangeUser?: (value: string) => void;
  onChangePass?: (value: string) => void;
};

export function LoginForm({ onChangeUser, onChangePass, user, password }: Props) {
  const { theme } = useStyles();

  return (
    <FlexColumn gap={theme.sizing.scale600}>
      <HeadingXLarge color={'rgb(30,41,59)'}>Sing in</HeadingXLarge>
      <LabeledInput
        placeholder={'User Name'}
        value={user}
        onChange={onChangeUser}
        size={'default'}
        startEnhancer={<MaterialIcon name="account_circle" />}
      />
      <LabeledInput
        placeholder={'Password'}
        value={password}
        onChange={onChangePass}
        type="password"
        size={'default'}
        autocomplete="current-password"
        startEnhancer={<MaterialIcon name="lock" />}
      />
    </FlexColumn>
  );
}
