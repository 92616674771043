import { EnhancedLabeledSelect } from '@gorila-shared-ui/components';
import { OnChangeParams } from 'baseui/select';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { installationsCampaignsState } from '../../../storage/Installations';

type Props = {
  selectedCampaignId?: string;
  onChange: (campaignId?: string) => void;
  label?: string;
  error?: React.ReactNode;
  onBlur?: () => void;
  inline?: boolean;
  clearable?: boolean;
  required?: boolean;
  disabled?: boolean;
};
export function CampaignSelect({
  selectedCampaignId,
  onChange,
  label = 'Compañia',
  error,
  onBlur,
  inline,
  clearable = false,
  required = false,
  disabled = false,
}: Readonly<Props>) {
  const campaignList = useRecoilValue(installationsCampaignsState);

  const campaignsOptions = useMemo(() => {
    if (!campaignList) return;

    let campaignOptions: { id: string | undefined; label: string }[] = campaignList.map((campaign) => ({
      id: campaign.pk,
      label: campaign.name,
    }));

    if (inline)
      campaignOptions = [
        {
          id: undefined,
          label: 'Todos',
        },
        ...campaignOptions,
      ];
    if (!inline && !required)
      campaignOptions = [
        {
          id: undefined,
          label: 'Ninguna',
        },
        ...campaignOptions,
      ];

    return campaignOptions;
  }, [campaignList]);

  const onCampaignChange = (params: OnChangeParams) => {
    params.option?.id ? onChange(params.option.id as string) : onChange(undefined);
  };

  return (
    <EnhancedLabeledSelect
      label={label}
      options={campaignsOptions}
      value={selectedCampaignId ? [{ id: selectedCampaignId }] : undefined}
      onChange={onCampaignChange}
      fullWidth
      searchable
      onBlur={onBlur}
      inline={inline}
      error={error}
      required={required}
      clearable={clearable}
      placeholder="Selecciona una compañia"
      disabled={disabled || !campaignList?.length}
    />
  );
}
