import { ContentContainer } from '@gorila-shared-ui/components';
import { useState } from 'react';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import { VersionsFiltersBar } from '../../versions/VersionsFiltersBar';
import { VersionsList } from '../../versions/VersionsList';

export function VersionsPage() {
  const { search, setSearch, debouncedSearch } = useDebouncedSearch('');
  const [reloadPage, setReloadPage] = useState(false);
  const [brandId, setBrandId] = useState<string>();
  const [subBrandId, setSubBrandId] = useState<string>();

  return (
    <ContentContainer>
      <VersionsFiltersBar
        search={search}
        onChangeSearch={setSearch}
        onReloadPage={() => setReloadPage(!reloadPage)}
        selectedBrandId={brandId}
        setSelectedBrandId={setBrandId}
        selectedSubBrandId={subBrandId}
        setSelectedSubBrandId={setSubBrandId}
      />
      <VersionsList
        search={debouncedSearch}
        reloadPage={reloadPage}
        brandId={brandId}
        subBrandId={subBrandId}
      />
    </ContentContainer>
  );
}
