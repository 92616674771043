import { createEmbeddingContext, FrameOptions } from "amazon-quicksight-embedding-sdk";
import { useRef, useEffect } from "react";
import { useStyles } from "../../hooks/useStyles";

type Props = {
  url: string,
  height?: string,
  width?: string,
}
export function EmbeddedIframe({url, width = '100%', height = "100%"}: Readonly<Props>) {
  const iframeRef = useRef<HTMLDivElement>(null);
  const { css } = useStyles();

  const embedDashboard = async () => {
    if (!iframeRef.current) return;
    const embeddingContext = await createEmbeddingContext({
      // onChange: (changeEvent, metadata) => {
      //     console.log('Context received a change', changeEvent, metadata);
      // },
    });

    const frameOptions: FrameOptions = {
      url,
      container: iframeRef.current,
      height,
      width,
    };
    
    await embeddingContext.embedDashboard(frameOptions, {});
  } 

  useEffect(() => {
    if (iframeRef.current) {
      embedDashboard();
    }
  });

  return (
    <div ref={iframeRef}className={css({height})}></div>
  )
}