import { ContentContainer } from '@gorila-shared-ui/components';
import { useState } from 'react';
import { ClientStreamaxFiltersBar } from './streamax/ClientStreamaxFiltersBar';
import { ClientStreamaxList } from './streamax/ClientStreamaxList';

type Props = {
  clientId: string;
};
export function StreamaxTab({ clientId }: Readonly<Props>) {
  const [reloadPage, setReloadPage] = useState(false);

  return (
    <ContentContainer>
      <ClientStreamaxFiltersBar
        clientId={clientId}
        onReloadPage={() => setReloadPage(!reloadPage)}
      />
      <ClientStreamaxList
        clientId={clientId}
        reloadPage={reloadPage}
      />
    </ContentContainer>
  );
}
