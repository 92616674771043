import React, { Component, ErrorInfo, ReactNode } from "react";
import { useRouteError } from "react-router-dom";
import { ErrorPage } from "../pages/ErrorPage";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {

    // console.log("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

export function ErrorElementBoundary() {
  // let error = useRouteError();
  // console.log(error);
  
  return <ErrorPage />; 
}