import { Installation } from "../types/installation"

/*
  Function to convert an entire text string to lowercase, 
  ignoring numbers and special characters and then converting 
  the first letter of each word to uppercase.
*/ 
export function formatName(name: string) {
  return name.toLowerCase().replace(/\b[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ]+/g, (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
}

export const getInstallationClient = (installation: Installation) => {
  let client = ''
  if (installation.poliza) {
    const { contratante, conductorHabitual } = installation.poliza;
    if (contratante?.razonSocial) return contratante?.razonSocial
    else if (contratante.nombre) client = `${contratante.nombre} ${contratante.apePaterno} ${contratante.apeMaterno}`;
    else if (conductorHabitual) client = `${conductorHabitual.nombre} ${conductorHabitual.apePaterno} ${conductorHabitual.apeMaterno}`;
  }
  return formatName(client);
}