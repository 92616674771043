import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { getAssetTypes } from '../../services/assetService';
import { assetsTypesState } from '../../storage/assets';

function AssetsTypesProvider() {
  const setAssetTypes = useSetRecoilState(assetsTypesState);

  useEffect(() => {
    const loadAssetTypes = async () => {
      const { assetTypes } = await getAssetTypes();
      if (assetTypes) {
        setAssetTypes(assetTypes)
      }
    };
    loadAssetTypes();
  });

  return null;
}

export default AssetsTypesProvider;
