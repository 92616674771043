import { get, post, del } from "aws-amplify/api";
import { uploadData, getUrl, downloadData } from "aws-amplify/storage";
import { environment } from '../environments/environment';
import { ApiError } from '../types/apiResponseError';
import { getErrorMessage } from '../utils/apiErrorResponse';
import { convertObjectToJson } from '../utils/objects';

export const getRequest = async (
  path: string,
  params = {}
): Promise<{ data?: any; error?: ApiError }> => {
  try {
    const response = await get({
      apiName: environment.awsAPIEmissionsEndpointName,
      path,
      options: {
        ...convertObjectToJson(params),
      },
    }).response;
    return {
      data: await response.body.json(),
    };
  } catch (error) {
    console.log(error);
    return {
      error: getErrorMessage(error, { params, path }),
    };
  }
};

export const postRequest = async (
  path: string,
  params = {}
): Promise<{ data?: any; error?: ApiError }> => {
  try {
    const response = await post({
      apiName: environment.awsAPIEmissionsEndpointName,
      path,
      options: {
        ...convertObjectToJson(params),
      },
    }).response;
    try {
      return {
        data: await response.body?.json(),
      };
    } catch {
      return {
        data: undefined,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      error: getErrorMessage(error, { params, path }),
    };
  }
};

export const fileRequest = async (path: string) => {
  try {
    return {
      data: {
        url: (await getUrl({ path })).url.href,
      },
    };
  } catch (error) {
    console.log(error);
    return {
      error: getErrorMessage(error),
    };
  }
};

export const uploadRequest = async (path: string, file: File, options = {}) => {
  try {
    return {
      data: {
        key: (await uploadData({ path, data: file, options }).result).path,
      },
    };
  } catch (error) {
    console.log(error);
    return {
      error: getErrorMessage(error),
    };
  }
};

export const downloadRequest = async (path: string) => {
  try {
    return {
      data: {
        file: (await downloadData({ path }).result).body,
      },
    };
  } catch (error) {
    console.log(error);
    return {
      error: getErrorMessage(error),
    };
  }
};

export const deleteRequest = async (
  path: string,
  params = {}
): Promise<{ data?: any; error?: ApiError }> => {
  try {
    return {
      data: await del({
        apiName: environment.awsAPIEmissionsEndpointName,
        path,
        options: {
          ...convertObjectToJson(params),
        },
      }).response,
    };
  } catch (error) {
    console.log(error);
    return {
      error: getErrorMessage(error, { params, path }),
    };
  }
};
