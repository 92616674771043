import { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../constants/app';
import { useFeedback } from '../../hooks/useFeedback';
import { useLoading } from '../../hooks/useLoading';
import { useStyles } from '../../hooks/useStyles';
import { createSafeGPT, updateSafeGPT } from '../../services/safeGptService';
import {
  editSafeGptConfigErrorState,
  editSafeGptConfigState,
  editSafeGptConfigTouchedState,
} from '../../storage/safeGpt';
import { SafeGptConfiguration } from '../../types/safeGpt';
import { FormStepsActionsRow } from '../shared/FormStepsActionsRow';
import { FlexColumn } from '../ui/FlexColumn';
import { SafeGptConfigFormSteps } from './modal/SafeGptConfigFormSteps';

type Props = {
  onCancel: () => void;
  afterSave: () => void;
  safeGptConfig?: SafeGptConfiguration;
};
export function SafeGptConfigForm({ onCancel, afterSave, safeGptConfig: safeGptCofig }: Readonly<Props>) {
  const { css } = useStyles();
  const [form, setForm] = useRecoilState(editSafeGptConfigState);
  const formHasError = useRecoilValue(editSafeGptConfigErrorState);
  const resetEditSafeGptConfigState = useResetRecoilState(editSafeGptConfigState);
  const resetEditSafeGptConfigTouchedState = useResetRecoilState(editSafeGptConfigTouchedState);
  const { loading, startLoading, stopLoading } = useLoading();
  const { showFailFeedback, showPositiveFeedback } = useFeedback();
  const canSubmit = !formHasError;
  const totalSteps = 2;
  const [currentStep, setCurrentStep] = useState(0);
  const isLastStep = currentStep + 1 === totalSteps;
  const isNew = !safeGptCofig?._id;

  useEffect(() => {
    return () => {
      clearStates();
    };
  }, []);

  useEffect(() => {
    if (safeGptCofig) {
      const { _id, ...safeGptCofigForm } = safeGptCofig;
      setForm((prev) => {
        return {
          ...prev,
          ...safeGptCofigForm,
        };
      });
    }
  }, [safeGptCofig]);

  const onSave = async () => {
    if (!canSubmit) return;
    startLoading();
    const { error } = safeGptCofig?._id ? await updateSafeGPT(safeGptCofig._id, form) : await createSafeGPT(form);
    if (!error) {
      afterSave();
      if (safeGptCofig?._id) showPositiveFeedback(FEEDBACK.edited(FEEDBACK_PREFIXES.safeGptConfig, safeGptCofig.name));
      else showPositiveFeedback(FEEDBACK.created(FEEDBACK_PREFIXES.safeGptConfig));
    } else {
      showFailFeedback(error);
    }
    stopLoading();
  };

  const clearStates = () => {
    resetEditSafeGptConfigState();
    resetEditSafeGptConfigTouchedState();
  };

  return (
    <FlexColumn classNames={css({ overflow: 'hidden' })}>
      <SafeGptConfigFormSteps
        currentStep={currentStep}
        isNew={isNew}
      />
      <FormStepsActionsRow
        onContinue={() =>
          flushSync(() => {
            setCurrentStep((prev) => prev + 1);
          })
        }
        onReturn={currentStep > 0 ? () => setCurrentStep((prev) => prev - 1) : undefined}
        onSubmit={onSave}
        onCancel={safeGptCofig?._id ? onCancel : undefined}
        editMode={!isNew}
        loading={loading}
        canSubmit={canSubmit}
        canContinue={canSubmit}
        isLastStep={isLastStep}
      />
    </FlexColumn>
  );
}
