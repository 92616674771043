import { Centered, FlexColumn, FlexRow, StyledButton } from '@gorila-shared-ui/components';
import { HeadingMedium } from 'baseui/typography';
import { useStyles } from '../../hooks/useStyles';
import { StyledRouterLink } from '../ui/StyledRouterLink';

export function ErrorPage() {
  const { theme, css } = useStyles();

  return (
    <Centered>
      <FlexColumn>
        <FlexRow
          classNames={css({ alignItems: 'end' })}
          centered
        >
          <img
            src={'/img/logo_circulo.svg'}
            alt="logo"
            className={css({
              height: '25vh',
            })}
          />
        </FlexRow>
        <HeadingMedium
          marginBottom={0}
          color={theme.colors.contentSecondary}
        >
          ¡Oops! Algo salió mal.
        </HeadingMedium>
        <StyledButton>
          <StyledRouterLink
            to={'/'}
            color={theme.colors.white}
          >
            Home
          </StyledRouterLink>
        </StyledButton>
      </FlexColumn>
    </Centered>
  );
}
