import { Check, Delete, DeleteAlt } from 'baseui/icon';
import { useSnackbar } from 'baseui/snackbar';
import { useStyles } from './useStyles';

export function useFeedback() {
  const { theme } = useStyles();
  const { enqueue } = useSnackbar();

  const showPositiveFeedback = (text: string) => {
    enqueue(
      {
        message: text,
        startEnhancer: ({ size }) => <Check size={size} />,
        focus: false,
        overrides: {
          Root: {
            style: {
              backgroundColor: theme.colors.contentPositive,
            },
          },
        },
      },
      2500
    );
  };

  const showDeletionFeedback = (text: string) => {
    enqueue(
      {
        message: text,
        startEnhancer: ({ size }) => <Delete size={size} />,
        focus: false,
        overrides: {
          Root: {
            style: {
              backgroundColor: theme.colors.contentPositive,
            },
          },
        },
      },
      2500
    );
  };

  const showFailFeedback = (text: string | string[]) => {
    enqueue(
      {
        message: typeof text === 'string' ? text : text.map((msg) => {
          return <span key={msg}>{msg}<br /></span>
        }),
        startEnhancer: ({ size }) => <DeleteAlt size={size} />,
        focus: false,
        overrides: {
          Root: {
            style: {
              backgroundColor: theme.colors.negative,
            },
          },
        },
      },
      2500
    );
  };

  return {
    showPositiveFeedback,
    showDeletionFeedback,
    showFailFeedback,
  };
}
