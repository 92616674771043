import {
  FlexColumn,
  FlexRow,
  LabeledInput,
  LabeledSelect,
  LabeledTextArea,
  StyledButton,
} from '@gorila-shared-ui/components';
import { Checkbox } from 'baseui/checkbox';
import { OnChangeParams } from 'baseui/select';
import { useEffect, useMemo, useState } from 'react';
import { areEqual } from 'react-window';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../constants/app';
import { COMMANDS_HINTS } from '../../constants/commands';
import { useFeedback } from '../../hooks/useFeedback';
import { useLoading } from '../../hooks/useLoading';
import { useStyles } from '../../hooks/useStyles';
import { updateDevicesModel } from '../../services/deviceService';
import { commandsSelectedState, filteredCommandsOptionsState } from '../../storage/commands';
import { Command } from '../../types/command';
import { DeviceModel } from '../../types/deviceModel';

type Props = {
  deviceModel: DeviceModel;
  commandIndex?: number;
  onCancel: () => void;
  afterSave: () => void;
};
export function CommandForm({ deviceModel, commandIndex, onCancel, afterSave }: Readonly<Props>) {
  const { css, theme } = useStyles();
  const [command, setCommand] = useState<Command>();
  const [editingCommand, setEditingCommand] = useState<Command>({
    commandName: '',
    commandDescription: '',
    command: '',
    queue: false,
    ttl: 0,
    properties: {},
    fields: {},
    logMessage: '',
  });
  const commandsTypes = useRecoilValue(filteredCommandsOptionsState);
  const setCommandsTypesUsed = useSetRecoilState(commandsSelectedState);
  const [commandTypeSelected, setCommandTypeSelected] = useState<{
    id: string;
    label: string;
    name: string;
    description: string;
  }>();
  const [properties, setProperties] = useState<string>();
  const [fields, setFields] = useState<string>();
  const [errors, setErrors] = useState<{
    properties: boolean;
    fields: boolean;
  }>({
    properties: false,
    fields: false,
  });
  const { startLoading, stopLoading, loading } = useLoading(false);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();

  useEffect(() => {
    if (!deviceModel.commands?.length && !deviceModel.commands) {
      setCommandsTypesUsed(undefined);
      return;
    }
    setCommandsTypesUsed(deviceModel.commands);
  }, [deviceModel]);

  useEffect(() => {
    if (commandIndex === 0 || commandIndex) {
      const com = deviceModel.commands![commandIndex];
      if (com) {
        setCommand(com);
        setEditingCommand(com);
        setProperties(JSON.stringify(com.properties));
        setFields(JSON.stringify(com.fields));
        return;
      }
    }
  }, [commandIndex]);

  const hasUpdates = !areEqual(command ?? {}, editingCommand);

  const canSubmit = useMemo(() => {
    const error = errors.fields || errors.properties;
    console.log(editingCommand);

    return !!editingCommand.commandName && !!editingCommand.commandDescription && !!editingCommand?.command && !error;
  }, [editingCommand]);

  const onInputChange = (value: string | undefined | null | {}, field: keyof Command) => {
    setEditingCommand((prev) => ({ ...prev!, [field]: value }));
  };

  const onErrorsChange = (hasError: boolean, field: string) => {
    setErrors((prev) => ({ ...prev!, [field]: hasError }));
  };

  const onCommandTypeChange = (params: OnChangeParams) => {
    if (params.option?.id) {
      onInputChange(params.option.description as string, 'commandDescription');
      onInputChange(params.option.name as string, 'commandName');
      setCommandTypeSelected(
        params.option as {
          id: string;
          label: string;
          name: string;
          description: string;
        }
      );
    } else {
      onInputChange(undefined, 'commandDescription');
      onInputChange(undefined, 'commandName');
      setCommandTypeSelected(undefined);
    }
  };

  const commandTypeOptions = useMemo(() => {
    if (!commandsTypes) return;
    const options = [
      ...commandsTypes.map((type) => ({
        id: type.commandName,
        label: `${type.commandName} - ${type.commandDescription}`,
        name: type.commandName,
        description: type.commandDescription,
      })),
    ];
    return options;
  }, [commandsTypes]);

  const onSubmit = async () => {
    startLoading();
    let model = { ...deviceModel };
    if (commandIndex === 0 || commandIndex) {
      model.commands![commandIndex] = editingCommand;
    } else {
      model.commands = [...model.commands!, editingCommand];
    }
    const { id, error } = await updateDevicesModel(model);
    if (!error && id) {
      afterSave();
      showPositiveFeedback(
        command
          ? FEEDBACK.edited(FEEDBACK_PREFIXES.deviceModelCommand, editingCommand.commandDescription)
          : FEEDBACK.created(FEEDBACK_PREFIXES.deviceModelCommand)
      );
    } else {
      showFailFeedback(
        error || command
          ? FEEDBACK.failedEdition(FEEDBACK_PREFIXES.deviceModelCommand, editingCommand.commandDescription)
          : FEEDBACK.failedCreation(FEEDBACK_PREFIXES.deviceModelCommand)
      );
    }
    stopLoading();
  };

  const jsonValidator = (value?: string): boolean => {
    let json = null;
    try {
      json = JSON.parse(value as string);
    } catch {}

    if (json || !value) {
      return true;
    }
    return false;
  };

  return (
    <FlexColumn
      classNames={css({
        justifyContent: 'space-between',
        overflow: 'hidden',
        width: '100%',
        alignItems: 'end',
      })}
    >
      <FlexColumn
        classNames={css({
          overflow: 'auto',
          width: '100%',
          height: '100%',
        })}
      >
        {commandIndex === undefined && (
          <LabeledSelect
            label="Tipo de comando"
            onChange={onCommandTypeChange}
            options={commandTypeOptions}
            value={[{ id: commandTypeSelected?.id }]}
          />
        )}
        <LabeledTextArea
          label="Commando"
          value={editingCommand.command}
          onChangeValue={(value) => onInputChange(value, 'command')}
          hint={COMMANDS_HINTS.command}
          size="small"
        />
        <LabeledInput
          label="TTL"
          value={editingCommand?.ttl}
          onChange={(value) => onInputChange(value, 'ttl')}
          type="number"
        />
        <LabeledTextArea
          label="Propiedades"
          value={properties}
          onChangeValue={(value) => {
            if (value && jsonValidator(value)) {
              onInputChange(JSON.parse(value), 'properties');
              onErrorsChange(false, 'properties');
            } else {
              onInputChange(undefined, 'properties');
              onErrorsChange(true, 'properties');
            }
            setProperties(value);
          }}
          hint={COMMANDS_HINTS.properties}
          error={errors.properties}
          size="large"
        />
        <LabeledTextArea
          label="Campos"
          value={fields}
          onChangeValue={(value) => {
            if (value && jsonValidator(value)) {
              onInputChange(JSON.parse(value), 'fields');
              onErrorsChange(false, 'fields');
            } else {
              onInputChange(undefined, 'fields');
              onErrorsChange(true, 'fields');
            }
            setFields(value);
          }}
          hint={COMMANDS_HINTS.fields}
          error={errors.fields}
          size="large"
        />
        <LabeledTextArea
          label="Logs"
          value={editingCommand.logMessage}
          onChangeValue={(value) => onInputChange(value, 'logMessage')}
          hint={COMMANDS_HINTS.logs}
          size="large"
        />
        <Checkbox
          checked={editingCommand?.queue}
          onChange={() => onInputChange(!editingCommand?.queue, 'queue')}
        >
          QUEUE
        </Checkbox>
      </FlexColumn>
      <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'flex-end' })}`}>
        <FlexRow gap={theme.sizing.scale300}>
          {commandIndex !== undefined && (
            <StyledButton
              kind="tertiary"
              onClick={onCancel}
            >
              Cancelar
            </StyledButton>
          )}
          <StyledButton
            onClick={onSubmit}
            isLoading={loading}
            disabled={!canSubmit || !hasUpdates}
          >
            Guardar
          </StyledButton>
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  );
}
