import { EmptyState, FlexColumn, FlexRow, Loading, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLoading } from '../../../../hooks/useLoading';
import { useStyles } from '../../../../hooks/useStyles';
import { getWebService } from '../../../../services/webServiceService';
import { WebService } from '../../../../types/webService';
import { WebServiceForm } from '../WebServiceForm';
import { WebServiceInfo } from '../WebServiceInfo';

type Props = {
  isOpen: boolean;
  selectedWebServiceId?: string;
  onClose: (update?: boolean) => void;
  subClientId: string;
};

function WebServiceDetailModal({ onClose, isOpen, selectedWebServiceId, subClientId }: Props) {
  const { css } = useStyles();
  const [webService, setWebService] = useState<WebService>();
  const { startLoading, stopLoading, loading } = useLoading(false);
  const [editingMode, setEditingMode] = useState(false);
  const [error, setError] = useState(false);

  const loadWebServiceData = async (webServiceId: string) => {
    startLoading();
    const response = await getWebService(webServiceId);
    if (response?.error) {
      setWebService(undefined);
      setError(true);
    } else {
      setWebService(response?.webService);
      setError(false);
    }
    stopLoading();
  };

  useEffect(() => {
    if (isOpen && selectedWebServiceId) setEditingMode(false);
  }, [isOpen]);

  useEffect(() => {
    if (!selectedWebServiceId) {
      setWebService(undefined);
      setEditingMode(true);
      return;
    }
    loadWebServiceData(selectedWebServiceId);
  }, [selectedWebServiceId]);

  return createPortal(
    <Modal
      animate
      closeable
      onClose={() => onClose()}
      isOpen={isOpen}
      size="auto"
    >
      {loading && (
        <FlexColumn
          centered
          classNames={css({
            height: '400px',
            width: '400px',
          })}
        >
          <Loading />
        </FlexColumn>
      )}
      {!loading && error && (
        <FlexColumn
          centered
          classNames={css({
            height: '400px',
            width: '400px',
          })}
        >
          <EmptyState
            title="*Error*"
            description="No se pudieron obtener los datos de la subcuenta"
          />
        </FlexColumn>
      )}
      {!loading && !error && (
        <>
          <FlexRow
            gap={0}
            classNames={css({
              justifyContent: 'space-between',
              margin: '6px 2rem 0px 0px',
            })}
          >
            <ModalHeader
              className={css({
                marginBottom: '0 !important',
              })}
            >
              {webService?.name ?? 'Nuevo servicio externo'}
            </ModalHeader>
            {!editingMode && (
              <StyledTooltip
                content={'Editar'}
                showArrow={false}
              >
                <Button
                  size="compact"
                  shape="square"
                  onClick={() => setEditingMode(true)}
                  kind="tertiary"
                >
                  <MaterialIcon
                    name={'edit'}
                    size="xs-mini"
                  />
                </Button>
              </StyledTooltip>
            )}
          </FlexRow>
          <ModalBody>
            <FlexColumn
              classNames={css({
                maxWidth: '1200px',
                minWidth: '550px',
                boxSizing: 'border-box',
                padding: '1rem',
                borderTop: '1px solid rgb(226, 226, 226)',
                maxHeight: '80vh',
                overflow: 'auto',
              })}
            >
              {!editingMode && webService && <WebServiceInfo webService={webService} />}
              {editingMode && !loading && (
                <WebServiceForm
                  webService={webService}
                  onCancel={() => setEditingMode(false)}
                  afterSave={() => onClose(true)}
                  subClientId={subClientId}
                />
              )}
            </FlexColumn>
          </ModalBody>
        </>
      )}
    </Modal>,
    document.body
  );
}

export default memo(WebServiceDetailModal);
