import { ContentContainer } from '@gorila-shared-ui/components';
import { useState } from 'react';
import { SubClient } from '../../../types/subClient';
import { SubAccountFiltersBar } from './SubAccountFiltersBar';
import { SubAccountList } from './SubAccountList';

type Props = {
  subClient: SubClient;
};

export function SubAccountsTab({ subClient }: Readonly<Props>) {
  const [reloadPage, setReloadPage] = useState(false);

  return (
    <ContentContainer>
      <SubAccountFiltersBar
        clientId={subClient.client?._id}
        subClientId={subClient._id}
        onReloadPage={() => setReloadPage(!reloadPage)}
      />
      <SubAccountList
        clientId={subClient.client?._id}
        subClientId={subClient._id}
        reloadPage={reloadPage}
      />
    </ContentContainer>
  );
}
