import { EnhancedLabeledSelect } from '@gorila-shared-ui/components';
import { OnChangeParams } from 'baseui/select';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDebounceFunction } from '../../../hooks/useDebounce';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import { useLoading } from '../../../hooks/useLoading';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { getClientsList } from '../../../services/clientService';
import { ClientCore } from '../../../types/client';

type Props = {
  client?: ClientCore;
  selectedClientId?: string;
  onChangeClientId?: (clientId?: string) => void;
  onChangeClient?: (client?: ClientCore) => void;
  isFilter?: boolean;
  label?: string;
  maxDropdownHeight?: string;
  dropdownOffset?: string;
  inline?: boolean;
  required?: boolean;
};
export function ClientSelect({
  selectedClientId,
  onChangeClientId,
  onChangeClient,
  client,
  isFilter = true,
  label = 'Cliente:',
  maxDropdownHeight,
  dropdownOffset,
  inline = false,
  required = false,
}: Readonly<Props>) {
  const [clientsList, setClientsList] = useState<ClientCore[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const { search, setSearch, debouncedSearch } = useDebouncedSearch('');
  const [selectedClientOption, setSelectedClientOption] = useState<{ id: string; label: string }>();
  const requestIdRef = useRef(0);

  useUpdateEffect(() => {
    setPage(0);
  }, [debouncedSearch]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadClients = async () => {
      const requestId = ++requestIdRef.current;
      const { clients, error, hasNext } = await getClientsList(page, search);
      if (requestId === requestIdRef.current) {
        if (!error && clients) {
          if (page > 1 && clientsList) {
            setClientsList([...clientsList, ...clients]);
            setHasNextPage(hasNext);
          } else {
            setClientsList(clients);
            setHasNextPage(hasNext);
          }
        } else {
          setClientsList(undefined);
          setHasNextPage(false);
        }
        stopLoading();
      }
    };
    loadClients();
  }, [page]);

  const clientOptions = useMemo(() => {
    if (!clientsList) return;
    const clientsOptions: { id: string | undefined; label: string }[] = clientsList.map((client) => ({
      id: client._id,
      label: client.name,
    }));
    if (!required) {
      clientsOptions?.unshift({
        id: undefined,
        label: isFilter ? 'Todos' : 'Ninguno',
      });
    }
    if (client) {
      if (!clientsOptions.find((c) => c.id === client._id))
        clientsOptions.push({
          id: client._id,
          label: client.name,
        });
    }
    if (selectedClientOption) {
      if (!clientsOptions.find((c) => c.id === selectedClientOption.id)) clientsOptions.push(selectedClientOption);
    }
    return clientsOptions;
  }, [clientsList]);

  const onClientChange = (params: OnChangeParams) => {
    if (params.option?.id) {
      onChangeClientId && onChangeClientId(params.option.id as string);
      onChangeClient &&
        onChangeClient({
          _id: params.option?.id,
          name: params.option?.label,
        } as ClientCore);
    } else {
      onChangeClientId && onChangeClientId(undefined);
      onChangeClient && onChangeClient(undefined);
    }
    setSelectedClientOption(params.option as { id: string; label: string });
    setSearch(undefined);
  };

  const fetchData = () => {
    if (hasNextPage) setPage(page + 1);
  };

  const handleInputChange = useDebounceFunction(function (term: string) {
    setSearch(term);
  }, 500);

  return (
    <EnhancedLabeledSelect
      label={label}
      options={clientOptions}
      value={[{ id: selectedClientId }]}
      onChange={onClientChange}
      onInputChange={(e) => {
        handleInputChange(e.target.value);
      }}
      infiniteScroll
      fetchData={fetchData}
      fetchDataLoading={loading}
      hasNext={hasNextPage}
      onBlur={() => setSearch(undefined)}
      searchable
      maxDropdownHeight={maxDropdownHeight}
      smallContainer={!!dropdownOffset}
      dropdownOffset={dropdownOffset}
      inline={inline}
      inset={inline}
      fullWidth
      required={required}
    />
  );
}
