import 'mapbox-gl/dist/mapbox-gl.css';
import { memo, useEffect } from 'react';
import { MapLayerMouseEvent, Map as MapboxMap, ViewStateChangeEvent, useMap } from 'react-map-gl';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ASSETS_PAGE, DEVICES_PAGE, INSTALLATIONS_DETAIL_PAGE, INSTALLATIONS_PAGE } from '../../constants/app';
import { environment } from '../../environments/environment';
import { pageState, sidenavIsPinnedState } from '../../storage/app';
import { mapPaddingState, mapStyleState, mapViewState } from '../../storage/map';
import { getAddressFromPoint } from '../../utils/geocode';
import { MapOptions } from './MapOptions';
import { MapSearchBox } from './MapSearchBox';
import { TrafficLayer } from './layers/TrafficLayer';
import './styles/mapbox.css';
import AssetClusters from './markers/AssetClusters';
import { useDebounceFunction } from '../../hooks/useDebounce';
import DeviceMarker from './markers/DeviceMarker';
import { editingInstallationState, editInstallationState } from '../../storage/Installations';
import { MIN_HEIGHT_MAP, MIN_WIDTH_MAP } from '../../constants/installations';
import InstallationDetailMarker from './markers/InstallationDetailMarker';
import NewInstallationMarker from './markers/NewInstallationMarker';

type Props = {
  fullWindow?: boolean;
  onlyView?: boolean;
  minHeight?: string;
};
function Map({ fullWindow = true, minHeight, onlyView = false }: Readonly<Props>) {
  const { map } = useMap();
  const [viewState, setViewState] = useRecoilState(mapViewState);
  const mapStyle = useRecoilValue(mapStyleState);
  const sidenavIsPinned = useRecoilValue(sidenavIsPinnedState);
  const mapPadding = useRecoilValue(mapPaddingState);
  const page = useRecoilValue(pageState);
  const [editingInstallation, setEditingInstallation] = useRecoilState(editingInstallationState);
  const [editInstallation, setEditInstallation] = useRecoilState(editInstallationState);

  // Time needed for side menu animation
  const mapResize = useDebounceFunction(function () {
    map?.resize();
  }, 300);

  useEffect(() => {
    queueMicrotask(() => {
      if (!sidenavIsPinned) {
        mapResize();
        return;
      }
      map?.resize();
    });
  }, [sidenavIsPinned]);

  useEffect(() => {
    queueMicrotask(() => {
      map?.resize();
    });
  }, [map]);

  const onMoveEnd = (ev: ViewStateChangeEvent) => {
    setViewState(ev.viewState);
  };

  const onClick = async (ev: MapLayerMouseEvent) => {
    if (onlyView) return;
    const point = {
      lat: ev.lngLat.lat,
      lng: ev.lngLat.lng,
    };
    const address = await getAddressFromPoint(point);
    if (page === INSTALLATIONS_DETAIL_PAGE.id && editingInstallation) {
      setEditingInstallation((prev) => ({
        ...prev!,
        georeference: address ?? '',
        latitude: point.lat,
        longitude: point.lng,
      }));
    } else if (page === INSTALLATIONS_PAGE.id && editInstallation) {
      setEditInstallation((prev) => ({
        ...prev!,
        georeference: address ?? '',
        latitude: point.lat,
        longitude: point.lng,
      }));
    }
  };

  return (
    <MapboxMap
      id="map"
      reuseMaps
      mapboxAccessToken={environment.mapboxAPIKey}
      initialViewState={{ ...viewState }}
      onClick={onClick}
      mapStyle={mapStyle}
      onMoveEnd={onMoveEnd}
      projection={{ name: 'mercator' }}
      padding={{
        left: mapPadding,
        bottom: 0,
        right: 0,
        top: 0,
      }}
      style={{
        position: fullWindow ? 'absolute' : undefined,
        flex: '1',
        minWidth: MIN_WIDTH_MAP,
        minHeight: minHeight ?? MIN_HEIGHT_MAP,
      }}
      scrollZoom={true}
      keyboard={true}
      doubleClickZoom={true}
      dragRotate={false}
      minZoom={0}
      maxZoom={18}
    >
      <TrafficLayer />
      {page !== ASSETS_PAGE.id && !onlyView && <MapSearchBox />}
      {page !== ASSETS_PAGE.id && <MapOptions />}
      {page === ASSETS_PAGE.id && <AssetClusters />}
      {page === DEVICES_PAGE.id && <DeviceMarker />}
      {page === INSTALLATIONS_DETAIL_PAGE.id && <InstallationDetailMarker onlyView={onlyView} />}
      {page === INSTALLATIONS_PAGE.id && <NewInstallationMarker />}
    </MapboxMap>
  );
}

export default memo(Map);
