import { atom, selector } from "recoil";
import { InstallationStatus, Campaign, InstallationAddressRequest, InstallationAddressRequestTouched, InstallationRequest, InstallationRequestTouched } from "../types/installation";
import { LogsTypes } from "../types/logs";
import { isValidLatitude, isValidLongitude } from "../utils/map";
import { ADDRESS_INSTALLATION_DEFAULTS, ADDRESS_INSTALLATION_TOUCHED_DEFAULTS, INSTALLATION_TABLE_COLUMNS_NAME, MINIMAL_MODEL_YEAR, NEW_INSTALLATION_DEFAULTS, NEW_INSTALLATION_TOUCHED_DEFAULTS } from "../constants/installations";
import { PaginatedColumn } from "../types/app";
import { EmissionClientRequest } from "../types/client";
import { validate as validateEmail } from "email-validator";


export const installationsStatusState = atom<InstallationStatus[] | undefined>({
  key: "app.installations.status",
  default: undefined,
});

export const installationsCampaignsState = atom<Campaign[] | undefined>({
  key: "app.installations.campaign",
  default: undefined,
});

export const logsTypesState = atom<LogsTypes | undefined>({
  key: "app.installations.log.type",
  default: undefined,
});

export const editingInstallationState = atom<InstallationAddressRequest>({
  key: "app.installation.editing",
  default: ADDRESS_INSTALLATION_DEFAULTS,
});

export const editingInstallationTouchedState =
  atom<InstallationAddressRequestTouched>({
    key: "vehicles.installation.edit.touched",
    default: ADDRESS_INSTALLATION_TOUCHED_DEFAULTS,
  });

export const editingInstallationValidState = selector<{
  [key in keyof InstallationAddressRequest]: boolean;
}>({
  key: "vehicles.installation.editing.valid",
  get: ({ get }) => {
    const fields = get(editingInstallationState);

    // SET ALL FIELDS TO BE VALID
    const validFields = Object.fromEntries(
      Object.keys(fields).map((key) => [key, true])
    ) as {
      [key in keyof InstallationAddressRequest]: boolean;
    };

    // VALIDATE REQUIRED FIELDS
    if (!fields.georeference) {
      validFields.georeference = false;
    }

    if (!fields.latitude || !isValidLatitude(fields.latitude)) {
      validFields.latitude = false;
    }

    if (!fields.longitude || !isValidLongitude(fields.longitude)) {
      validFields.longitude = false;
    }

    return validFields;
  },
});

export const editingInstallationErrorState = selector({
  key: "vehicles.installation.editing.error",
  get: ({ get }) => {
    const validFields = get(editingInstallationValidState);
    return Object.values(validFields).some((valid) => !valid);
  },
});

export const installationSearchState = atom<string | undefined>({
  key: "app.installation.filter.search",
  default: undefined,
});

export const installationFilterStatusState = atom<string>({
  key: "app.installation.filter.status",
  default: "",
});

export const installationFilterGDLState = atom<string | undefined>({
  key: "app.installation.filter.gdlStatus",
  default: undefined,
});

export const installationReloadListState = atom<boolean>({
  key: "app.installation.list.reload",
  default: false,
});

export const installationDownloadListState = atom<boolean>({
  key: "app.installation.list.download",
  default: false,
});

export const tableColumnsState = atom<PaginatedColumn[]>({
  key: "app.installation.table.columns",
  default: INSTALLATION_TABLE_COLUMNS_NAME,
});

export const sortInstallationState = selector<PaginatedColumn | undefined>({
  key: "vehicles.installation.sort.test",
  get: ({ get }) => {
    const columns = get(tableColumnsState);
    return columns.find((column) => column.sortDirection !== undefined);
  },
});

export const installationDateRangeState = atom<(number | null)[] | undefined>({
  key: "app.installation.filter.dateRange",
  default: undefined,
});

export const installationCampaignState = atom<string | undefined>({
  key: "app.installation.filter.campaign",
  default: undefined,
});

export const installationIvrState = atom<string | undefined>({
  key: "app.installation.filter.ivr",
  default: undefined,
});

// New installation
export const editInstallationState = atom<InstallationRequest>({
  key: "app.installation.edit",
  default: NEW_INSTALLATION_DEFAULTS,
});

export const editInstallationTouchedState = atom<InstallationRequestTouched>({
  key: "app.installation.edit.touched",
  default: NEW_INSTALLATION_TOUCHED_DEFAULTS,
});

export const editInstallationValidState = selector<{
  [key in keyof InstallationRequest]: boolean;
}>({
  key: "app.installation.edit.valid",
  get: ({ get }) => {
    const fields = get(editInstallationState);

    // SET ALL FIELDS TO BE VALID
    const validFields = Object.fromEntries(
      Object.keys(fields).map((key) => [key, true])
    ) as {
      [key in keyof InstallationRequest]: boolean;
    };

    // VALIDATE REQUIRED FIELDS
    if (!fields.brandId) {
      validFields.brandId = false;
    }
    if (!fields.subBrandId) {
      validFields.subBrandId = false;
    }

    if (!!fields.model || fields.model === 0) {
      const currtYear = new Date().getFullYear();
      if (fields.model < MINIMAL_MODEL_YEAR || fields.model > currtYear + 2)
        validFields.model = false;
    } else {
      validFields.model = false;
    }

    if (!fields.engineNumber) {
      validFields.engineNumber = false;
    }
    if (!fields.vin) {
      validFields.vin = false;
    }
    if (!fields.licensePlate) {
      validFields.licensePlate = false;
    }
    if (!fields.insuranceCompany) {
      validFields.insuranceCompany = false;
    }
    return validFields;
  },
});

export const editInstallationClientValidState = selector<{
  [key in keyof EmissionClientRequest]: boolean;
}>({
  key: "app.installation.edit.client.valid",
  get: ({ get }) => {
    const fields = get(editInstallationState).client;

    // SET ALL FIELDS TO BE VALID
    const validFields = Object.fromEntries(
      Object.keys(fields).map((key) => [key, true])
    ) as {
      [key in keyof EmissionClientRequest]: boolean;
    };

    // VALIDATE REQUIRED FIELDS
    if (!fields.name) {
      validFields.name = false;
    }
    if (!fields.lastName) {
      validFields.lastName = false;
    }
    if (!fields.phone || fields.phone.length < 10 || fields.phone.length > 15) {
      validFields.phone = false;
    }
    if (!fields.email || !validateEmail(fields.email)) {
      validFields.email = false;
    }
    if (fields.rfc && (fields.rfc.length < 12 || fields.rfc.length > 13)) {
      validFields.rfc = false;
    }

    return validFields;
  },
});

export const editInstallationErrorState = selector({
  key: "app.installation.edit.error",
  get: ({ get }) => {
    const validFields = get(editInstallationValidState);
    const validClientFields = get(editInstallationClientValidState);
    return (
      Object.values(validFields).some((valid) => !valid) ||
      Object.values(validClientFields).some((valid) => !valid)
    );
  },
});
