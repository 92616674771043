import { FlexRow, StyledButton, FlexColumn } from "@gorila-shared-ui/components";
import { useMemo, memo} from "react";
import { useStyles } from "../../../hooks/useStyles";
import { ClientSelect } from "../selects/ClientSelect";
import { Filter } from "baseui/table";
import { SubClientFilters } from "../../../types/subClient";
import { DEFAULT_SUB_CLIENT_FILTERS } from "../../../constants/subClients";
import { SetterOrUpdater } from "recoil";

type Props = {
  filters: SubClientFilters,
  setFilters: SetterOrUpdater<SubClientFilters>
};
function SubClientsFilters({ filters, setFilters }: Readonly<Props>) {
  const { theme, css } = useStyles();

  const isActive = useMemo(() => {
    if (filters?.clientId) return true;
    return false;
  }, [filters]);

  const onChangeLocalFilters = (key: keyof SubClientFilters, value: any) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const resetFilters = () => {
    setFilters(DEFAULT_SUB_CLIENT_FILTERS);
  };

  return (
    <Filter
      active={isActive}
      overrides={{
        Heading: {
          component: () => <></>,
        },
        Footer: {
          component: () => {
            return (
              <FlexRow
                classNames={css({
                  justifyContent: 'end',
                  padding: theme.sizing.scale200,
                })}
              >
                <StyledButton
                  onClick={resetFilters}
                  size="mini"
                  kind="tertiary"
                >
                  Limpiar filtros
                </StyledButton>
              </FlexRow>
            );
          },
        },
        Content: {
          style: () => ({
            borderTop: 'none',
          }),
        },
      }}
    >
      <FlexColumn
        classNames={css({
          paddingTop: theme.sizing.scale500,
          paddingBottom: theme.sizing.scale500,
          width: '170px',
        })}
        gap={theme.sizing.scale200}
      >
        <ClientSelect 
          selectedClientId={filters.clientId}
          onChangeClientId={(clientId) => onChangeLocalFilters('clientId', clientId)}
          isFilter
          dropdownOffset="75px"
        />
      </FlexColumn>
    </Filter>
  );
}

export default memo(SubClientsFilters);
