import { FlexColumn, FlexRow } from '@gorila-shared-ui/components';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useStyles } from '../../../hooks/useStyles';
import { Webhook } from '../../../types/webhook';
import { WebhookForm } from '../WebhookForm';
import { WebhookInfo } from '../WebhookInfo';

type Props = {
  isOpen: boolean;
  webhook?: Webhook;
  onClose: (update?: boolean) => void;
  clientId: string;
};
function WebhookDetailModal({ onClose, isOpen, webhook, clientId }: Props) {
  const { css } = useStyles();
  const [detailMode, setDetailMode] = useState(false);

  useEffect(() => {
    if (isOpen && webhook) setDetailMode(true);
  }, [isOpen]);

  useEffect(() => {
    if (!webhook) {
      setDetailMode(false);
      return;
    }
  }, [webhook]);

  return createPortal(
    <Modal
      animate
      closeable
      onClose={() => onClose()}
      isOpen={isOpen}
      size="auto"
    >
      <FlexRow
        gap={0}
        classNames={css({
          justifyContent: 'space-between',
          margin: '6px 2rem 0px 0px',
        })}
      >
        <ModalHeader
          className={css({
            marginBottom: '0 !important',
          })}
        >
          {!detailMode ? 'Nuevo webhook' : ''}
        </ModalHeader>
      </FlexRow>
      <ModalBody>
        <FlexColumn
          classNames={css({
            maxWidth: '1200px',
            minWidth: '550px',
            boxSizing: 'border-box',
            padding: '1rem',
            borderTop: '1px solid rgb(226, 226, 226)',
            maxHeight: '80vh',
            overflow: 'auto',
          })}
        >
          {detailMode && webhook && <WebhookInfo webhook={webhook} />}
          {!detailMode && (
            <WebhookForm
              webhook={webhook}
              onCancel={() => setDetailMode(false)}
              afterSave={() => onClose(true)}
              clientId={clientId}
            />
          )}
        </FlexColumn>
      </ModalBody>
    </Modal>,
    document.body
  );
}

export default memo(WebhookDetailModal);
