import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useAuth } from '../../hooks/useAuth';
import { currentPagePathState, isLoggedInState } from '../../storage/app';
import useEffectOnce from '../../hooks/useEffectOnce';
import useUpdateEffect from '../../hooks/useUpdateEffect';

export function AuthProvider() {
  const isLoggedIn = useRecoilValue(isLoggedInState);
  const setCurrentPagePath = useSetRecoilState(currentPagePathState);
  const navigate = useNavigate();
  const { initApp } = useAuth();
  const location = useLocation();

  useUpdateEffect(() => {
    const path = `${location.pathname}${location.search}`;
    setCurrentPagePath(path);
  }, [location])

  useEffectOnce(() => {
    const path = `${location.pathname}${location.search}`;
    setCurrentPagePath(path);
    initApp();
  });

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login', { replace: true });
    }
  }, [isLoggedIn]);

  return null;
}
