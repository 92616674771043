import { EllipsedText, FlexColumn, FlexRow, LabeledSelect, StyledButton } from '@gorila-shared-ui/components';
import { Banner } from 'baseui/banner';
import { StyledDivider } from 'baseui/divider';
import { FileUploader } from 'baseui/file-uploader';
import { HeadingXSmall, ParagraphMedium } from 'baseui/typography';
import { useMemo, useState } from 'react';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../constants/app';
import { ASSET_TYPE } from '../../constants/asset';
import {
  BULK_ASSETS_FILE,
  BULK_DEVICES_FILE,
  BULK_DISABLE_MONITORING_FILE,
  BULK_LINK_DEVICE_ASSETS_FILE,
  EMPTY_BULK,
} from '../../constants/bulk';
import { useFeedback } from '../../hooks/useFeedback';
import { useLoading } from '../../hooks/useLoading';
import { useStyles } from '../../hooks/useStyles';
import { createBulkFile } from '../../services/bulkService';
import { BulkEmpty, FileType } from '../../types/bulk';
import { FileTypePicker } from '../shared/FileTypePicker';
import { ClientSelect } from '../shared/selects/ClientSelect';
import { DeviceBrandSelect } from '../shared/selects/DeviceBrandSelect';
import { DeviceModelSelect } from '../shared/selects/DeviceModelSelect';
import { SubClientSelect } from '../shared/selects/SubClientSelect';

type Props = {
  onCancel: () => void;
  afterSave: () => void;
};
export function BulkForm({ onCancel, afterSave }: Props) {
  const { css, theme } = useStyles();
  const [editingBulk, setEditingBulk] = useState<BulkEmpty>(EMPTY_BULK);
  const { startLoading, stopLoading, loading } = useLoading(false);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();
  const [filesError, setFilesError] = useState('');

  const assetTypeOptions = Object.entries(ASSET_TYPE).map(([key, assetType]) => ({
    id: key,
    label: assetType,
  }));

  const canSubmit = useMemo(() => {
    if (!editingBulk.file || !editingBulk.fileType) return false;
    const hasClientAndSubClient = !!editingBulk.client && !!editingBulk.subClient;
    switch (editingBulk.fileType) {
      case BULK_LINK_DEVICE_ASSETS_FILE:
        if (hasClientAndSubClient) return true;
        break;
      case BULK_ASSETS_FILE:
        if (hasClientAndSubClient && editingBulk.assetType) return true;
        break;
      case BULK_DEVICES_FILE:
        if (editingBulk.device && editingBulk.brand) return true;
        break;
      case BULK_DISABLE_MONITORING_FILE:
        if (hasClientAndSubClient) return true;
        break;
    }
    return false;
  }, [editingBulk]);

  const onInputChange = (
    value: string | boolean | number | undefined | null | {} | File | FileType,
    field: keyof BulkEmpty
  ) => {
    setEditingBulk((prev) => ({ ...prev!, [field]: value }));
  };

  const onFileTypeChange = (fileType: FileType) => {
    setEditingBulk({
      ...EMPTY_BULK,
      fileType,
    });
  };

  const onSave = async () => {
    if (!canSubmit) return;
    startLoading();
    const { error } = await createBulkFile(editingBulk, editingBulk.file!);
    if (!error) {
      afterSave();
      showPositiveFeedback(FEEDBACK.created(FEEDBACK_PREFIXES.bulk));
    } else {
      showFailFeedback(error || FEEDBACK.failedCreation(FEEDBACK_PREFIXES.bulk));
    }
    stopLoading();
  };

  return (
    <FlexColumn
      classNames={css({
        justifyContent: 'space-between',
        overflow: 'hidden',
      })}
    >
      <FlexColumn
        classNames={css({
          overflow: 'auto',
          height: '100%',
        })}
      >
        <FileTypePicker
          onChange={onFileTypeChange}
          fileType={editingBulk.fileType}
        />
        {editingBulk.file && (
          <Banner
            hierarchy="low"
            overrides={{
              Root: {
                style: {
                  marginTop: 0,
                  marginRight: 0,
                  marginBottom: 0,
                  marginLeft: 0,
                },
              },
            }}
          >
            <FlexRow classNames={css({ alignItems: 'center', justifyContent: 'space-between' })}>
              <EllipsedText breakAll>{editingBulk.file.name}</EllipsedText>
              <StyledButton
                onClick={() => {
                  onInputChange(undefined, 'file');
                }}
              >
                Eliminar
              </StyledButton>
            </FlexRow>
          </Banner>
        )}
        {!editingBulk.file && (
          <FileUploader
            accept=".csv"
            onDrop={(acceptedFiles, rejectedFiles) => {
              if (rejectedFiles.length === 1) {
                setFilesError('El tipo de archivo no es válido, sube un archivo CSV.');
              } else if (rejectedFiles.length > 1) {
                setFilesError('Solo puedes subir un archivo a la vez.');
              } else {
                setFilesError('');
              }
              if (acceptedFiles.length === 1) {
                onInputChange(acceptedFiles[0], 'file');
              }
            }}
            errorMessage={filesError}
            onRetry={() => {
              setFilesError('');
            }}
            multiple={false}
            overrides={{
              ContentMessage: {
                component: () => (
                  <ParagraphMedium
                    marginTop={0}
                    marginBottom={theme.sizing.scale300}
                    color={theme.colors.contentSecondary}
                  >
                    Arrastra un archivo aquí o...
                  </ParagraphMedium>
                ),
              },
              RetryButtonComponent: {
                component: ({ onClick }) => (
                  <StyledButton
                    onClick={onClick}
                    overrides={{
                      BaseButton: {
                        style: { marginBottom: theme.sizing.scale800, marginTop: theme.sizing.scale300 },
                      },
                    }}
                  >
                    Aceptar
                  </StyledButton>
                ),
              },
              ButtonComponent: {
                component: ({ ...props }) => (
                  <StyledButton
                    {...props}
                    kind="primary"
                    shape="default"
                  >
                    Buscar archivos
                  </StyledButton>
                ),
              },
            }}
          />
        )}
        {editingBulk.fileType && (
          <>
            <StyledDivider
              $size="cell"
              style={{ width: '100%' }}
            />
            <HeadingXSmall margin={0}>
              {editingBulk.fileType === BULK_LINK_DEVICE_ASSETS_FILE && 'Datos para vincular los equipos con activos'}
              {editingBulk.fileType === BULK_ASSETS_FILE && 'Datos de los Activos'}
              {editingBulk.fileType === BULK_DEVICES_FILE && 'Datos de los Equipos'}
              {editingBulk.fileType === BULK_DISABLE_MONITORING_FILE && 'Datos para deshabilitar el monitoreo'}
            </HeadingXSmall>
            {editingBulk.fileType !== BULK_DEVICES_FILE && (
              <>
                <ClientSelect
                  client={editingBulk?.client?.name ? editingBulk.client : undefined}
                  selectedClientId={editingBulk?.client?._id}
                  onChangeClientId={(clientId) => onInputChange({ _id: clientId }, 'client')}
                  isFilter={false}
                  required
                />
                <SubClientSelect
                  subClient={editingBulk?.subClient?.name ? editingBulk.subClient : undefined}
                  selectedClientId={editingBulk?.client?._id}
                  selectedSubClientId={editingBulk?.subClient?._id}
                  onChangeSubClientId={(subClientId) => onInputChange({ _id: subClientId }, 'subClient')}
                  isFilter={false}
                  disabled={!editingBulk?.client?._id}
                  required
                />
              </>
            )}
            {editingBulk.fileType === BULK_ASSETS_FILE && (
              <LabeledSelect
                label="Tipo de activo"
                options={assetTypeOptions}
                value={[{ id: editingBulk?.assetType }]}
                onChange={({ option }) => {
                  onInputChange(option?.id, 'assetType');
                }}
                required
              />
            )}
            {editingBulk.fileType === BULK_DEVICES_FILE && (
              <>
                <DeviceBrandSelect
                  deviceBrand={editingBulk.brand}
                  selectedDeviceBrandId={editingBulk.brand?._id}
                  onChangeDeviceBrand={(brandId) => onInputChange({ _id: brandId }, 'brand')}
                  isFilter={false}
                  required
                />
                <DeviceModelSelect
                  deviceModel={editingBulk.device}
                  onChangeDeviceModel={(deviceModelId) => onInputChange({ _id: deviceModelId }, 'device')}
                  isFilter={false}
                  selectedDeviceModelId={editingBulk.device?._id}
                  deviceBrandId={editingBulk.brand?._id}
                  disabled={!editingBulk.brand?._id}
                  required
                />
              </>
            )}
          </>
        )}
      </FlexColumn>
      <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'flex-end' })}`}>
        <FlexRow gap={theme.sizing.scale300}>
          <StyledButton
            kind="tertiary"
            onClick={onCancel}
          >
            Cancelar
          </StyledButton>
          <StyledButton
            onClick={onSave}
            isLoading={loading}
            disabled={!canSubmit}
          >
            Guardar
          </StyledButton>
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  );
}
