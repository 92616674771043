import { useBreakpoints } from '../../hooks/useBreakpoints';
import { useStyles } from '../../hooks/useStyles';
import { FlexibleBox, GenericComponent } from '../../types/components';

export function FlexRow({ children, centered, gap, classNames, padding, breakPoint }: GenericComponent & FlexibleBox) {
  const { css, classes, theme } = useStyles();
  if (gap === undefined) gap = theme.sizing.scale600;
  const breakPoints = useBreakpoints();
  const styles = css({ gap, padding });
  return (
    <div 
      className={`
        ${breakPoint && breakPoints[breakPoint] ? classes.verticalFlex : classes.horizontalFlex}
        ${centered ? classes.centeredFlex : ''} 
        ${styles} ${classNames ?? ''}
      `}
    >
      {children}
    </div>
  );
}
