import { useEffect, useRef, EffectCallback, DependencyList } from 'react';

function useEffectOnce(effect: EffectCallback, deps?: DependencyList) {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return effect();
    }
  }, deps);
}

export default useEffectOnce;