import { PaginatedColumn } from '../types/app';
import { AdminUserRequest, AdminUserRequestTouched, UserMinimal } from '../types/user';

export const USER_TYPE_CLIENT = 1;
export const USER_TYPE_ADMIN = 2;
export const USER_TYPE_MONITORING = 3;

export const USER_TYPES = {
  [USER_TYPE_CLIENT]: 'Cliente',
  [USER_TYPE_ADMIN]: 'Admin',
  [USER_TYPE_MONITORING]: 'Monitorista',
};

export const GRANTED_PROJECT_MONITORING = 'monitoring';
export const GRANTED_PROJECT_ADMINISTRATIVE = 'administrative';
export const GRANTED_PROJECT_FSM = 'fsm';
export const GRANTED_PROJECT_CORE = 'core';

export const GRATED_PROJECTS = {
  [GRANTED_PROJECT_MONITORING]: 'Monitoreo',
  [GRANTED_PROJECT_ADMINISTRATIVE]: 'Administrativo',
  [GRANTED_PROJECT_FSM]: 'FSM',
};

export const USERS_TABLE_COLUMNS_NAME: PaginatedColumn[] = [
  {
    label: 'Usuario',
    key: 'name',
  },
  {
    label: 'Rol',
    key: 'role',
  },
  {
    label: 'Cliente',
    key: 'client',
  },
  {
    label: 'Sub Cliente',
    key: 'subClient',
  },
  {
    label: 'Sub Cuenta',
    key: 'subAccount',
  },
  {
    label: 'Acciones',
    key: 'actions',
    minWidth: 120,
    maxWidth: 120,
    centered: true,
  },
];

export const EMPTY_USER: UserMinimal = {
  _id: '',
  username: '',
  name: '',
  email: '',
  permissions: {},
  client: undefined,
  subClient: undefined,
  subAccount: undefined,
  password: undefined,
  confirmPassword: undefined,
};

export const ADMIN_USERS_TABLE_COLUMNS_NAME: PaginatedColumn[] = [
  {
    label: 'Usuario',
    key: 'username',
  },
  {
    label: 'Nombre',
    key: 'name',
  },
  {
    label: 'Correo',
    key: 'email',
  },
  {
    label: 'Acciones',
    key: 'actions',
    minWidth: 120,
    maxWidth: 120,
    centered: true,
  },
];

export const EMPTY_ADMIN_USER: AdminUserRequest = {
  username: '',
  name: '',
  email: '',
  phoneNumber: undefined,
  password: undefined,
  grantedProjects: [GRANTED_PROJECT_MONITORING, GRANTED_PROJECT_ADMINISTRATIVE, GRANTED_PROJECT_FSM],
};

export const NEW_ADMIN_USER_TOUCHED_DEFAULTS: AdminUserRequestTouched = {
  usernameTouched: false,
  nameTouched: false,
  emailTouched: false
};