import { FlexColumn } from '@gorila-shared-ui/components';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { memo } from 'react';
import { createPortal } from 'react-dom';
import { useStyles } from '../../../hooks/useStyles';
import { WebServiceLogsFilters } from '../WebServiceLogsFilters';

type Props = {
  isOpen: boolean;
  selectedClientId?: string;
  selectedSubClientId?: string;
  setSelectedClientId: (clientId?: string) => void;
  setSelectedSubClientId: (subClientId?: string) => void;
  onClose: () => void;
};

function WebServiceLogsFiltersModal({
  onClose,
  isOpen,
  selectedClientId,
  selectedSubClientId,
  setSelectedSubClientId,
  setSelectedClientId,
}: Props) {
  const { css, theme } = useStyles();

  return createPortal(
    <Modal
      animate
      closeable
      onClose={onClose}
      isOpen={isOpen}
      size="auto"
    >
      <ModalHeader>Filtros</ModalHeader>
      <ModalBody>
        <FlexColumn
          gap={theme.sizing.scale800}
          classNames={css({
            width: '70vw',
            maxWidth: '1280px',
            minWidth: '300px',
            boxSizing: 'border-box',
          })}
        >
          <WebServiceLogsFilters
            selectedClientId={selectedClientId}
            selectedSubClientId={selectedSubClientId}
            setSelectedClientId={setSelectedClientId}
            setSelectedSubClientId={setSelectedSubClientId}
          />
        </FlexColumn>
      </ModalBody>
    </Modal>,
    document.body
  );
}

export default memo(WebServiceLogsFiltersModal);
