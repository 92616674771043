import { EnhancedLabeledSelect } from '@gorila-shared-ui/components';
import { OnChangeParams } from 'baseui/select';
import { useMemo } from 'react';
import { WS_TYPE } from '../../constants/webService';
import { WsType } from '../../types/webService';

type Props = {
  onChange: (wsType: WsType) => void;
  wsType?: WsType;
  maxWidth?: number;
  disabled?: boolean;
  inline?: boolean;
  isFilter?: boolean;
  required?: boolean;
};

export function WebServiceTypePicker({
  onChange,
  wsType,
  maxWidth,
  disabled = false,
  inline = false,
  required = false,
  isFilter,
}: Readonly<Props>) {
  const wsTypeOptions = useMemo(() => {
    const options = [
      {
        id: undefined,
        label: isFilter ? 'Todos' : 'Ninguno',
      },
      ...Object.entries(WS_TYPE).map(([key, wsType]) => ({
        id: key,
        label: wsType,
      })),
    ];
    return options;
  }, [isFilter]);

  const selectConfig = {
    label: `Tipo: ${required ? '*' : ''}`,
    options: wsTypeOptions,
    onChange: (params: OnChangeParams) => {
      onChange(params.option?.id as WsType);
    },
    value: [{ id: wsType }],
  };

  return (
    <EnhancedLabeledSelect
      {...selectConfig}
      searchable
      inline={inline}
      inset={inline}
      maxWidth={maxWidth}
      disabled={disabled}
      fullWidth
    />
  );
}
