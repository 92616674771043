import { FullContainer } from '@gorila-shared-ui/components';
import { useStyles } from '../../../hooks/useStyles';
import { PhoneBlacklistTable } from '../../blacklist/PhoneBlacklistTable';
import PhoneTableFilters from '../../blacklist/PhoneTableFilters';

export function PhonesBlacklistPage() {
  const { css, theme } = useStyles();

  return (
    <FullContainer
      verticalFlex
      classNames={`${css({
        padding: theme.sizing.scale400,
        gap: theme.sizing.scale500,
        boxSizing: 'border-box',
      })}`}
    >
      <PhoneTableFilters />
      <PhoneBlacklistTable />
    </FullContainer>
  );
}
