import { atom } from 'recoil';
import { UserType } from '../types/user';

export const usersSearchFilterState = atom<string | undefined>({
  key: 'app.users.search.filter',
  default: undefined,
});

export const userTypeFilterState = atom<UserType | undefined>({
  key: 'app.users.userType.filter',
  default: undefined,
});

export const usersClientIdFilterState = atom<string | undefined>({
  key: 'app.users.clientId.filter',
  default: undefined,
});

export const usersSubClientIdFilterState = atom<string | undefined>({
  key: 'app.users.subClientId.filter',
  default: undefined,
});

export const usersSubAccountIdFilterState = atom<string | undefined>({
  key: 'app.users.subAccountId.filter',
  default: undefined,
});
