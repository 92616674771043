import { LightTheme, createThemedUseStyletron } from 'baseui';

type Breakpoints = {
  extraSmall: number;
  small: number;
  medium: number;
  large: number;
  extraLarge: number;
};

export type Breakpoint = keyof Breakpoints;

type MediaQuery = {
  extraSmall: string;
  small: string;
  medium: string;
  large: string;
  extraLarge: string;
};

const breakpoints: Breakpoints = {
  extraSmall: 320,
  small: 600,
  medium: 768,
  large: 992,
  extraLarge: 1280,
};

type CustomResponsive = {
  breakpoints: Breakpoints;
  mediaQuery: MediaQuery;
};

const ResponsiveTheme = Object.keys(breakpoints).reduce<CustomResponsive>(
  (acc: CustomResponsive, key: string) => {
    acc.mediaQuery[key as keyof MediaQuery] = `@media screen and (min-width: ${
      breakpoints[key as keyof Breakpoints]
    }px)`;
    return acc;
  },
  {
    breakpoints,
    mediaQuery: {} as MediaQuery,
  }
);

export const CustomTheme = {
  ...LightTheme,
  ...ResponsiveTheme,
};

export const themedUseStyletron = createThemedUseStyletron<typeof CustomTheme>();
