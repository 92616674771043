import { Position } from 'geojson';
import { LngLatBoundsLike, useMap } from 'react-map-gl';
import { Point } from '../types/geoModels';

const DEFAULT_DURATION = 1400;

export function useCamera() {
  const { map } = useMap();

  const flyTo = (location: Point) => {
    queueMicrotask(() => {
      map?.flyTo({
        center: location,
        duration: DEFAULT_DURATION,
      });
    });
  };

  const zoomIn = () => {
    queueMicrotask(() => map?.zoomIn());
  };

  const zoomOut = () => {
    queueMicrotask(() => map?.zoomOut());
  };

  const zoomTo = (location: Point | undefined, zoom = 15, duration = DEFAULT_DURATION) => {
    if (location && location.lat && location.lng) {
      queueMicrotask(() => {
        map?.flyTo({
          center: location,
          duration: duration,
          zoom: zoom,
        });
      });
    }
  };

  const fitToBounds = (minLat: number, minLng: number, maxLat: number, maxLng: number, duration = DEFAULT_DURATION) => {
    setTimeout(() => {
      const availableWidthSpace = (map?.getCanvasContainer().offsetWidth ?? 0) - (map?.getPadding().left ?? 0);
      const availableHeightSpace = (map?.getContainer().offsetHeight ?? 0) - (map?.getPadding().top ?? 0);
      const paddingWidth = 300 < availableWidthSpace ? 150 : 80 < availableWidthSpace ? 40 : 0;
      const paddingHeight= 300 < availableHeightSpace ? 150 : 80 < availableHeightSpace ? 40 : 0;
      const bounds: LngLatBoundsLike = [
        [minLng, minLat],
        [maxLng, maxLat],
      ];
      map?.fitBounds(bounds, { padding: { top: paddingHeight, bottom: paddingHeight, left: paddingWidth, right: paddingWidth }, duration: duration });
    }, 0);
  };

  const fitToLngLats = (lngLats?: Position[], duration = DEFAULT_DURATION) => {
    if (!lngLats || lngLats.length === 0) {
      return;
    }
    if (lngLats.length === 1) {
      const Point = {
        lat: lngLats[0][1],
        lng: lngLats[0][0],
      } as Point;
      zoomTo(Point);
      return;
    }

    const lats = lngLats.map((lngLat) => lngLat[1]);
    const lngs = lngLats.map((lngLat) => lngLat[0]);
    const minLng = Math.min(...lngs);
    const minLat = Math.min(...lats);
    const maxLat = Math.max(...lats);
    const maxLng = Math.max(...lngs);
    fitToBounds(minLat, minLng, maxLat, maxLng, duration);
  };

  return { flyTo, zoomTo, zoomIn, zoomOut, fitToLngLats };
}
