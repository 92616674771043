import { atom, selector } from 'recoil';
import { Asset } from '../types/asset';

export const assetsTypesState = atom<
  | {
      label: string;
      key: string;
    }[]
  | undefined
>({
  key: 'app.crm.assetsTypes',
  default: [],
});

export const assetTypeState = atom<'vehicle' | 'container' | 'phone' | undefined>({
  key: 'app.crm.assetType',
  default: undefined,
});

export const selectAssetState = atom<Asset | undefined>({
  key: 'app.crm.asset.select',
  default: undefined,
});

export const mapDevicesAssetGeoJson = selector<GeoJSON.Feature[]>({
  key: 'app.map.asset.geojson',
  get: ({ get }) => {
    const mapSelectAsset = get(selectAssetState);
    if (!mapSelectAsset?.cs?.position_latitude && !mapSelectAsset?.cs?.position_longitude) return [];

    const mapDevices = [
      {
        ident: mapSelectAsset?.device?.ident,
        point: {
          type: 'Point',
          coordinates: [mapSelectAsset?.cs?.position_longitude, mapSelectAsset?.cs?.position_latitude]
        }
      }
    ]

    mapSelectAsset?.secondariesCS?.map((device) => {
      mapDevices.push({
        ident: device?.ident,
        point: {
          type: 'Point',
          coordinates: [device.position_longitude, device.position_latitude]
        }
      })
    })    

    return mapDevices.map(
      (mapDevice) =>
        ({
          type: 'Feature',
          properties: { cluster: false, ident: mapDevice.ident },
          geometry: mapDevice.point,
        } as GeoJSON.Feature)
    );
  },
});