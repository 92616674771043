import { FullContainer } from '@gorila-shared-ui/components';
import { useStyles } from '../../../hooks/useStyles';
import InstallationsFiltersBar from '../../installations/InstallationsFiltersBar';
import { InstallationsList } from '../../installations/InstallationsList';

export function InstallationsPage() {
  const { css, theme } = useStyles();

  return (
    <FullContainer
      verticalFlex
      classNames={`${css({
        padding: theme.sizing.scale400,
        gap: theme.sizing.scale500,
        boxSizing: 'border-box',
      })}`}
    >
      <InstallationsFiltersBar />
      <InstallationsList />
    </FullContainer>
  );
}
