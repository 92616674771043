export const ROAD_MAP_TYPE = 'roadmap';
export const SATELLITE_MAP_TYPE = 'satellite';

export const MAP_CONFIGURATION = {
  [ROAD_MAP_TYPE]: {
    style: 'mapbox://styles/mapbox/streets-v12',
  },
  [SATELLITE_MAP_TYPE]: {
    style: 'mapbox://styles/mapbox/satellite-streets-v12',
  },
};