import { FlexRow, MaterialIcon, StyledButton, StyledSearchBar, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { WHATSAPP_FILE_EXAMPLE } from '../../constants/whatsapp';
import { useFeedback } from '../../hooks/useFeedback';
import { useModal } from '../../hooks/useModal';
import { useStyles } from '../../hooks/useStyles';
import { getFileUrlByKey } from '../../services/appService';
import NewCampaignModal from './modal/NewCampaignModal';

type Props = {
  search?: string;
  onChangeSearch: (search?: string) => void;
  onReloadPage: () => void;
};
export function WhatsappFiltersBar({ search, onChangeSearch, onReloadPage }: Props) {
  const { css, theme } = useStyles();
  const { openModal, closeModal, showModal } = useModal();
  const { showFailFeedback } = useFeedback();

  const onDownload = async () => {
    const { url, error } = await getFileUrlByKey(WHATSAPP_FILE_EXAMPLE);
    if (url) {
      window.open(url, '_blank');
    } else {
      showFailFeedback(error || 'Ocurrió un error al descargar el archivo, inténtalo más tarde.');
    }
  };

  return (
    <FlexRow
      classNames={css({
        justifyContent: 'space-between',
        width: '100%',
      })}
    >
      <FlexRow
        gap={theme.sizing.scale300}
        classNames={css({
          width: '50%',
        })}
      >
        <StyledSearchBar
          value={search ?? ''}
          onChange={onChangeSearch}
          clearable
        />
      </FlexRow>
      <FlexRow gap={theme.sizing.scale300}>
        <StyledTooltip
          content={'Plantilla'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={onDownload}
            kind="tertiary"
          >
            <MaterialIcon name={'download'} />
          </Button>
        </StyledTooltip>
        <StyledTooltip
          content={'Recargar tabla'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={onReloadPage}
            kind="tertiary"
          >
            <MaterialIcon name={'refresh'} />
          </Button>
        </StyledTooltip>
        <StyledButton
          onClick={openModal}
          startEnhancer={
            <MaterialIcon
              name="add"
              size="xs-mini"
              color={theme.colors.contentInversePrimary}
            />
          }
        >
          Nueva
        </StyledButton>
      </FlexRow>
      {showModal && (
        <NewCampaignModal
          onClose={(updateTable) => {
            if (updateTable) {
              onReloadPage();
            }
            closeModal();
          }}
          isOpen={showModal}
        />
      )}
    </FlexRow>
  );
}
