import { MinimalWebService } from '../types/webService';

export const WS_TYPE_UNIGIS = 'unigis';
export const WS_TYPE_RECURSO_CONFIABLE = 'recursoConfiable';

export const WS_TYPE = {
  [WS_TYPE_UNIGIS]: 'Unigis',
  [WS_TYPE_RECURSO_CONFIABLE]: 'Recurso confiable',
};

export const EMPTY_WEB_SERVICE: MinimalWebService = {
  _id: '',
  name: '',
  assignedAssets: [],
  data: undefined,
  subClient: undefined,
  wsType: undefined,
};

export const WEB_SERVICES_TABLE_COLUMNS_NAME = [
  {
    label: 'Nombre',
    key: 'name',
  },
  {
    label: 'Acciones',
    key: 'actions',
  },
];

export const WSDL_URL = 'WSDL_URL';
export const WSDL_USER = 'WSDL_USER';
export const NEW_WSDL_PASSWORD = 'NEW_WSDL_PASSWORD';

export const CUSTOMER_ID = 'CUSTOMER_ID';
export const CUSTOMER_NAME = 'CUSTOMER_NAME';
