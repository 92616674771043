import { useRecoilState, useResetRecoilState } from 'recoil';
import { getCommandsReports } from '../../../services/commandService';
import {
  commandsReportDateRangeState,
  commandsReportReloadListState,
} from '../../../storage/commandReportsFiltersState';

export function useCommandsReports() {
  const [dates, setDates] = useRecoilState(commandsReportDateRangeState);
  const resetDates = useResetRecoilState(commandsReportDateRangeState);
  const [reload, setReload] = useRecoilState(commandsReportReloadListState);

  const resetFilters = () => {
    resetDates();
  };

  const onReload = () => {
    setReload((prev) => !prev);
  };

  const loadCommandsReports = async (page: number, startDate?: number | null, endDate?: number | null) => {
    const { commands, error, total } = await getCommandsReports(page, startDate, endDate);
    return {
      commands,
      error,
      total,
    };
  };

  return {
    selectedCommandsDateRange: dates,
    onChangeDates: setDates,
    resetFilters,
    reload,
    onReload,
    loadCommandsReports,
  };
}
