import { FlexRow, MaterialIcon, StyledButton, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { HeadingSmall } from 'baseui/typography';
import { useNavigate } from 'react-router-dom';
import { INSTALLATIONS_PAGE } from '../../constants/app';
import { useModal } from '../../hooks/useModal';
import { useStyles } from '../../hooks/useStyles';
import { Installation } from '../../types/installation';
import ChangeStatusModal from './modal/ChangeStatusModal';

type Props = {
  installation: Installation;
  refreshData: () => void;
};
export function InstallationDetailHeader({ installation, refreshData }: Readonly<Props>) {
  const { css, theme } = useStyles();
  const { showModal, openModal, closeModal } = useModal();
  const navigate = useNavigate();

  return (
    <>
      <FlexRow classNames={css({ justifyContent: 'space-between' })}>
        <FlexRow
          classNames={css({ alignItems: 'center' })}
          gap={0}
        >
          <StyledTooltip
            content={'Regresar'}
            showArrow={false}
          >
            <Button
              size="mini"
              shape="square"
              onClick={() => {
                navigate(INSTALLATIONS_PAGE.route, { replace: false });
              }}
              kind="tertiary"
            >
              <MaterialIcon
                name={'arrow_back_ios'}
                size="mini"
              />
            </Button>
          </StyledTooltip>
          <HeadingSmall margin={theme.sizing.scale100}>Emisión {installation.uuidTramite ?? '-'}</HeadingSmall>
        </FlexRow>
        {!!installation.nextStatus.length && (
          <StyledButton
            kind="primary"
            onClick={openModal}
          >
            Actualizar Estado
          </StyledButton>
        )}
      </FlexRow>
      <ChangeStatusModal
        isOpen={showModal}
        onClose={(update) => {
          closeModal();
          if (update) refreshData();
        }}
        installation={installation}
      />
    </>
  );
}
