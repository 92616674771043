import { MemoExoticComponent, ReactElement } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { SetterOrUpdater } from 'recoil';
import { DEFAULT_LIST_ITEM_HEIGHT } from '../../constants/app';
import { useStyles } from '../../hooks/useStyles';
import { PaginatedResponse } from '../../types/api';
import { VirtualizedComponent } from '../../types/components';

type Props<T> = {
  elements?: string[] | T[];
  loading: boolean;
  onNextPage: SetterOrUpdater<number>;
  itemComponent: MemoExoticComponent<({ data, index, style }: VirtualizedComponent) => ReactElement>;
  paginatedResponse?: PaginatedResponse<T>;
  itemSize?: number;
};

export function VirtualizedPaginatedList<T>({
  elements,
  loading,
  paginatedResponse,
  onNextPage,
  itemComponent,
  itemSize = DEFAULT_LIST_ITEM_HEIGHT,
}: Props<T>) {
  const { css } = useStyles();
  const itemCount = paginatedResponse?.hasNext ? (elements?.length ?? 0) + 1 : elements?.length ?? 0;
  const isItemLoaded = (index: number) => !paginatedResponse?.hasNext || index < (elements?.length ?? 0);
  const loadMoreItems =
    loading || !paginatedResponse?.hasNext || !paginatedResponse
      ? () => {}
      : () => {
          onNextPage((prev) => prev + 1);
        };

  if (!elements) return null;

  return (
    <ul
      className={css({
        margin: 0,
        paddingLeft: 0,
        paddingRight: 0,
        height: '100%',
        flex: 1,
      })}
    >
      <AutoSizer>
        {({ width, height }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <List
                height={height ?? 500}
                itemCount={itemCount}
                itemSize={() => itemSize}
                width={width ?? 200}
                itemData={{
                  items: elements,
                  isLoaded: isItemLoaded,
                }}
                onItemsRendered={onItemsRendered}
                overscanCount={4}
                ref={ref}
              >
                {itemComponent}
              </List>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </ul>
  );
}
