import { MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { WEB_SERVICES_TABLE_COLUMNS_NAME } from '../../../constants/webService';
import { useLoading } from '../../../hooks/useLoading';
import { useModal } from '../../../hooks/useModal';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { getWebServices } from '../../../services/webServiceService';
import { webServicesSearchFilterState } from '../../../storage/webServices';
import { WebService } from '../../../types/webService';
import { ListWrapper } from '../../shared/ListWrapper';
import WebServiceDetailModal from './modal/WebServiceDetailModal';

type Props = {
  subClientId: string;
  reloadPage?: boolean;
};

export function WebServiceList({ subClientId, reloadPage }: Readonly<Props>) {
  const [webServices, setWebServices] = useState<WebService[]>();
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const { loading, startLoading, stopLoading } = useLoading();
  const search = useRecoilValue(webServicesSearchFilterState);
  const [selectedWebServiceId, setSelectedWebServiceId] = useState<string>();
  const { openModal, closeModal, showModal } = useModal();

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage, search]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadWebServicesTableData = async () => {
      const response = await getWebServices({
        subClientId,
        page,
        q: search,
      });
      if (response?.error) {
        setWebServices(undefined);
        setTotalItems(0);
      } else {
        setWebServices(response?.webServices);
        setTotalItems(response?.total ?? 0);
      }
      stopLoading();
    };
    loadWebServicesTableData();
  }, [page]);

  const openWebServiceModal = (webServiceId: string) => {
    setSelectedWebServiceId(webServiceId);
    openModal();
  };

  const closeWebServiceModal = (updateTable?: boolean) => {
    setSelectedWebServiceId(undefined);
    closeModal();
    if (updateTable) setPage(0);
  };

  return (
    <>
      <ListWrapper
        columns={WEB_SERVICES_TABLE_COLUMNS_NAME}
        data={webServices?.map((webService, i) => {
          return {
            items: [
              webService.name,
              <StyledTooltip
                key={`webService-action-${i}`}
                content={'Ver detalles'}
                showArrow={false}
              >
                <Button
                  size="compact"
                  shape="square"
                  onClick={() => openWebServiceModal(webService._id)}
                  kind="tertiary"
                >
                  <MaterialIcon name={'visibility'} />
                </Button>
              </StyledTooltip>,
            ],
          };
        })}
        hasData={webServices !== undefined}
        isEmpty={!webServices?.length}
        loading={loading}
        noDataDescription="Todavía no tienes ningun servicio externo"
        onPageChange={setPage}
        page={page}
        totalItems={totalItems}
      />
      {showModal && (
        <WebServiceDetailModal
          onClose={(updateTable) => closeWebServiceModal(updateTable)}
          isOpen={showModal}
          selectedWebServiceId={selectedWebServiceId}
          subClientId={subClientId}
        />
      )}
    </>
  );
}
