import { EllipsedText, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { ParagraphXSmall } from 'baseui/typography';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { USERS_TABLE_COLUMNS_NAME, USER_TYPES } from '../../constants/users';
import { useLoading } from '../../hooks/useLoading';
import { useModal } from '../../hooks/useModal';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { getUsers } from '../../services/userService';
import {
  userTypeFilterState,
  usersClientIdFilterState,
  usersSearchFilterState,
  usersSubAccountIdFilterState,
  usersSubClientIdFilterState,
} from '../../storage/users';
import { User } from '../../types/user';
import { ListWrapper } from '../shared/ListWrapper';
import UserDetailModal from './modal/UserDetailModal';

type Props = {
  reloadPage?: boolean;
};

export function UserList({ reloadPage }: Readonly<Props>) {
  const [users, setUsers] = useState<User[]>();
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const { loading, startLoading, stopLoading } = useLoading();
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const { openModal, closeModal, showModal } = useModal();
  const search = useRecoilValue(usersSearchFilterState);
  const selectedClientId = useRecoilValue(usersClientIdFilterState);
  const selectedSubClientId = useRecoilValue(usersSubClientIdFilterState);
  const selectedSubAccountId = useRecoilValue(usersSubAccountIdFilterState);
  const selectedUserType = useRecoilValue(userTypeFilterState);

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage, selectedClientId, selectedSubClientId, selectedSubAccountId, selectedUserType, search]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadUsersTableData = async () => {
      const response = await getUsers({
        page,
        clientId: selectedClientId,
        q: search,
        subAccountId: selectedSubAccountId,
        subClientId: selectedSubClientId,
        userType: selectedUserType,
      });
      if (response?.error) {
        setUsers(undefined);
        setTotalItems(0);
      } else {
        setUsers(response?.users);
        setTotalItems(response?.total ?? 0);
      }
      stopLoading();
    };
    loadUsersTableData();
  }, [page]);

  const openUserModal = (userId: string) => {
    setSelectedUserId(userId);
    openModal();
  };

  const closeUserModal = (updateTable?: boolean) => {
    setSelectedUserId(undefined);
    closeModal();
    if (updateTable) setPage(0);
  };

  return (
    <>
      <ListWrapper
        columns={USERS_TABLE_COLUMNS_NAME}
        data={users?.map((user, i) => {
          return {
            items: [
              <ParagraphXSmall
                margin={0}
                key={`user-name-${i}`}
              >
                <EllipsedText>{user.username ?? '-'}</EllipsedText>
              </ParagraphXSmall>,
              <ParagraphXSmall
                margin={0}
                key={`user-rol-${i}`}
              >
                <EllipsedText>{USER_TYPES[user.userType] ?? '-'}</EllipsedText>
              </ParagraphXSmall>,
              <ParagraphXSmall
                margin={0}
                key={`user-client-${i}`}
              >
                <EllipsedText>{user?.client?.name ?? '-'}</EllipsedText>
              </ParagraphXSmall>,
              <ParagraphXSmall
                margin={0}
                key={`user-subclient-${i}`}
              >
                <EllipsedText>{user?.subClient?.name ?? '-'}</EllipsedText>
              </ParagraphXSmall>,
              <ParagraphXSmall
                margin={0}
                key={`user-subaccount-${i}`}
              >
                <EllipsedText>{user.subAccount?.name ?? '-'}</EllipsedText>
              </ParagraphXSmall>,
              <StyledTooltip
                key={`user-action-${i}`}
                content={'Ver detalles'}
                showArrow={false}
              >
                <Button
                  size="compact"
                  shape="square"
                  onClick={() => openUserModal(user._id)}
                  kind="tertiary"
                >
                  <MaterialIcon name={'visibility'} />
                </Button>
              </StyledTooltip>,
            ],
          };
        })}
        hasData={users !== undefined}
        isEmpty={!users?.length}
        loading={loading}
        noDataDescription="Todavía no tienes ningun usuario"
        onPageChange={setPage}
        page={page}
        totalItems={totalItems}
      />
      {showModal && (
        <UserDetailModal
          onClose={(updateTable) => closeUserModal(updateTable)}
          isOpen={showModal}
          selectedUserId={selectedUserId}
        />
      )}
    </>
  );
}
