import { FlexColumn, FlexRow, MaterialIcon } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { ListItem, ListItemLabel } from 'baseui/list';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { memo } from 'react';
import { createPortal } from 'react-dom';
import { BULK_FILE_EXAMPLES } from '../../../constants/bulk';
import { useFeedback } from '../../../hooks/useFeedback';
import { useStyles } from '../../../hooks/useStyles';
import { getFileUrlByKey } from '../../../services/appService';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function BulkFileExamplesModal({ onClose, isOpen }: Props) {
  const { css } = useStyles();
  const { showFailFeedback } = useFeedback();

  const onDownload = async (key: string) => {
    const { url, error } = await getFileUrlByKey(key);
    if (url) {
      window.open(url, '_blank');
    } else {
      showFailFeedback(error || 'Ocurrió un error al descargar el archivo, inténtalo más tarde.');
    }
  };

  return createPortal(
    <Modal
      animate
      closeable
      onClose={() => onClose()}
      isOpen={isOpen}
      size="auto"
    >
      <FlexRow
        gap={0}
        classNames={css({
          justifyContent: 'space-between',
          margin: '6px 2rem 0px 0px',
        })}
      >
        <ModalHeader
          className={css({
            marginBottom: '0 !important',
          })}
        >
          Ejemplos de tipos de archivos
        </ModalHeader>
      </FlexRow>
      <ModalBody>
        <FlexColumn
          classNames={css({
            maxWidth: '1200px',
            minWidth: '450px',
            boxSizing: 'border-box',
            padding: '1rem',
            borderTop: '1px solid rgb(226, 226, 226)',
            maxHeight: '80vh',
            overflow: 'auto',
          })}
        >
          <ul
            className={css({
              paddingLeft: 0,
              paddingRight: 0,
            })}
          >
            {BULK_FILE_EXAMPLES.map((bulkFileExample, index) => (
              <ListItem
                key={index}
                endEnhancer={
                  bulkFileExample.url
                    ? () => (
                        <Button
                          size="compact"
                          kind="tertiary"
                          shape="circle"
                          onClick={() => onDownload(bulkFileExample.url)}
                        >
                          <MaterialIcon name="download" />
                        </Button>
                      )
                    : undefined
                }
                overrides={{
                  Content: { style: { marginLeft: 0, minHeight: '55px' } },
                }}
              >
                <ListItemLabel>{bulkFileExample.label}</ListItemLabel>
              </ListItem>
            ))}
          </ul>
        </FlexColumn>
      </ModalBody>
    </Modal>,
    document.body
  );
}

export default memo(BulkFileExamplesModal);
