import { atom, selector } from 'recoil';
import { Command } from '../types/command';

export const commandsState = atom<Command[] | undefined>({
  key: 'app.client.commands',
  default: undefined,
});

export const commandsSelectedState = atom<Command[] | undefined>({
  key: 'app.client.commands.selected',
  default: undefined,
});

export const filteredCommandsOptionsState = selector<Command[] | undefined>({
  key: 'app.client.commands.filtered',
  get: ({ get }) => {
    const commands = get(commandsState);

    if (!commands) return;
    let filteredCommands = [...commands];

    const commandsSelected = get(commandsSelectedState);

    if (commandsSelected) {
      const commandsSelectedNames = commandsSelected.map((command) => command.commandName)
      filteredCommands = filteredCommands.filter((command) => {
        if (commandsSelectedNames.includes(command.commandName)) return false
        return true
      });
    }

    return filteredCommands;
  },
});
