import { MaterialIcon, EllipsedText } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { ListItem, ListItemLabel } from 'baseui/list';
import { memo } from 'react';
import { IdName } from '../../../types/app';


type Props = {
  data: {
    subClients: IdName[];
    type: 'selectable' | 'deselectable';
    onClick: (subClientId: string) => void;
    isLoaded: (index: number) => boolean;
  };
  index: number;
  style: React.CSSProperties;
};

function SubClientItem({ data, index, style }: Readonly<Props>) {
  const subClient = data.subClients[index];

  return (
    <span style={style}>
      <ListItem
        endEnhancer={() => (
          <Button
            size="mini"
            shape="round"
            kind="tertiary"
            onClick={() => data.onClick(subClient._id)}
          >
            <MaterialIcon
              name={data.type === 'selectable' ? 'add' : 'remove'}
              size="mini"
            />
          </Button>
        )}
      >
        <ListItemLabel>
          <EllipsedText inline>{subClient?.name || '-'}</EllipsedText>
        </ListItemLabel>
      </ListItem>
    </span>
  );
}

export default memo(SubClientItem);
