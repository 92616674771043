import { FlexColumn, LabeledInput } from '@gorila-shared-ui/components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { REQUIRED_FIELD_TEXT, VELID_FIELD_TEXT } from '../../constants/app';
import {
  editInstallationClientValidState,
  editInstallationState,
  editInstallationTouchedState,
} from '../../storage/Installations';
import { EmissionClientRequest, EmissionClientRequestTouched } from '../../types/client';
import { LabeledEmailInput } from '../ui/LabeledEmailInput';
import { LabeledPhoneInput } from '../ui/LabeledPhoneInput';

export function InstallationClientForm() {
  const [form, setForm] = useRecoilState(editInstallationState);
  const [formTouched, setFormTouched] = useRecoilState(editInstallationTouchedState);
  const formValid = useRecoilValue(editInstallationClientValidState);

  const onTouched = (key: keyof EmissionClientRequestTouched) => {
    setFormTouched((prev) => ({
      ...prev,
      clientTouched: {
        ...prev.clientTouched,
        [key]: true,
      },
    }));
  };
  const onChangeInput = (value: any, field: keyof EmissionClientRequest) => {
    setForm((prev) => {
      return {
        ...prev,
        client: {
          ...prev.client,
          [field]: value || '',
        },
      };
    });
  };

  return (
    <FlexColumn>
      <LabeledInput
        label="Nombre"
        placeholder="Ingresa el nombre"
        value={form.client.name}
        onChange={(value) => {
          onChangeInput(value, 'name');
        }}
        onBlur={() => onTouched('nameTouched')}
        error={formTouched.clientTouched.nameTouched && !formValid.name && REQUIRED_FIELD_TEXT}
        required
      />
      <LabeledInput
        label="Apellido paterno"
        placeholder="Ingresa el apellido paterno"
        value={form.client.lastName}
        onChange={(value) => {
          onChangeInput(value, 'lastName');
        }}
        onBlur={() => onTouched('lastNameTouched')}
        error={formTouched.clientTouched.lastNameTouched && !formValid.lastName && REQUIRED_FIELD_TEXT}
        required
      />
      <LabeledInput
        label="Apellido materno"
        placeholder="Ingresa el apellido materno"
        value={form.client.motherName}
        onChange={(value) => {
          onChangeInput(value, 'motherName');
        }}
      />
      <LabeledInput
        label="RFC"
        placeholder="Ingresa el rfc"
        value={form.client.rfc}
        onChange={(value) => {
          onChangeInput(value, 'rfc');
        }}
        onBlur={() => onTouched('rfcTouched')}
        error={formTouched.clientTouched.rfcTouched && !formValid.rfc && VELID_FIELD_TEXT()}
      />
      <LabeledPhoneInput
        placeholder="Ingresa un número telefonico"
        value={form.client.phone}
        onChange={(value) => {
          onChangeInput(value, 'phone');
        }}
        type="simple"
        required
      />
      <LabeledEmailInput
        placeholder="Ingrese un correo electronico"
        value={form.client.email}
        onChange={(value) => {
          onChangeInput(value, 'email');
        }}
        required
      />
    </FlexColumn>
  );
}
