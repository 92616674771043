import { EllipsedText, FlexColumn, FlexRow, LabeledData } from '@gorila-shared-ui/components';
import { Block } from 'baseui/block';
import { StyledDivider } from 'baseui/divider';
import { LabelMedium, LabelSmall, ParagraphXSmall } from 'baseui/typography';
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { useMoment } from '../../hooks/useMoment';
import { useStyles } from '../../hooks/useStyles';
import { selectAssetState } from '../../storage/assets';
import { showTrafficLayerState } from '../../storage/map';
import { Asset } from '../../types/asset';
import Map from '../map/Map';
import AssetContainerData from './AssetContainerData';
import { AssetDataResume } from './AssetDataResume';
import AssetVehicleData from './AssetVehicleData';

type Props = {
  asset: Asset;
};
export function AssetInfo({ asset }: Readonly<Props>) {
  const { classes, css } = useStyles();
  const { formatDateFromTs } = useMoment();
  const { isSmall } = useBreakpoints();
  const setSelectAsset = useSetRecoilState(selectAssetState);
  const [showTrafficLayer, setShowTrafficLayer] = useRecoilState(showTrafficLayerState);

  useEffect(() => {
    if (showTrafficLayer) setShowTrafficLayer(false);
    if (!asset) {
      setSelectAsset(undefined);
      return;
    }
    setSelectAsset(asset);
  }, [asset]);

  return (
    <>
      <FlexRow breakPoint="isMedium">
        <AssetDataResume asset={asset} />
        <FlexColumn
          classNames={css({ flex: '100%', maxHeight: '300px' })}
          gap={'8px'}
        >
          <LabelMedium className={classes.dataHeaderClass}>Estado Actual</LabelMedium>
          <StyledDivider
            $size="cell"
            style={{ width: '100%' }}
          />
          <FlexColumn
            classNames={css({ overflow: 'auto' })}
            gap={0}
          >
            <FlexColumn gap={'4px'}>
              <FlexRow>
                <LabeledData
                  label={<LabelSmall minWidth={'75px'}>{'Dispositivo:'}</LabelSmall>}
                  content={<EllipsedText maxLines={5}>{asset.device?.ident ?? '-'}</EllipsedText>}
                />
                <LabeledData
                  label={<LabelSmall minWidth={'105px'}>{'Ultima posición:'}</LabelSmall>}
                  content={
                    <EllipsedText maxLines={5}>
                      {asset.cs?.timestamp ? formatDateFromTs(asset.cs.timestamp) : '-'}
                    </EllipsedText>
                  }
                />
              </FlexRow>
              <FlexColumn gap={0}>
                <LabelSmall>Georeferencia:</LabelSmall>
                <ParagraphXSmall margin={'0px 0px 0px 12px'}>
                  <EllipsedText maxLines={5}>{asset.cs?.geoReference || '-'}</EllipsedText>
                </ParagraphXSmall>
              </FlexColumn>
              <FlexRow>
                {asset.cs?.position_latitude && (
                  <LabeledData
                    label={<LabelSmall minWidth={'50px'}>{'Latitud:'}</LabelSmall>}
                    content={<EllipsedText maxLines={5}>{asset.cs.position_latitude.toString()}</EllipsedText>}
                  />
                )}
                {asset.cs?.position_longitude && (
                  <LabeledData
                    label={<LabelSmall minWidth={'50px'}>{'Longitud:'}</LabelSmall>}
                    content={<EllipsedText maxLines={5}>{asset.cs.position_longitude.toString()}</EllipsedText>}
                  />
                )}
              </FlexRow>
            </FlexColumn>
            <StyledDivider
              $size="cell"
              style={{ width: '100%' }}
            />

            {!!asset.secondariesCS?.length &&
              asset.secondariesCS?.map((secondary, i) => {
                return (
                  <>
                    <FlexColumn
                      gap={'4px'}
                      key={`secondary-${i}`}
                    >
                      <FlexRow>
                        <LabeledData
                          label={<LabelSmall minWidth={'75px'}>{'Dispositivo:'}</LabelSmall>}
                          content={<EllipsedText maxLines={5}>{secondary.ident ?? '-'}</EllipsedText>}
                        />
                        <LabeledData
                          label={<LabelSmall minWidth={'105px'}>{'Ultima posición:'}</LabelSmall>}
                          content={
                            <EllipsedText maxLines={5}>
                              {secondary.timestamp ? formatDateFromTs(secondary.timestamp) : '-'}
                            </EllipsedText>
                          }
                        />
                      </FlexRow>
                      <FlexColumn gap={0}>
                        <LabelSmall>Georeferencia:</LabelSmall>
                        <ParagraphXSmall margin={'0px 0px 0px 12px'}>
                          <EllipsedText maxLines={5}>{secondary.geoReference || '-'}</EllipsedText>
                        </ParagraphXSmall>
                      </FlexColumn>
                      <FlexRow>
                        {asset.cs?.position_latitude && (
                          <LabeledData
                            label={<LabelSmall minWidth={'50px'}>{'Latitud:'}</LabelSmall>}
                            content={<EllipsedText maxLines={5}>{secondary.position_latitude.toString()}</EllipsedText>}
                          />
                        )}
                        {asset.cs?.position_longitude && (
                          <LabeledData
                            label={<LabelSmall minWidth={'50px'}>{'Longitud:'}</LabelSmall>}
                            content={
                              <EllipsedText maxLines={5}>{secondary.position_longitude.toString()}</EllipsedText>
                            }
                          />
                        )}
                      </FlexRow>
                    </FlexColumn>
                    <StyledDivider
                      $size="cell"
                      style={{ width: '100%' }}
                    />
                  </>
                );
              })}
          </FlexColumn>
        </FlexColumn>
      </FlexRow>
      {(asset.cs?.geoReference || !!asset.secondariesCS?.length) && (
        <Block
          className={css({
            width: '100%',
          })}
        >
          <Map
            fullWindow={false}
            minHeight={isSmall ? '150px' : '250px'}
          />
        </Block>
      )}
      {asset.vehicle && asset.assetType === 'vehicle' && <AssetVehicleData vehicle={asset.vehicle} />}
      {asset.container && asset.assetType === 'container' && <AssetContainerData container={asset.container} />}
    </>
  );
}
