import { atom } from 'recoil';

export const clientUsersSearchFilterState = atom<string | undefined>({
  key: 'app.client.users.search.filter',
  default: undefined,
});

export const clientStreamaxSearchFilterState = atom<string | undefined>({
  key: 'app.client.streamax.search.filter',
  default: undefined,
});
