import { FlexRow, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { ParagraphMedium, ParagraphXSmall } from 'baseui/typography';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../constants/app';
import { useFeedback } from '../../hooks/useFeedback';
import { useModal } from '../../hooks/useModal';
import { useStyles } from '../../hooks/useStyles';
import { addRemovePhoneToBl } from '../../services/blackListService';
import { setValidInvalidPhone } from '../../services/installationService';
import { EmissionContactPhone } from '../../types/contact';
import { ConfirmModal } from '../shared/ConfirmModal';
import AddTagModal from './modal/AddTagModal';

type Props = {
  installationId: string;
  phone: EmissionContactPhone;
  refreshData: () => void;
};
export function InstallationPhone({ installationId, phone, refreshData }: Readonly<Props>) {
  const { css, theme } = useStyles();
  const { showModal, openModal, closeModal } = useModal();
  const { showModal: showTagModal, openModal: openTagModal, closeModal: closeTagModal } = useModal();
  const { openModal: openConfirmModal, closeModal: closeConfirmModal, showModal: showConfirmModal } = useModal();
  const { showPositiveFeedback, showFailFeedback } = useFeedback();
  const isValidPhone = phone.status === 'valid';

  const AddPhoneToBl = async () => {
    const tel = `${phone.lada}${phone.numero}`;
    const { error } = await addRemovePhoneToBl(tel.trim(), true);
    if (!error) {
      showPositiveFeedback(FEEDBACK.added(FEEDBACK_PREFIXES.phone));
      refreshData();
    } else {
      showFailFeedback(error);
    }
  };

  const setValidPhone = async () => {
    const tel = `${phone.lada}${phone.numero}`;
    const { error } = await setValidInvalidPhone(installationId, tel.trim(), true);
    if (!error) {
      showPositiveFeedback(FEEDBACK.remove(FEEDBACK_PREFIXES.phone));
      refreshData();
    } else {
      showFailFeedback(error);
    }
  };

  const setInvalidPhone = async () => {
    const tel = `${phone.lada}${phone.numero}`;
    const { error } = await setValidInvalidPhone(installationId, tel.trim(), false);
    if (!error) {
      showPositiveFeedback(FEEDBACK.added(FEEDBACK_PREFIXES.phone));
      refreshData();
    } else {
      showFailFeedback(error);
    }
  };

  return (
    <FlexRow
      classNames={css({ alignItems: 'center' })}
      gap={'4px'}
    >
      {phone.blacklisted !== undefined && !phone.blacklisted && (
        <>
          <a
            href={`tel:${phone.lada}${phone.numero}`}
            className={
              isValidPhone
                ? ''
                : css({
                    border: '1px solid red',
                    padding: '2px 4px',
                    borderRadius: '6px',
                  })
            }
          >
            {phone.lada ? '(' + phone.lada + ') ' : ''}
            {phone.numero}
          </a>
          <ParagraphXSmall margin={0}>{phone.extension ? ' - Ext. ' + phone.extension : ''}</ParagraphXSmall>
          <ParagraphXSmall margin={0}>{phone.tipo}</ParagraphXSmall>
        </>
      )}
      {phone.blacklisted && (
        <ParagraphXSmall
          margin={0}
          style={{ textDecoration: 'line-through' }}
        >
          {phone.lada ? '(' + phone.lada + ') ' : ''}
          {phone.numero}
          {phone.extension ? ' - Ext. ' + phone.extension : ''} {phone.tipo}
        </ParagraphXSmall>
      )}
      {phone.blacklisted !== undefined && !phone.blacklisted && (
        <FlexRow gap={0}>
          <StyledTooltip
            content={'Añadir a Lista Negra'}
            showArrow={false}
          >
            <Button
              size="mini"
              shape="square"
              onClick={openConfirmModal}
              kind="tertiary"
            >
              <MaterialIcon
                name="block"
                size="mini"
              />
            </Button>
          </StyledTooltip>
          <StyledTooltip
            content={isValidPhone ? 'Número equivocado' : 'No es número equivocado'}
            showArrow={false}
          >
            <Button
              size="mini"
              shape="square"
              onClick={openModal}
              kind="tertiary"
            >
              <MaterialIcon
                name={isValidPhone ? 'close' : 'check_circle'}
                size="mini"
                color={isValidPhone ? theme.colors.negative : theme.colors.positive}
              />
            </Button>
          </StyledTooltip>
        </FlexRow>
      )}
      <StyledTooltip
        content={'Añadir tag'}
        showArrow={false}
      >
        <Button
          size="mini"
          shape="square"
          onClick={openTagModal}
          kind="tertiary"
        >
          <MaterialIcon
            name="label"
            size="mini"
          />
        </Button>
      </StyledTooltip>
      <ConfirmModal
        isOpen={showConfirmModal}
        onCancel={closeConfirmModal}
        onConfirm={AddPhoneToBl}
        confirmText="Añadir"
        description={
          <ParagraphMedium>
            ¿Desea agregar a la lista negra el teléfono {phone.lada ? '(' + phone.lada + ') ' : ''}
            {phone.numero}?
          </ParagraphMedium>
        }
      />
      <ConfirmModal
        isOpen={showModal}
        onCancel={closeModal}
        onConfirm={() => {
          if (isValidPhone) setInvalidPhone();
          else setValidPhone();
        }}
        confirmText="Confirmar"
        description={
          <ParagraphMedium>
            {isValidPhone
              ? `¿Marcar el teléfono "${phone.lada ? '(' + phone.lada + ') ' : ''}${
                  phone.numero
                }" como número equivocado?`
              : `¿Desmarcar el teléfono "${phone.lada ? '(' + phone.lada + ') ' : ''}${
                  phone.numero
                }" como número equivocado?`}
          </ParagraphMedium>
        }
      />
      <AddTagModal
        isOpen={showTagModal}
        onClose={(update) => {
          closeTagModal();
          if (update) {
            refreshData();
          }
        }}
        tags={phone.tags}
        phone={`${phone.lada}${phone.numero}`.trim()}
      />
    </FlexRow>
  );
}
