export const environment = {
  production: false,
  awsProjectRegion: 'us-east-1',
  awsCognitoRegion: 'us-east-1',
  awsCognitoIdentityPool: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL,
  awsUserPoolsId: process.env.REACT_APP_AWS_USER_POOLS_ID,
  awsUserPoolsWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  awsOauthDomain: 'crm-gorilamx.auth.us-east-1.amazoncognito.com',
  awsOauthScope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'],
  awsOauthResponseType: 'code',
  awsAPIEndpointName: 'apiGorila',
  awsAPIEndpointUrl: process.env.REACT_APP_API_ENDPOINT_URL,
  awsAPIEmissionsEndpointName: 'apiEmissions',
  awsAPIEmissionsEndpointUrl: process.env.REACT_APP_API_EMISSIONS_ENDPOINT_URL,
  awsMediaS3Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
  awsMediaS3BucketRegion: 'us-east-1',
  googleMapsAPIKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  mapboxAPIKey: process.env.REACT_APP_MAPBOX_API_KEY,
  datadogClientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  datadogSite: process.env.REACT_APP_DATADOG_SITE,
  datadogService: process.env.REACT_APP_DATADOG_SERVICE,
  originGnpId: process.env.REACT_APP_ORIGIN_GNP_ID,
};
