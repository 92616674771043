import { MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { DatePicker } from 'baseui/datepicker';
import { useRecoilState } from 'recoil';
import useEffectOnce from '../../hooks/useEffectOnce';
import { useStyles } from '../../hooks/useStyles';
import { commsDateRangeState } from '../../storage/reports';
import { Button } from 'baseui/button';
import { FlexRow } from '../ui/FlexRow';

export function CommsReportFilters() {
  const { theme } = useStyles();
  const [commsDateRange, setCommsDateRange] = useRecoilState(commsDateRangeState);

  useEffectOnce(() => {
    setCommsDateRange(undefined);
    return () => {
      setCommsDateRange(undefined);
    };
  });

  const startDate = commsDateRange && commsDateRange[0] ? new Date(commsDateRange[0]) : undefined;
  const endDate = commsDateRange && commsDateRange[1] ? new Date(commsDateRange[1]) : undefined;

  return (
    <FlexRow gap={theme.sizing.scale200} centered>
      <DatePicker
        endDateLabel="Fecha fin:"
        mask="9999/99/99"
        maxDate={new Date()}
        range
        separateRangeInputs
        size="compact"
        startDateLabel="Fecha inicio:"
        value={[startDate, endDate]}
        onClose={() => {
          if (commsDateRange) {
            setCommsDateRange([commsDateRange[0], commsDateRange[1]]);
          } else {
            setCommsDateRange(undefined);
          }
        }}
        onChange={({ date: dates }) => {
          if (dates instanceof Array) {
            if (dates[0] && dates[1]) {
              setCommsDateRange([dates[0].getTime(), dates[1].getTime()]);
            } else if (dates[0]) {
              setCommsDateRange([dates[0].getTime(), null]);
            }
          }
        }}
      />
      <StyledTooltip
        content={'Limpiar filtro'}
        showArrow={false}
      >
        <Button
          size="compact"
          shape="square"
          onClick={() => setCommsDateRange(undefined)}
          kind="tertiary"
        >
          <MaterialIcon name={'clear'} />
        </Button>
      </StyledTooltip>
  </FlexRow>
  );
}
