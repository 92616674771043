import { MinimalSubAccount } from '../types/subAccount';

export const SUB_ACCOUNTS_TABLE_COLUMNS_NAME = [
  {
    label: 'Nombre',
    key: 'name',
  },
  {
    label: 'Acciones',
    key: 'actions',
  },
];

export const EMPTY_SUB_ACCOUNT: MinimalSubAccount = {
  _id: '',
  name: '',
  assets: [],
  client: undefined,
  subClient: undefined,
};
