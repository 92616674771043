import { OutputFormat, fromLatLng, setDefaults } from 'react-geocode';
import { environment } from '../environments/environment';
import { Point } from '../types/geoModels';

setDefaults({
  key: environment.googleMapsAPIKey ?? '',
  language: 'es',
  outputFormat: OutputFormat.JSON,
});

export const getAddressFromPoint = async (point: Point) => {
  try {
    const response = await fromLatLng(point.lat, point.lng);
    const address: string = response.results[0].formatted_address;

    return address;
  } catch (error) {
    console.log('something went wrong', error);
  }
};
