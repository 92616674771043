import { PaginatedColumn } from '../types/app';

export const TABLE_COLUMNS_NAME: PaginatedColumn[] = [
  {
    label: 'Placa',
    key: 'plate',
  },
  {
    label: 'Ident',
    key: 'ident',
  },
  {
    label: 'Etiqueta',
    key: 'label',
  },
  {
    label: 'Comando Enviado',
    key: 'commandType',
  },
  {
    label: 'Fecha Solicitud',
    key: 'created',
  },
  {
    label: 'Estatus',
    key: 'status',
  },
  {
    label: 'Usuario',
    key: 'username',
  },
];

export const COMMAND_PENDING_STATUS = 'Pendiente';
export const COMMAND_SUCCESS_STATUS = 'Completado';
export const COMMAND_EXPIRED_STATUS = 'Expirado';

export const COMMANDS_STATUS: { [key: string]: string } = {
  pending: COMMAND_PENDING_STATUS,
  success: COMMAND_SUCCESS_STATUS,
  expired: COMMAND_EXPIRED_STATUS,
};

export const COMMANDS_HINTS = {
  command: `Res;{ident};419.03;60;400;{normal_frequency};10;2;{emergency_frequency};200;30;3;;;;;"

Entre {} iría el nombre del campo que se desea usar de la lista de fields.`,
  properties: '{ "enabled": true }',
  fields: `{
    "hour": {
      "name": "Hora del dia reporteo",
      "helpText": "Hora del dia a la que se desea el equipo 
      reporte estando configurado como 1 reporte al dia.",
      "fieldType": "number",
      "defaultValue": 1600,
      "required": true
    }
  }`,
  logs: `“Se configura para que reporte cada {normal_frequency} (frecuencia normal) y cada {emergency_frequency} (Frecuencia emergencia)”
    
Entre {} iría el nombre del campo que se desea usar de la lista de fields.`,
};

export const COMMANDS_REPORTS_TABLE_COLUMNS_NAME: PaginatedColumn[] = [
  {
    label: 'Nombre',
    key: 'name',
  },
  {
    label: 'Estado',
    key: 'status',
  },
  {
    label: 'Creado',
    key: 'created',
  },
  {
    label: '',
    key: 'actions',
  },
];

export const COMMAND_ORIGIN_FLESPI = 'flespi';
export const COMMAND_ORIGIN_IOTSUITE = 'iotsuite';

export const COMMAND_ORIGINS: { [key: string]: string } = {
  flespi: COMMAND_ORIGIN_FLESPI,
  iotsuite: COMMAND_ORIGIN_IOTSUITE,
};
