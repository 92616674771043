import { ContentContainer } from '@gorila-shared-ui/components';
import { useState } from 'react';
import { UserFiltersBar } from '../../users/UserFiltersBar';
import { UserList } from '../../users/UserList';

export function UsersPage() {
  const [reloadPage, setReloadPage] = useState(false);

  return (
    <ContentContainer>
      <UserFiltersBar onReloadPage={() => setReloadPage(!reloadPage)} />
      <UserList reloadPage={reloadPage} />
    </ContentContainer>
  );
}
