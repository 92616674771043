import { FlexColumn, FlexRow, Loading, MaterialIcon, StyledButton } from '@gorila-shared-ui/components';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { ParagraphMedium } from 'baseui/typography';
import { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../../constants/app';
import {
  BULK_ERROR_STATUS,
  BULK_PARTIALLY_PROCESSED_STATUS,
  BULK_PROCESSED_STATUS,
  BULK_VALID_STATUS,
} from '../../../constants/bulk';
import { useFeedback } from '../../../hooks/useFeedback';
import { useLoading } from '../../../hooks/useLoading';
import { useModal } from '../../../hooks/useModal';
import { useStyles } from '../../../hooks/useStyles';
import { deleteBulkFile, getBulkFile, postBulkFile } from '../../../services/bulkService';
import { Bulk } from '../../../types/bulk';
import { ConfirmModal } from '../../shared/ConfirmModal';
import { BulkInfo } from '../BulkInfo';

type Props = {
  isOpen: boolean;
  selectedBulkFileId?: string;
  onClose: (update?: boolean) => void;
};

function BulkDetailModal({ onClose, isOpen, selectedBulkFileId }: Readonly<Props>) {
  const { css, theme, classes } = useStyles();
  const [bulkFile, setBulkFile] = useState<Bulk>();
  const { startLoading, stopLoading, loading } = useLoading(false);
  const { startLoading: startProcessing, stopLoading: stopProcessing, loading: processing } = useLoading(false);
  const { openModal: openConfirmModal, closeModal: closeConfirmModal, showModal: showConfirmModal } = useModal();
  const { showFailFeedback, showPositiveFeedback } = useFeedback();

  useEffect(() => {
    if (!selectedBulkFileId) {
      setBulkFile(undefined);
      return;
    }
    loadBulkFileData(selectedBulkFileId);
  }, [selectedBulkFileId]);

  const loadBulkFileData = async (bulkFileId: string) => {
    startLoading();
    const response = await getBulkFile(bulkFileId);
    if (response?.error) {
      setBulkFile(undefined);
      showFailFeedback(response.error || 'No se pudo cargar el detalle, inténtalo más tarde.');
      onClose();
    } else {
      setBulkFile(response?.bulkFile);
    }
    stopLoading();
  };

  const onDeleteBulk = async () => {
    if (!selectedBulkFileId) return;
    const response = await deleteBulkFile(selectedBulkFileId);
    if (response?.error) {
      showFailFeedback(response.error || FEEDBACK.deleted(FEEDBACK_PREFIXES.bulkFile, selectedBulkFileId));
    } else {
      showPositiveFeedback(FEEDBACK.deleted(FEEDBACK_PREFIXES.bulkFile, selectedBulkFileId));
    }
    closeConfirmModal();
    onClose(true);
  };

  const onProcessBulk = async () => {
    if (!selectedBulkFileId) return;
    startProcessing();
    const response = await postBulkFile(selectedBulkFileId);
    if (response?.error) {
      showFailFeedback(response.error || 'No se pudo procesar, inténtalo más tarde.');
    } else {
      showPositiveFeedback('El archivo está siendo procesado.');
    }
    stopProcessing();
    onClose(true);
  };

  return (
    <>
      {createPortal(
        <Modal
          animate
          closeable
          onClose={() => onClose()}
          isOpen={isOpen}
          size="auto"
        >
          {loading && (
            <FlexColumn
              centered
              classNames={css({
                height: '400px',
                width: '400px',
              })}
            >
              <Loading />
            </FlexColumn>
          )}
          {!loading && bulkFile && (
            <>
              <FlexRow
                gap={0}
                classNames={css({
                  justifyContent: 'space-between',
                  margin: '6px 2rem 0px 0px',
                })}
              >
                <ModalHeader
                  className={css({
                    marginBottom: '0 !important',
                  })}
                >
                  {bulkFile.rows.length > 0 ? 'Archivo ' : 'Campos '}
                  {bulkFile.status === BULK_ERROR_STATUS && 'con errores'}
                  {bulkFile.status === BULK_VALID_STATUS && 'valido'}
                  {bulkFile.status === BULK_PARTIALLY_PROCESSED_STATUS && 'en proceso'}
                  {bulkFile.status === BULK_PROCESSED_STATUS && 'cargado'}
                </ModalHeader>
              </FlexRow>
              <ModalBody>
                <FlexColumn
                  classNames={css({
                    width: '70vw',
                    maxWidth: '1200px',
                    minWidth: '350px',
                    boxSizing: 'border-box',
                    padding: '1rem',
                    borderTop: '1px solid rgb(226, 226, 226)',
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    gap: theme.sizing.scale600,
                  })}
                >
                  <BulkInfo bulkFile={bulkFile} />
                </FlexColumn>
              </ModalBody>
              <ModalFooter>
                <FlexRow
                  gap={theme.sizing.scale300}
                  classNames={classes.centeredEnd}
                >
                  <StyledButton
                    kind="tertiary"
                    onClick={openConfirmModal}
                    startEnhancer={
                      <MaterialIcon
                        name="delete"
                        size="mini"
                      />
                    }
                  >
                    Borrar
                  </StyledButton>
                  {bulkFile.status === BULK_VALID_STATUS && (
                    <StyledButton
                      onClick={onProcessBulk}
                      isLoading={processing}
                    >
                      Procesar
                    </StyledButton>
                  )}
                </FlexRow>
              </ModalFooter>
            </>
          )}
        </Modal>,
        document.body
      )}
      <ConfirmModal
        isOpen={showConfirmModal}
        onCancel={closeConfirmModal}
        onConfirm={onDeleteBulk}
        confirmText="Eliminar"
        description={<ParagraphMedium>¿Desea eliminar el archivo?</ParagraphMedium>}
      />
    </>
  );
}

export default memo(BulkDetailModal);
