import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { installationsCampaignsState } from "../../storage/Installations";
import { getCampaignList } from "../../services/installationService";

function CampaignProvider() {
  const setCampaigns = useSetRecoilState(installationsCampaignsState);

  useEffect(() => {
    const getInstallationStatus = async () => {
      const { campaigns } = await getCampaignList();
      if (campaigns) setCampaigns(campaigns);
      else setCampaigns(undefined);
    };
    getInstallationStatus();
  });

  return null;
}

export default CampaignProvider;
