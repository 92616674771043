import { ApiError } from '../types/apiResponseError';
import { AdminUser, AdminUserRequest, User, UserMinimal, UserType } from '../types/user';
import { deleteRequest, getRequest, postRequest } from './api';
import { URLS } from './urls/urls';

//platform users
export const getUsers = async ({
  clientId,
  subClientId,
  subAccountId,
  userType,
  page,
  q,
}: {
  clientId?: string;
  subClientId?: string;
  subAccountId?: string;
  userType?: UserType;
  page?: number;
  q?: string;
}): Promise<{ users?: User[]; error?: ApiError; total?: number }> => {
  const params = {
    clientId,
    subClientId,
    subAccountId,
    userType,
    page,
    q,
  };
  const response = await getRequest(URLS.users.all, {
    queryParams: params,
  });
  return {
    users: response?.data?.items,
    total: response?.data?.total,
    error: response?.error,
  };
};

export const getUser = async (userId: string): Promise<{ user?: User; error?: ApiError }> => {
  const response = await getRequest(URLS.users.detail(userId), {});
  return {
    user: response?.data,
    error: response?.error,
  };
};

export const postUser = async (user: UserMinimal): Promise<{ id?: string; error?: ApiError }> => {
  const body: { [key: string]: any } = {
    ...user,
    clientId: user.client?._id,
    subClientId: user.subClient?._id,
    subAccountId: user.subAccount?._id,
  };
  const response = await postRequest(URLS.users.create, {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const updateUser = async (user: UserMinimal): Promise<{ id?: string; error?: ApiError }> => {
  const body: { [key: string]: any } = {
    ...user,
    clientId: user.client?._id,
    subClientId: user.subClient?._id,
    subAccountId: user.subAccount?._id,
  };
  const response = await postRequest(URLS.users.update(user._id), {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const updateUserPassword = async (userId: string, password: string): Promise<{ error?: ApiError }> => {
  const body = {
    password,
  };
  const response = await postRequest(URLS.users.changePassword(userId), {
    body,
  });
  return {
    error: response?.error,
  };
};


//admin users
export const getAdminUsers = async ({
  page,
  q,
}: {
  page?: number;
  q?: string;
}): Promise<{ users?: AdminUser[]; error?: ApiError; total?: number }> => {
  const params = {
    page,
    q,
  };
  const response = await getRequest(URLS.adminUsers.all, {
    queryParams: params,
  });
  return {
    users: response?.data?.items,
    total: response?.data?.total,
    error: response?.error,
  };
};

export const postAdminUser = async (user: AdminUserRequest): Promise<{ id?: string; error?: ApiError }> => {
  const body: { [key: string]: any } = {
    ...user,
  };
  const response = await postRequest(URLS.adminUsers.create, {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const updateAdminUser = async (user: AdminUser): Promise<{ id?: string; error?: ApiError }> => {
  const body: { [key: string]: any } = {
    ...user,
  };
  const response = await postRequest(URLS.adminUsers.update(user._id), {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const updateAdminUserPassword = async (userId: string, password: string ): Promise<{ error?: ApiError }> => {
  const body = {
    password,
  };
  const response = await postRequest(URLS.adminUsers.update(userId), {
    body,
  });
  return {
    error: response?.error,
  };
};

export const deleteAdminUser = async (userId: string): Promise<{ error?: ApiError }> => {
  const response = await deleteRequest(URLS.adminUsers.delete(userId));
  return {
    error: response?.error,
  };
};