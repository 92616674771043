import { EllipsedText, FlexRow, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { DEVICE_MODEL_COMMANDS_PAGE } from '../../constants/app';
import { DEVICE_MODEL_TABLE_COLUMNS_NAME } from '../../constants/deviceModel';
import { useLoading } from '../../hooks/useLoading';
import { useModal } from '../../hooks/useModal';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { getDeviceModelsList } from '../../services/deviceService';
import { DeviceModel } from '../../types/deviceModel';
import { getPageRoute } from '../../utils/pages';
import { ListWrapper } from '../shared/ListWrapper';
import DeviceModelDetailModal from './modal/DeviceModelDetailModal';

type Props = {
  search?: string;
  reloadPage?: boolean;
  deviceBrandId?: string;
};
export function DeviceModelsList({ search, reloadPage, deviceBrandId }: Readonly<Props>) {
  const navigate = useNavigate();
  const [deviceModels, setDeviceModels] = useState<DeviceModel[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const { openModal, closeModal, showModal } = useModal();
  const [selectedDeviceModel, setSelectedDeviceModel] = useState<DeviceModel>();

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage, search, deviceBrandId]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadDeviceModels = async () => {
      const { models, error, total } = await getDeviceModelsList(page, search, deviceBrandId);
      if (!error && models) {
        setDeviceModels(models);
        setTotalItems(total);
      } else {
        setDeviceModels(undefined);
        setTotalItems(0);
      }
      stopLoading();
    };
    loadDeviceModels();
  }, [page]);

  const openDetailModal = (deviceModel: DeviceModel) => {
    setSelectedDeviceModel(deviceModel);
    openModal();
  };

  const closeDetailModal = (updateTable?: boolean) => {
    setSelectedDeviceModel(undefined);
    closeModal();
    if (updateTable) setPage(0);
  };

  const goCommandsView = (itemId: string) => {
    const route = getPageRoute(DEVICE_MODEL_COMMANDS_PAGE.id);
    if (route) navigate(generatePath(route, { modelId: itemId }), { replace: false });
  };

  return (
    <>
      <ListWrapper
        columns={DEVICE_MODEL_TABLE_COLUMNS_NAME}
        data={deviceModels?.map((deviceModel, i) => {
          return {
            items: [
              <EllipsedText key={`device-model-name-${i}`}>{deviceModel.name}</EllipsedText>,
              <EllipsedText key={`device-brand-name-${i}`}>{deviceModel.brand.name}</EllipsedText>,
              <FlexRow key={`details-${deviceModel._id}`}>
                <StyledTooltip
                  content={'Ver detalle'}
                  showArrow={false}
                >
                  <Button
                    size="compact"
                    shape="square"
                    onClick={() => openDetailModal(deviceModel)}
                    kind="tertiary"
                  >
                    <MaterialIcon name={'visibility'} />
                  </Button>
                </StyledTooltip>
                <StyledTooltip
                  content={'Ver commandos'}
                  showArrow={false}
                >
                  <Button
                    size="compact"
                    shape="square"
                    onClick={() => goCommandsView(deviceModel._id)}
                    kind="tertiary"
                  >
                    <MaterialIcon name={'settings_remote'} />
                  </Button>
                </StyledTooltip>
              </FlexRow>,
            ],
          };
        })}
        hasData={deviceModels !== undefined}
        isEmpty={!deviceModels?.length}
        loading={loading}
        noDataDescription="Todavía no tienes ningun modelo"
        onPageChange={setPage}
        page={page}
        totalItems={totalItems}
      />
      {showModal && (
        <DeviceModelDetailModal
          onClose={(updateTable) => closeDetailModal(updateTable)}
          isOpen={showModal}
          selectedDeviceModel={selectedDeviceModel}
        />
      )}
    </>
  );
}
