import { TreeNodeData, TreeView, toggleIsExpanded } from 'baseui/tree-view';
import { appPermissionState, filteredAppPermissionState } from '../../../storage/permissions';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Permissions } from '../../../types/permissions';
import { usePermissionsTree } from './hooks/usePermissionsTree';
import useUpdateEffect from '../../../hooks/useUpdateEffect';

type Props = {
  userPermissions: Permissions;
  userId?: string;
  disabled?: boolean;
  onChangePermissions?: (newPermissions: Permissions) => void;
  useFilteredPermission?: boolean;
  disableScroll?: boolean;
};
export function PermissionTree({
  userPermissions,
  onChangePermissions,
  disabled = true,
  userId,
  useFilteredPermission = false,
  disableScroll = false,
}: Readonly<Props>) {
  const appPermission = useRecoilValue(appPermissionState);
  const filteredAppPermission = useRecoilValue(filteredAppPermissionState);
  const { getPermissionTreeData } = usePermissionsTree(useFilteredPermission);
  const [data, setData] = useState<TreeNodeData[]>();

  useEffect(() => {
    if (!appPermission || !filteredAppPermission) return;
    const newData = [
      getPermissionTreeData({
        permission: useFilteredPermission ? filteredAppPermission : appPermission,
        userPermissions,
        disabled,
        onChangePermissions,
      }),
    ];
    if (data) {
      newData[0].isExpanded = data[0].isExpanded;
      newData[0].children = newData[0].children?.map((treeNode, index) => ({
        ...treeNode,
        isExpanded: data[0].children && data[0].children[index].isExpanded,
      }));
    }
    setData(newData);
  }, [userPermissions, useFilteredPermission]);

  useUpdateEffect(() => {
    setData((prevData) => {
      if (!prevData) return undefined;
      const newData = [...prevData];
      newData[0].isExpanded = false;
      newData[0].children = newData[0].children?.map((treeNode, index) => ({ ...treeNode, isExpanded: false }));
      return newData;
    });
  }, [userId]);

  if (!data) return null;

  return (
    <TreeView
      data={data}
      indentGuides
      onToggle={(node) => setData((prevData) => toggleIsExpanded(prevData!, node))}
      overrides={disableScroll ? { Root: { style: { overflow: 'visible' } } } : undefined}
    />
  );
}
