import { FlexColumn, FlexRow, LabeledInput } from '@gorila-shared-ui/components';
import { LabelMedium } from 'baseui/typography';
import { useEffect, useState } from 'react';
import { Contact } from '../../types/contact';
import { LabeledEmailInput } from './LabeledEmailInput';
import { LabeledPhoneInput } from './LabeledPhoneInput';

type Props = {
  contact?: Contact;
  onChange: (contact: Contact) => void;
  label: string;
  isValid?: (valid: boolean) => void;
};
export function ContactForm({ label, contact, onChange, isValid }: Readonly<Props>) {
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  const validateContact = (value: Contact): boolean => {
    if (value?.phone && !isValidPhone) return false;
    if (value?.email && !isValidEmail) return false;
    return true;
  };

  useEffect(() => {
    if (contact && isValid) {
      isValid(validateContact(contact));
    }
  }, [isValidEmail, isValidPhone]);

  const onInputChange = (value: string | undefined | null, field: keyof Contact) => {
    onChange({
      ...contact!,
      [field]: value,
    });
  };

  return (
    <FlexColumn>
      <LabelMedium>{label}</LabelMedium>
      <LabeledInput
        label="Nombre:"
        value={contact?.fullName}
        onChange={(value) => onInputChange(value, 'fullName')}
      />
      <FlexRow>
        <LabeledPhoneInput
          value={contact?.phone}
          onChange={(phone) => onInputChange(phone, 'phone')}
          type="simple"
          isPhoneValid={setIsValidPhone}
        />
        <LabeledEmailInput
          value={contact?.email}
          onChange={(email) => onInputChange(email, 'email')}
          isEmailValid={setIsValidEmail}
        />
      </FlexRow>
    </FlexColumn>
  );
}
