import { Helmet } from 'react-helmet';
import { useRecoilValue } from 'recoil';
import { pageState } from '../../storage/app';
import { getPageTitle } from '../../utils/pages';

type Props = {
  title?: string;
};

export function HeadTitle({ title }: Props) {
  const page = useRecoilValue(pageState);
  if (!title) title = getPageTitle(page);

  return (
    <Helmet>
      <title>CRM {title ? `- ${title}` : ''}</title>
    </Helmet>
  );
}
