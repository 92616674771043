import { atom, selector } from "recoil";
import { WhatsappRequest, WhatsappRequestTouched } from "../types/whatsapp";
import { WHATSAPP_DEFAULTS, WHATSAPP_TOUCHED_DEFAULTS } from "../constants/whatsapp";

export const whatsappsSearchState = atom<string | undefined>({
  key: 'app.whatsapps.search',
  default: undefined,
});

export const editWhatsappState = atom<WhatsappRequest>({
  key: 'whatsapps.edit',
  default: WHATSAPP_DEFAULTS,
});

export const editWhatsappTouchedState = atom<WhatsappRequestTouched>({
  key: 'whatsapps.edit.touched',
  default: WHATSAPP_TOUCHED_DEFAULTS,
});

export const editWhatsappValidState = selector<{ [key in keyof WhatsappRequest]: boolean }>({
  key: 'whatsapps.edit.valid',
  get: ({ get }) => {
    const fields = get(editWhatsappState);

    // SET ALL FIELDS TO BE VALID
    const validFields = Object.fromEntries(Object.keys(fields).map((key) => [key, true])) as {
      [key in keyof WhatsappRequest]: boolean;
    };

    // VALIDATE REQUIRED FIELDS
    if (!fields.name) {
      validFields.name = false;
    }
    if (!fields.file) {
      validFields.file = false;
    }
    if (!fields.template) {
      validFields.template = false;
    }

    return validFields;
  },
});

export const editWhatsappErrorState = selector({
  key: 'whatsapps.edit.error',
  get: ({ get }) => {
    const validFields = get(editWhatsappValidState);
    return Object.values(validFields).some((valid) => !valid);
  },
});