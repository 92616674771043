import { validatePassword } from '../utils/password';

export function usePasswordValidation(password?: string, confirmPassword?: string) {
  const passwordValidation = (errors: string) => {
    if (!password) return;
    const hasErrors = validatePassword(password, { repeatPassword: confirmPassword });
    if (!hasErrors) return;
    return hasErrors[errors];
  };

  const isValidPassword = (() => {
    if (!!password && !!confirmPassword) {
      if (!!passwordValidation('errors') || !!passwordValidation('repeatPasswordErrors')) return false;
      return true;
    }
    return false;
  })();

  return {
    passwordValidation,
    isValidPassword,
  };
}
