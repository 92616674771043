export const SUB_BRANDS_TABLE_COLUMNS_NAME = [
  {
    label: 'Nombre',
    key: 'name',
  },
  {
    label: 'Marca',
    key: 'brand',
  },
  {
    label: 'Acciones',
    key: 'actions',
  },
];