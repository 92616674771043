import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { useLoading } from '../../hooks/useLoading';
import { useModal } from '../../hooks/useModal';
import { ListWrapper } from '../shared/ListWrapper';
import { getSafeGptConfigList } from '../../services/safeGptService';
import { SafeGptConfiguration } from '../../types/safeGpt';
import { SAFEGPT_TABLE_COLUMNS_NAME } from '../../constants/safeGpt';
import SafeGptConfigModal from './modal/SafeGptConfigModal';
import { EllipsedText, StyledTooltip, MaterialIcon } from '@gorila-shared-ui/components';

type Props = {
  search?: string;
  reloadPage?: boolean;
};
export function SafeGptConfigList({ search, reloadPage }: Readonly<Props>) {
  const [safeGptConfigs, setSafeGptConfigs] = useState<SafeGptConfiguration[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const { openModal, closeModal, showModal } = useModal();
  const [selectedSafeGptConfig, setSelectedSafeGptConfig] = useState<SafeGptConfiguration>();

  const loadSafeGptConfigs = async () => {
    startLoading();
    const { configurations, error } = await getSafeGptConfigList(search);
    if (!error && configurations) {
      setSafeGptConfigs(configurations);
    } else {
      setSafeGptConfigs(undefined);
    }
    stopLoading();
  };

  useEffect(() => {
    loadSafeGptConfigs();
  }, [reloadPage, search]);

  const openDetailModal = (safeGptConfig: SafeGptConfiguration) => {
    setSelectedSafeGptConfig(safeGptConfig);
    openModal();
  };

  const closeDetailModal = (updateTable?: boolean) => {
    setSelectedSafeGptConfig(undefined);
    closeModal();
    if (updateTable) loadSafeGptConfigs();
  };

  return (
    <>
      <ListWrapper
        columns={SAFEGPT_TABLE_COLUMNS_NAME}
        data={
          safeGptConfigs?.map((safeGpt, i) => {
            return {
              items: [
                <EllipsedText key={`safe-gpt-name-${i}`}>{safeGpt.name || '-'}</EllipsedText>,
                <EllipsedText key={`safe-gpt-timeWindow-${i}`}>{`${safeGpt.timeWindow ? safeGpt.timeWindow : 0} min.`}</EllipsedText>,
                <EllipsedText key={`safe-gpt-totalEvents-${i}`}>{`${safeGpt.totalEvents ? safeGpt.totalEvents : 0}`}</EllipsedText>,
                <EllipsedText key={`safe-gpt-totalDistinct-${i}`}>{`${safeGpt.totalDistinct ? safeGpt.totalDistinct : 0}`}</EllipsedText>,
                <EllipsedText key={`safe-gpt-ignitionStatus-${i}`}>
                  {!safeGpt.ignitionStatus ? safeGpt.ignitionStatus === null ? '-' : 'No' : 'Si' }
                </EllipsedText>,
                <EllipsedText key={`safe-gpt-continuousDriving-${i}`}>{`${safeGpt.continuousDriving ? safeGpt.continuousDriving : 0} min.`}</EllipsedText>,
                <EllipsedText key={`safe-gpt-active-${i}`}>{safeGpt.active ? 'Si' : 'No'}</EllipsedText>,
                <StyledTooltip
                  key={`safe-gpt-details-${safeGpt._id}`}
                  content={'Editar'}
                  showArrow={false}
                >
                  <Button
                    size="compact"
                    shape="square"
                    onClick={() => openDetailModal(safeGpt)}
                    kind="tertiary"
                  >
                    <MaterialIcon name={'edit'} />
                  </Button>
                </StyledTooltip>,
              ],
            };
          }) ?? []
        }
        hasData={safeGptConfigs !== undefined}
        isEmpty={!safeGptConfigs?.length}
        loading={loading}
        noDataDescription="Todavía no tienes ninguna configuración"
      />
      {showModal && selectedSafeGptConfig && <SafeGptConfigModal
        onClose={(updateTable) => closeDetailModal(updateTable)}
        isOpen={showModal}
        safeGptConfig={selectedSafeGptConfig}
      />}
    </>
  );
}
