import { FullContainer } from '@gorila-shared-ui/components';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { useStyles } from '../../hooks/useStyles';
import Map from '../map/Map';

function PageLayout() {
  const { css } = useStyles();
  const hasMap = false;

  return (
    <FullContainer
      flexible
      classNames={css({
        position: 'relative',
        overflow: 'hidden',
      })}
    >
      {hasMap && <Map />}
      <Outlet />
    </FullContainer>
  );
}

export default memo(PageLayout);
