import { FlexRow, MaterialIcon, StyledButton } from '@gorila-shared-ui/components';
import { StatefulMenu } from 'baseui/menu';
import { StatefulPopover } from 'baseui/popover';
import { useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { useStyles } from '../../../hooks/useStyles';

export function UserMenu() {
  const { theme, classes } = useStyles();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { logout } = useAuth();

  const onItemSelect = (item: any, close: () => void) => {
    if (item.id === 'logout') {
      logout();
    }
  };

  return (
    <StatefulPopover
      content={({ close }) => (
        <StatefulMenu
          onItemSelect={({ item }) => onItemSelect(item, close)}
          items={[
            {
              id: 'logout',
              label: (
                <FlexRow
                  classNames={classes.centeredStart}
                  gap={theme.sizing.scale400}
                >
                  <MaterialIcon name="logout" />
                  Sign out
                </FlexRow>
              ),
            },
          ]}
        />
      )}
      placement={'bottomRight'}
      triggerType={'click'}
      onOpen={() => setMenuIsOpen(true)}
      onClose={() => setMenuIsOpen(false)}
    >
      <span>
        <StyledButton
          size="compact"
          kind="tertiary"
        >
          <FlexRow
            centered
            gap={theme.sizing.scale300}
          >
            <MaterialIcon name="account_circle" />
          </FlexRow>
        </StyledButton>
      </span>
    </StatefulPopover>
  );
}
