import { useState } from 'react';

export function useInitialLoadState(initialState: boolean = true) {
  const [initialLoad, setInitialLoad] = useState(initialState);

  const completeInitialLoad = () => {
    setInitialLoad(false);
  };

  const resetInitialLoad = () => {
    setInitialLoad(true);
  };

  return {
    completeInitialLoad,
    resetInitialLoad,
    initialLoad,
  };
}
