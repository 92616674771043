import { environment } from '../environments/environment';
import { Installation } from '../types/installation';

export function useInstallations(installation?: Installation) {
  let clientId = '';
  if (installation?.origin === 'gnp') {
    const originGnpId = environment.originGnpId;
    if (originGnpId) clientId = originGnpId;
  }

  const clientName = `${installation?.poliza.contratante?.nombre} ${installation?.poliza.contratante?.apePaterno} ${installation?.poliza.contratante?.apeMaterno}`;

  const socialReason = installation?.poliza.contratante.razonSocial;

  const driverName = `${installation?.poliza.conductorHabitual?.nombre} ${installation?.poliza.conductorHabitual?.apePaterno} ${installation?.poliza.conductorHabitual?.apeMaterno}`;

  const installationVehicle = installation?.poliza.vehiculo;

  const policy = installation?.poliza;

  return {
    clientId,
    clientName,
    driverName,
    installationVehicle,
    policy,
    socialReason,
  };
}
