import { FlexColumn } from '@gorila-shared-ui/components';
import { LabelMedium } from 'baseui/typography';
import { useStyles } from '../../hooks/useStyles';
import { StyledCard } from './styledCard/StyledCard';
import { StyledCardBodyWrapper } from './styledCard/StyledCardBodyWrapper';

type Props = {
  code: Object;
  label?: string;
};

export function CodeBlock({ code, label }: Readonly<Props>) {
  const { css, theme } = useStyles();

  const codeStyles = css({
    whiteSpace: 'pre',
    fontSize: theme.sizing.scale550,
  });

  const jsonStringyfied = JSON.stringify(code, null, 4);

  return (
    <FlexColumn gap={theme.sizing.scale300}>
      {label && <LabelMedium>{label}</LabelMedium>}
      <StyledCard
        rootStyleOverrides={{
          backgroundColor: theme.colors.backgroundSecondary,
        }}
      >
        <StyledCardBodyWrapper>
          <code className={codeStyles}>{jsonStringyfied}</code>
        </StyledCardBodyWrapper>
      </StyledCard>
    </FlexColumn>
  );
}
