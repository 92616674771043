import { ContentContainer } from '@gorila-shared-ui/components';
import { useState } from 'react';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import { BrandsFiltersBar } from '../../brands/BrandsFiltersBar';
import { BrandsList } from '../../brands/BrandsList';

export function BrandsPage() {
  const { search, setSearch, debouncedSearch } = useDebouncedSearch('');
  const [reloadPage, setReloadPage] = useState(false);

  return (
    <ContentContainer>
      <BrandsFiltersBar
        search={search}
        onChangeSearch={setSearch}
        onReloadPage={() => setReloadPage(!reloadPage)}
      />
      <BrandsList
        search={debouncedSearch}
        reloadPage={reloadPage}
      />
    </ContentContainer>
  );
}
