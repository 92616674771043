import { FixedMarkerOverrides, FloatingMarker } from 'baseui/map-marker';
import { memo, useEffect } from 'react';
import { Marker, MarkerDragEvent } from 'react-map-gl';
import { useRecoilState } from 'recoil';
import { editInstallationState } from '../../../storage/Installations';
import { useCamera } from '../../../hooks/useCamera';
import { useStyles } from '../../../hooks/useStyles';
import { getAddressFromPoint } from '../../../utils/geocode';
import { isValidLatitude, isValidLongitude } from '../../../utils/map';


function NewInstallationMarker() {
  const { theme } = useStyles();
  const [editInstallation, setEditInstallation] = useRecoilState(editInstallationState);
  const { zoomTo } = useCamera();

  useEffect(() => {
    if (!editInstallation?.latitude || !editInstallation?.longitude) return;
    const point = {
      lng: editInstallation.longitude,
      lat: editInstallation.latitude,
    };
    const isValidPoint = isValidLatitude(point.lat) && isValidLongitude(point.lng);
    if (isValidPoint) {
      zoomTo(point);
    }
  }, [editInstallation]);

  if (!editInstallation?.latitude || !editInstallation?.longitude) return null;

  const floatingMarkerOverrides: FixedMarkerOverrides = {
    Root: {
      style: () => ({
        transform: `translateY(-50%)`,
      }),
    },
    OuterAnchor: {
      style: { backgroundColor: theme.colors.negative },
    },
  };

  const point = {
    lng: editInstallation.longitude,
    lat: editInstallation.latitude,
  };

  const isValidPoint = isValidLatitude(point.lat) && isValidLongitude(point.lng);

  if (!isValidPoint) return null;

  const onDragEnd = async (event: MarkerDragEvent) => {
    const address = await getAddressFromPoint(event.lngLat);
    setEditInstallation((prev) => ({
      ...prev!,
      georeference: address ?? '',
      latitude: event.lngLat.lat,
      longitude: event.lngLat.lng
    }));
  };

  return (
    <Marker
      latitude={point.lat}
      longitude={point.lng}
      draggable
      onDragEnd={onDragEnd}
    >
      <FloatingMarker
        label={editInstallation.georeference.trim() ? editInstallation.georeference : 'Sin nombre'}
        overrides={floatingMarkerOverrides}
        size="small"
        anchorType="circle"
      />
    </Marker>
  );
}

export default memo(NewInstallationMarker);
