import {
  EllipsedText,
  EmptyState,
  FlexColumn,
  FlexRow,
  Loading,
  MaterialIcon,
  StyledButton,
  StyledTooltip,
} from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { Table } from 'baseui/table';
import { LabelMedium, ParagraphMedium } from 'baseui/typography';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../constants/app';
import { DEVICE_MODEL_COMMANDS_TABLE_COLUMNS_NAME } from '../../constants/deviceModel';
import { useFeedback } from '../../hooks/useFeedback';
import { useLoading } from '../../hooks/useLoading';
import { useModal } from '../../hooks/useModal';
import { useStyles } from '../../hooks/useStyles';
import { getDeviceModel, updateDevicesModel } from '../../services/deviceService';
import { DeviceModel } from '../../types/deviceModel';
import { ConfirmModal } from '../shared/ConfirmModal';
import { StyledRouterLink } from '../ui/StyledRouterLink';
import DeviceModelCommandsModal from './modal/DeviceModelCommandsModal';

export function DeviceModelCommandsList() {
  const { css, classes, theme } = useStyles();
  const { modelId } = useParams();
  const [deviceModel, setDeviceModel] = useState<DeviceModel>();
  const { loading, startLoading, stopLoading } = useLoading();
  const { openModal: openConfirmModal, closeModal: closeConfirmModal, showModal: showConfirmModal } = useModal();
  const { openModal: openFormModal, closeModal: closeFormModal, showModal: showFormModal } = useModal();
  const [selectedCommandIndex, setSelectedCommandIndex] = useState<number>();
  const { showFailFeedback, showPositiveFeedback } = useFeedback();

  const loadDeviceModels = async (deviceModelId: string) => {
    startLoading();
    const { model, error } = await getDeviceModel(deviceModelId);
    if (!error && model) {
      setDeviceModel(model);
    } else {
      setDeviceModel(undefined);
    }
    stopLoading();
  };

  useEffect(() => {
    if (!modelId) return;
    loadDeviceModels(modelId);
  }, [modelId]);

  const openDetailModal = (commandIndex: number) => {
    setSelectedCommandIndex(commandIndex);
    openFormModal();
  };

  const openConfirmDeleteModal = (commandIndex: number) => {
    setSelectedCommandIndex(commandIndex);
    openConfirmModal();
  };

  const closeDetailModal = (updateTable?: boolean) => {
    setSelectedCommandIndex(undefined);
    closeFormModal();
    if (updateTable && modelId) loadDeviceModels(modelId);
  };

  const onDelete = async () => {
    if (selectedCommandIndex) {
      let model = { ...deviceModel! };
      model.commands?.splice(selectedCommandIndex, 1);
      const { id, error } = await updateDevicesModel(model);
      if (!error && id) {
        showPositiveFeedback(
          FEEDBACK.deleted(
            FEEDBACK_PREFIXES.deviceModelCommand,
            deviceModel?.commands![selectedCommandIndex].commandName
          )
        );
      } else {
        showFailFeedback(
          error ||
            FEEDBACK.deleted(
              FEEDBACK_PREFIXES.deviceModelCommand,
              deviceModel?.commands![selectedCommandIndex].commandName
            )
        );
      }
    }
    closeConfirmModal();
    setSelectedCommandIndex(undefined);
  };

  return (
    <FlexColumn classNames={`${classes.flexFill} ${css({ overflow: 'hidden' })}`}>
      {!deviceModel && !loading && <EmptyState title="Todavía no tienes ningun commando" />}
      {!deviceModel && loading && <Loading />}
      {deviceModel?.commands && (
        <>
          <FlexRow
            classNames={css({
              justifyContent: 'space-between',
              padding: '8px 8px 0px',
            })}
          >
            <FlexRow
              gap={0}
              centered
            >
              <StyledRouterLink to={'/equipos/modelos'}>
                <StyledTooltip
                  content={'Lista de Modelos'}
                  showArrow={false}
                >
                  <MaterialIcon
                    name={'arrow_back_ios'}
                    size="compact"
                  />
                </StyledTooltip>
              </StyledRouterLink>
              <LabelMedium margin={'0px 0px 4px'}>{deviceModel.name}</LabelMedium>
            </FlexRow>
            <StyledButton
              onClick={openFormModal}
              startEnhancer={
                <MaterialIcon
                  name="add"
                  size="xs-mini"
                  color={theme.colors.contentInversePrimary}
                />
              }
            >
              Nuevo
            </StyledButton>
          </FlexRow>
          {!!deviceModel.commands?.length && (
            <Table
              columns={DEVICE_MODEL_COMMANDS_TABLE_COLUMNS_NAME.map((col) => col.label)}
              data={deviceModel?.commands.map((command, i) => {
                return [
                  <EllipsedText key={`command-name-${i}`}>{command.commandName}</EllipsedText>,
                  <EllipsedText key={`command-description-${i}`}>{command.commandDescription}</EllipsedText>,
                  <EllipsedText key={`command-${i}`}>{command.command}</EllipsedText>,
                  <FlexRow>
                    <StyledTooltip
                      content={'Ver detalle'}
                      showArrow={false}
                    >
                      <Button
                        size="compact"
                        shape="square"
                        onClick={() => openDetailModal(i)}
                        kind="tertiary"
                      >
                        <MaterialIcon name={'visibility'} />
                      </Button>
                    </StyledTooltip>
                    <StyledTooltip
                      content={'Eliminar'}
                      showArrow={false}
                    >
                      <Button
                        size="compact"
                        shape="square"
                        onClick={() => openConfirmDeleteModal(i)}
                        kind="tertiary"
                      >
                        <MaterialIcon name={'delete'} />
                      </Button>
                    </StyledTooltip>
                  </FlexRow>,
                ];
              })}
            />
          )}
          {!deviceModel.commands.length && (
            <EmptyState
              title="No se encontraron resultados"
              description="Intenta con otra busqueda"
            />
          )}
          {showFormModal && (
            <DeviceModelCommandsModal
              onClose={(updateTable) => closeDetailModal(updateTable)}
              isOpen={showFormModal}
              deviceModel={deviceModel}
              selectedCommand={selectedCommandIndex}
            />
          )}
          <ConfirmModal
            isOpen={showConfirmModal}
            onCancel={closeConfirmModal}
            onConfirm={onDelete}
            confirmText="Eliminar"
            description={<ParagraphMedium>¿Desea eliminar el comando?</ParagraphMedium>}
          />
        </>
      )}
    </FlexColumn>
  );
}
