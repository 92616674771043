import { HeaderNavigation } from 'baseui/header-navigation';
import { useStyles } from '../../../hooks/useStyles';
import { UserMenu } from './UserMenu';
import { PageTitle } from './PageTitle';

export function Navbar() {
  const { theme } = useStyles();

  return (
    <HeaderNavigation
      overrides={{
        Root: {
          style: {
            paddingTop: theme.sizing.scale500,
            paddingRight: theme.sizing.scale500,
            paddingBottom: theme.sizing.scale500,
            paddingLeft: theme.sizing.scale500,
            columnGap: theme.sizing.scale500,
            alignItems: 'center',
            minHeight: '60px',
            boxSizing: 'border-box',
          },
        },
      }}
    >
      <PageTitle />
      <UserMenu />
    </HeaderNavigation>
  );
}
