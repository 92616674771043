import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import { useStyles } from '../../../hooks/useStyles';
import { InstallationLog } from '../../../types/logs';
import InstallationLogItem from './InstallationLogItem';

type Props = {
  logs: InstallationLog[];
};
export function InstallationLogsList({ logs }: Readonly<Props>) {
  const { css } = useStyles();

  return (
    <ul
      className={css({
        margin: 0,
        paddingLeft: 0,
        paddingRight: 0,
        height: '100%',
        flex: 1,
      })}
    >
      <AutoSizer>
        {({ width, height }: { width: number; height: number }) => (
          <List
            height={height ?? 500}
            itemCount={logs.length ?? 0}
            itemSize={() => 70}
            width={width ?? 190}
            itemData={logs}
            overscanCount={4}
          >
            {InstallationLogItem}
          </List>
        )}
      </AutoSizer>
    </ul>
  );
}
