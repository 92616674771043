import { Item } from 'baseui/side-navigation';
import { Key } from 'react';
import { useRecoilValue } from 'recoil';
import {
  ADMIN_PAGE_IDS,
  VEHICLE_PAGE_IDS,
  EQUIPMENT_PAGE_IDS,
  ADMIN_PAGE_ID,
  EQUIPMENT_PAGE_ID,
  VEHICLE_PAGE_ID,
  EMISSIONS_PAGE_IDS,
  EMISSIONS_PAGE_ID,
  PLATFORM_PAGE_ID,
  PLATFORM_PAGE_IDS,
  REPORTS_PANELS_PAGE_IDS,
  REPORTS_PANELS_PAGE_ID,
  CHILDREN_EMISSIONS_PAGE_IDS,
  CHILDREN_ADMIN_PAGE_IDS,
  CHILDREN_PLATFORM_PAGE_IDS,
  CHILDREN_EQUIPMENT_PAGE_IDS,
  CHILDREN_VEHICLE_PAGE_IDS,
  CHILDREN_REPORTS_PANELS_PAGE_IDS,
  CAMPAINGS_PAGE_IDS,
  CHILDREN_CAMPAINGS_PAGE_IDS,
  CAMPAINGS_PAGE_ID,
  SAFE_GPT_PAGE_IDS,
  CHILDREN_SAFE_GPT_PAGE_IDS,
  SAFE_GPT_PAGE_ID,
} from '../constants/app';
import { pageState } from '../storage/app';

export function useSidenavItems(items: Item[]) {
  const currentPage = useRecoilValue(pageState);

  const adminItems = items.filter((item) => (item.itemId ? ADMIN_PAGE_IDS.includes(item.itemId) : false));
  const platformItems = items.filter((item) => (item.itemId ? PLATFORM_PAGE_IDS.includes(item.itemId) : false));
  const emissionsItems = items.filter((item) => (item.itemId ? EMISSIONS_PAGE_IDS.includes(item.itemId) : false));
  const equimentItems = items.filter((item) => (item.itemId ? EQUIPMENT_PAGE_IDS.includes(item.itemId) : false));
  const vehicleItems = items.filter((item) => (item.itemId ? VEHICLE_PAGE_IDS.includes(item.itemId) : false));
  const reportsItems = items.filter((item) => (item.itemId ? REPORTS_PANELS_PAGE_IDS.includes(item.itemId) : false));
  const campaignsItems = items.filter((item) => (item.itemId ? CAMPAINGS_PAGE_IDS.includes(item.itemId) : false));
  const safeGptItems = items.filter((item) => (item.itemId ? SAFE_GPT_PAGE_IDS.includes(item.itemId) : false));

  const pageSection: Key | undefined = (() => {
    if (ADMIN_PAGE_IDS.includes(currentPage) || CHILDREN_ADMIN_PAGE_IDS.includes(currentPage)) {
      return ADMIN_PAGE_ID;
    }
    if (PLATFORM_PAGE_IDS.includes(currentPage) || CHILDREN_PLATFORM_PAGE_IDS.includes(currentPage)) {
      return PLATFORM_PAGE_ID;
    }
    if (EMISSIONS_PAGE_IDS.includes(currentPage) || CHILDREN_EMISSIONS_PAGE_IDS.includes(currentPage)) {
      return EMISSIONS_PAGE_ID;
    }
    if (EQUIPMENT_PAGE_IDS.includes(currentPage) || CHILDREN_EQUIPMENT_PAGE_IDS.includes(currentPage)) {
      return EQUIPMENT_PAGE_ID;
    }
    if (VEHICLE_PAGE_IDS.includes(currentPage) || CHILDREN_VEHICLE_PAGE_IDS.includes(currentPage)) {
      return VEHICLE_PAGE_ID;
    }
    if (REPORTS_PANELS_PAGE_IDS.includes(currentPage) || CHILDREN_REPORTS_PANELS_PAGE_IDS.includes(currentPage)) {
      return REPORTS_PANELS_PAGE_ID;
    }
    if (CAMPAINGS_PAGE_IDS.includes(currentPage) || CHILDREN_CAMPAINGS_PAGE_IDS.includes(currentPage)) {
      return CAMPAINGS_PAGE_ID;
    }
    if (SAFE_GPT_PAGE_IDS.includes(currentPage) || CHILDREN_SAFE_GPT_PAGE_IDS.includes(currentPage)) {
      return SAFE_GPT_PAGE_ID;
    }
  })();

  const sections = (() => {
    return [
      {
        id: ADMIN_PAGE_ID,
        title: 'Admin',
        items: adminItems,
      },
      {
        id: SAFE_GPT_PAGE_ID,
        title: 'Safe GPT',
        items: safeGptItems,
      },
      {
        id: PLATFORM_PAGE_ID,
        title: 'Plataforma',
        items: platformItems,
      },
      {
        id: EMISSIONS_PAGE_ID,
        title: 'Instalaciones',
        items: emissionsItems,
      },
      {
        id: VEHICLE_PAGE_ID,
        title: 'Vehículos',
        items: vehicleItems,
      },
      {
        id: EQUIPMENT_PAGE_ID,
        title: 'Equipos',
        items: equimentItems,
      },
      {
        id: REPORTS_PANELS_PAGE_ID,
        title: 'Reportes',
        items: reportsItems,
      },
      {
        id: CAMPAINGS_PAGE_ID,
        title: 'Campañas',
        items: campaignsItems,
      },
    ];
  })();

  const coreItems = (() => {
    return [];
  })();

  return {
    pageSection,
    coreItems,
    sections,
  };
}
