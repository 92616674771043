import { PaginatedColumn } from '../types/app';

export const DEVICE_TABLE_COLUMNS_NAME: PaginatedColumn[] = [
  {
    label: 'Ident',
    key: 'ident',
  },
  {
    label: 'Model',
    key: 'model',
  },
  {
    label: 'Marca',
    key: 'brand',
  },
  {
    label: 'Cliente',
    key: 'client',
  },
  {
    label: 'Subcliente',
    key: 'subClient',
  },
  {
    label: 'Posición actual',
    key: 'cs',
  },
  {
    label: 'Posición sin asset',
    key: 'hsWithoutAsset',
  },
  {
    label: 'Acciones',
    key: 'actions',
    minWidth: 120,
    maxWidth: 120,
    centered: true,
  },
];
