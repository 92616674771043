import { EnhancedLabeledSelect, FlexRow } from '@gorila-shared-ui/components';
import { OnChangeParams } from 'baseui/select';
import { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { useStyles } from '../../hooks/useStyles';
import { assetTypeState, assetsTypesState } from '../../storage/assets';
import { ClientSelect } from '../shared/selects/ClientSelect';
import { SubClientSelect } from '../shared/selects/SubClientSelect';

type Props = {
  selectedClientId?: string;
  selectedSubClientId?: string;
  setSelectedClientId: (clientId?: string) => void;
  setSelectedSubClientId: (subClientId?: string) => void;
};
export function AssetFilters({
  selectedClientId,
  selectedSubClientId,
  setSelectedSubClientId,
  setSelectedClientId,
}: Props) {
  const { css, theme, classes } = useStyles();
  const assetTypes = useRecoilValue(assetsTypesState);
  const [selectedAssetType, setSelectedAssetType] = useRecoilState(assetTypeState);
  const { isSmall } = useBreakpoints();

  const assetTypeOptions = useMemo(() => {
    if (!assetTypes) return;
    return [
      {
        id: undefined,
        label: 'Todos',
      },
      ...assetTypes.map((assetType) => ({ id: assetType.key, label: assetType.label })),
    ];
  }, [assetTypes]);

  const onAssetTypeChange = (params: OnChangeParams) => {
    params.option?.id
      ? setSelectedAssetType(params.option.id as 'vehicle' | 'container' | 'phone')
      : setSelectedAssetType(undefined);
  };

  const handleClientChange = (clientId?: string) => {
    if (clientId !== selectedClientId) {
      setSelectedClientId(clientId);
      setSelectedSubClientId(undefined);
    }
  };

  return (
    <FlexRow
      gap={theme.sizing.scale300}
      classNames={`${classes.fillParent} ${css({
        flexDirection: isSmall ? 'column' : 'row',
      })}`}
    >
      <EnhancedLabeledSelect
        label="Tipo de activo:"
        options={assetTypeOptions}
        value={[{ id: selectedAssetType }]}
        onChange={onAssetTypeChange}
        inline
        inset
        fullWidth
      />
      <ClientSelect
        selectedClientId={selectedClientId}
        onChangeClientId={handleClientChange}
        inline
      />
      <SubClientSelect
        selectedClientId={selectedClientId}
        selectedSubClientId={selectedSubClientId}
        onChangeSubClientId={setSelectedSubClientId}
        disabled={!selectedClientId}
        inline
      />
    </FlexRow>
  );
}
