import { JobTypeCategory } from '../types/job';

export const JOB_TYPE_CATEGORY_INSTALLATION = 'Instalación';
export const JOB_TYPE_CATEGORY_UNINSTALL = 'Desinstalación';
export const JOB_TYPE_CATEGORY_REVISION = 'Revisión';

export const JOB_TYPE_CATEGORY_OPTIONS = [
  {
    id: JobTypeCategory.installation,
    label: JOB_TYPE_CATEGORY_INSTALLATION,
  },
  {
    id: JobTypeCategory.uninstall,
    label: JOB_TYPE_CATEGORY_UNINSTALL,
  },
  {
    id: JobTypeCategory.revision,
    label: JOB_TYPE_CATEGORY_REVISION,
  },
];

export const JOB_TYPE_CATEGORY_LABELS = {
  [JobTypeCategory.installation]: JOB_TYPE_CATEGORY_INSTALLATION,
  [JobTypeCategory.uninstall]: JOB_TYPE_CATEGORY_UNINSTALL,
  [JobTypeCategory.revision]: JOB_TYPE_CATEGORY_REVISION,
};
