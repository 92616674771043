import { forwardRef } from 'react';
import { useStyles } from '../../hooks/useStyles';
import { FlexibleBox, GenericComponent } from '../../types/components';

export const FlexColumn = forwardRef<HTMLDivElement, GenericComponent & FlexibleBox>(
  ({ children, centered, gap, classNames, padding }, ref) => {
    const { css, classes, theme } = useStyles();
    if (gap === undefined) gap = theme.sizing.scale600;
    const styles = css({ gap, padding });
    return (
      <div
        className={`
      ${classes.verticalFlex} 
      ${centered ? classes.centeredFlex : ''} 
      ${styles} ${classNames}`}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);
