import { useWindowSize } from 'usehooks-ts';

export function useBreakpoints() {
  const windowSize = useWindowSize();
  const isXXSmall = windowSize.width <= 600;
  const isXSmall = windowSize.width <= 780;
  const isSmall = windowSize.width <= 960;
  const isMedium = windowSize.width <= 1280;
  const isLarge = windowSize.width <= 1920;

  return {
    isXXSmall,
    isXSmall,
    isSmall,
    isMedium,
    isLarge
  };
}
