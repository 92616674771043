import { FlexColumn, FlexRow } from '@gorila-shared-ui/components';
import { FormControl } from 'baseui/form-control';
import { MaskedInput } from 'baseui/input';
import { COUNTRIES, PhoneInput, StyledFlag } from 'baseui/phone-input';
import { useEffect, useMemo, useState } from 'react';
import { useStyles } from '../../hooks/useStyles';
import useUpdateEffect from '../../hooks/useUpdateEffect';

function CustomFlag(props: any) {
  const { children, ...rest } = props;
  return (
    <StyledFlag
      iso={props.$iso}
      {...rest}
    />
  );
}

type Props = {
  value?: string;
  onChange: (phone: string) => void;
  type?: 'full' | 'simple';
  required?: boolean;
  disabled?: boolean;
  isPhoneValid?: (valid: boolean) => void;
  caption?: React.ReactNode;
  endEnhancer?: React.ReactNode;
  label?: React.ReactNode;
  placeholder?: string;
  readonly?: boolean;
  startEnhancer?: React.ReactNode;
  size?: 'compact' | 'mini' | 'default' | 'large';
  maxLength?: number;
  mask?: string;
};
export function LabeledInternationalPhoneInput({
  label = 'Telefono',
  value,
  onChange,
  type = 'full',
  required = false,
  disabled = false,
  caption,
  endEnhancer,
  placeholder,
  readonly,
  startEnhancer,
  size = 'compact',
  maxLength,
  isPhoneValid,
  mask,
}: Readonly<Props>) {
  const { css, theme } = useStyles();
  const [isValid, setIsValid] = useState(false);
  const [isVisited, setIsVisited] = useState(false);
  const shouldShowError = !isValid && (required ? isVisited : true);
  const [country, setCountry] = useState(COUNTRIES.MX);

  const validatePhone = (value?: string): boolean => {
    if (required && !value) return false;
    if (value) {
      const phone = value.replace(country.dialCode, '').replace(/\D/g, '').trim();
      if (phone.length < 10) return false;
    }
    return true;
  };

  useEffect(() => {
    setIsValid(validatePhone(value));
  }, [value]);

  useUpdateEffect(() => {
    if (isPhoneValid) {
      isPhoneValid(isValid);
    }
  }, [isValid]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    if (!value) {
      onChange('');
      return;
    }
    if (type === 'full') onChange(value.trim());
    else if (type === 'simple') onChange(`+${value.replace(/\D/g, '').trim()}`);
  };

  const errorMsg = useMemo(() => {
    if (shouldShowError) {
      if (required && !value) return 'Este campo es requerido';
      return 'Ingrese un número valido';
    }
  }, [isValid, isVisited]);

  const phoneMask = useMemo(() => {
    if (mask && mask.replace(/\D/g, '').length === 10) return `${country.dialCode} ${mask}`;
    return `${country.dialCode} 999-999-9999`;
  }, [mask, country.dialCode]);

  return (
    <FlexColumn
      gap={theme.sizing.scale200}
      classNames={css({ width: '100%' })}
    >
      <FormControl
        label={label && `${label} ${required ? '*' : ''}`}
        caption={caption}
        error={errorMsg}
        disabled={disabled}
        overrides={{
          LabelContainer: {
            style: {
              marginTop: 0,
              marginBottom: 0,
            },
          },
          ControlContainer: {
            style: { marginBottom: 0 },
          },
          Caption: {
            style: {
              marginTop: theme.sizing.scale200,
              marginBottom: 0,
            },
          },
        }}
      >
        <FlexRow gap={0}>
          <PhoneInput
            country={country}
            onCountryChange={({ option }) => {
              setCountry(option as any);
            }}
            overrides={{
              Input: () => {
                return null;
              },
              FlagContainer: {
                component: CustomFlag,
              },
            }}
            size={size}
          />
          <MaskedInput
            value={value}
            placeholder={placeholder}
            readOnly={readonly}
            onChange={onInputChange}
            mask={phoneMask}
            size={size}
            error={shouldShowError}
            maxLength={maxLength}
            endEnhancer={endEnhancer}
            startEnhancer={startEnhancer}
            onBlur={() => setIsVisited(true)}
            required={required}
          />
        </FlexRow>
      </FormControl>
    </FlexColumn>
  );
}
