import { EnhancedLabeledSelect } from '@gorila-shared-ui/components';
import { OnChangeParams } from 'baseui/select';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDebounceFunction } from '../../../hooks/useDebounce';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import { useLoading } from '../../../hooks/useLoading';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { getDevicesList } from '../../../services/deviceService';
import { DeviceCore } from '../../../types/device';

type Props = {
  device?: DeviceCore;
  devices?: DeviceCore[];
  selectedDeviceId?: string;
  selectedDevicesIds?: string[];
  onChangeDevice?: (deviceId?: string) => void;
  onChangeDevices?: (devicesIds?: string[]) => void;
  isMulti?: boolean;
  isFilter?: boolean;
  inline?: boolean;
  label?: string;
  required?: boolean;
};
export function DeviceSelect({
  selectedDeviceId,
  selectedDevicesIds,
  onChangeDevice,
  onChangeDevices,
  device,
  devices,
  isMulti = false,
  isFilter = true,
  label = 'Dispositivo:',
  inline = false,
  required = false,
}: Readonly<Props>) {
  const [devicesList, setDevicesList] = useState<DeviceCore[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const { search, setSearch, debouncedSearch } = useDebouncedSearch('');
  const [selectedDeviceOption, setSelectedDeviceOption] = useState<{
    id: string;
    label: string;
  }>();
  const [selectedDevicesOptions, setSelectedDevicesOptions] = useState<
    {
      id: string;
      label: string;
    }[]
  >();
  const requestIdRef = useRef(0);

  useUpdateEffect(() => {
    setPage(0);
  }, [debouncedSearch]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadDevices = async () => {
      const requestId = ++requestIdRef.current;
      const { devices, error, hasNext } = await getDevicesList(page, search);
      if (requestId === requestIdRef.current) {
        if (!error && devices) {
          if (page > 1 && devicesList) {
            setDevicesList([...devicesList, ...devices]);
            setHasNextPage(hasNext);
          } else {
            setDevicesList(devices);
            setHasNextPage(hasNext);
          }
        } else {
          setDevicesList(undefined);
          setHasNextPage(false);
        }
        stopLoading();
      }
    };
    loadDevices();
  }, [page]);

  const deviceOptions = useMemo(() => {
    if (!devicesList) return;
    const devicesOptions: { id: string | undefined; label: string }[] = [
      ...devicesList.map((device) => ({ id: device._id, label: device.ident })),
    ];
    if (!required) {
      devicesOptions?.unshift({
        id: undefined,
        label: isFilter ? 'Todos' : 'Ninguno',
      });
    }
    if (device) {
      if (!devicesOptions.find((d) => d.id === device._id))
        devicesOptions.push({
          id: device._id,
          label: device.ident,
        });
    } else if (devices) {
      devices.forEach((device) => {
        if (!devicesOptions.find((d) => d.id === device._id))
          devicesOptions.push({
            id: device._id,
            label: device.ident,
          });
      });
    }
    if (selectedDeviceOption) {
      if (!devicesOptions.find((c) => c.id === selectedDeviceOption.id) && selectedDeviceOption.id)
        devicesOptions.push(selectedDeviceOption);
    } else if (selectedDevicesOptions) {
      selectedDevicesOptions.forEach((device) => {
        if (!devicesOptions.find((d) => d.id === device.id) && device.id) devicesOptions.push(device);
      });
    }
    return devicesOptions;
  }, [devicesList]);

  const onDeviceChange = (params: OnChangeParams) => {
    if (onChangeDevice) params.option?.id ? onChangeDevice(params.option.id as string) : onChangeDevice(undefined);
    setSelectedDeviceOption(params.option as { id: string; label: string });
  };

  const onDevicesChange = (params: OnChangeParams) => {
    if (onChangeDevices) {
      if (params.value.length) {
        onChangeDevices(params.value.map((val) => val.id).filter((id) => id !== undefined) as string[]);
        setSelectedDevicesOptions(
          params.value.map((val) => val).filter((val) => val.id !== undefined) as { id: string; label: string }[]
        );
      } else {
        onChangeDevices(undefined);
        setSelectedDeviceOption(undefined);
      }
    }
  };

  const fetchData = () => {
    if (hasNextPage) setPage(page + 1);
  };

  const handleInputChange = useDebounceFunction(function (term: string) {
    setSearch(term);
  }, 500);

  return (
    <EnhancedLabeledSelect
      label={label}
      options={deviceOptions}
      value={
        isMulti
          ? selectedDevicesIds?.map((deviceId) => {
              return { id: deviceId };
            })
          : [{ id: selectedDeviceId }]
      }
      onChange={isMulti ? onDevicesChange : onDeviceChange}
      onInputChange={(e) => {
        handleInputChange(e.target.value);
      }}
      fullWidth
      infiniteScroll
      fetchData={fetchData}
      fetchDataLoading={loading}
      hasNext={hasNextPage}
      onBlur={() => setSearch(undefined)}
      searchable
      isMulti={isMulti}
      inline={inline}
      inset={inline}
      required={required}
    />
  );
}
