import { ViewState } from 'react-map-gl';
import { atom, selector } from 'recoil';
import { MAP_CONFIGURATION, ROAD_MAP_TYPE } from '../constants/map';
import { MapType } from '../types/app';
import { localStorageEffect, persistedAtom } from '../utils/persistence';

export const mapTypeState = persistedAtom<MapType>('app.map.type', ROAD_MAP_TYPE);
export const mapStyleState = selector({
  key: 'app.map.styles',
  get: ({ get }) => MAP_CONFIGURATION[get(mapTypeState)].style,
});
export const mapPaddingState = atom({
  key: 'app.map.padding',
  default: 0,
});

export const mapViewState = atom<ViewState>({
  key: 'map.view',
  default: {
    longitude: -100.38806,
    latitude: 20.58806,
    zoom: 14,
  } as ViewState,
  effects: [localStorageEffect],
});

export const showTrafficLayerState = persistedAtom('app.map.layer.traffic.show', false);
