import { MaterialIcon } from '@gorila-shared-ui/components';
import { Input } from 'baseui/input';
import { useEffect, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useRecoilState, useRecoilValue } from 'recoil';
import { INSTALLATIONS_DETAIL_PAGE } from '../../constants/app';
import { environment } from '../../environments/environment';
import { useCamera } from '../../hooks/useCamera';
import { useStyles } from '../../hooks/useStyles';
import { editingInstallationState } from '../../storage/Installations';
import { pageState } from '../../storage/app';
import { Point } from '../../types/geoModels';

export function MapSearchBox() {
  const { theme, css } = useStyles();
  const { zoomTo } = useCamera();
  const page = useRecoilValue(pageState);
  const [editingInstallation, setEditingInstallation] = useRecoilState(editingInstallationState);
  const [selectedPlace, setSelectedPlace] = useState<{
    point: Point;
    address: string;
  }>();
  const { ref } = usePlacesWidget({
    apiKey: environment.googleMapsAPIKey,
    inputAutocompleteValue: '',
    options: {
      types: [],
      fields: ['formatted_address', 'geometry'],
    },
    onPlaceSelected: (place) => {
      const point = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
      const address = place.formatted_address;
      setSelectedPlace({ point, address });
    },
  });

  useEffect(() => {
    if (!selectedPlace) return;
    if (page === INSTALLATIONS_DETAIL_PAGE.id && editingInstallation) {
      setEditingInstallation((prev) => ({
        ...prev!,
        georeference: selectedPlace.address ?? '',
        latitude: selectedPlace.point.lat,
        longitude: selectedPlace.point.lng,
      }));
    } else {
      zoomTo(selectedPlace.point);
    }
  }, [selectedPlace]);

  return (
    <span
      className={css({
        position: 'absolute',
        top: theme.sizing.scale500,
        right: theme.sizing.scale500,
        backgroundColor: theme.colors.backgroundPrimary,
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
        borderRadius: '8px',
        minWidth: '250px',
      })}
    >
      <Input
        startEnhancer={
          <MaterialIcon
            name="place"
            size="mini"
          />
        }
        name={'map-search'}
        placeholder={'Buscar lugar'}
        size={'compact'}
        autoComplete="off"
        overrides={{
          StartEnhancer: {
            style: {
              paddingRight: theme.sizing.scale200,
              paddingLeft: theme.sizing.scale200,
              backgroundColor: 'transparent',
              position: 'absolute',
              top: '50%',
              left: 0,
              transform: 'translateY(-50%)',
            },
          },
          InputContainer: {
            style: {
              backgroundColor: theme.colors.backgroundPrimary,
            },
          },
          Input: {
            style: {
              backgroundColor: theme.colors.backgroundPrimary,
              paddingLeft: theme.sizing.scale850,
            },
            props: {
              ref,
            },
          },
          Root: {
            style: {
              paddingLeft: 0,
            },
          },
        }}
      />
    </span>
  );
}
