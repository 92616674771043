import { ContentContainer, Loading } from '@gorila-shared-ui/components';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { REPORTS_PANELS_TYPES } from '../../../constants/reports';
import { useFeedback } from '../../../hooks/useFeedback';
import { useLoading } from '../../../hooks/useLoading';
import { EmbeddedIframe } from '../../shared/EmbeddedIframe';

export function ReportsPanelsPage() {
  const { reportType } = useParams();
  const [reportUrl, setReportUrl] = useState<string>();
  const { loading, startLoading, stopLoading } = useLoading();
  const { showFailFeedback } = useFeedback();

  useEffect(() => {
    if (!reportType) return;
    startLoading();
    try {
      fetch(REPORTS_PANELS_TYPES[reportType])
        .then((response) => response.json())
        .then((response) => {
          if (response.EmbedUrl) setReportUrl(response.EmbedUrl);
          else showFailFeedback(response);
        })
        .catch((error) => {
          console.log(error);
          showFailFeedback('Ups... Algo salio mal!');
        })
        .finally(() => {
          stopLoading();
        });
    } catch (error) {
      showFailFeedback('Ups... Algo salio mal!');
      stopLoading();
    }
  }, [reportType]);

  if (!reportType) return;

  return (
    <ContentContainer>
      {loading && <Loading />}
      {!loading && reportUrl && <EmbeddedIframe url={reportUrl} />}
    </ContentContainer>
  );
}
