import { Link } from 'react-router-dom';
import { useStyles } from '../../hooks/useStyles';

type Props = {
  color?: string;
};

export function StyledRouterLink({ color, children, className, ...props }: React.ComponentProps<typeof Link> & Props) {
  const { css, theme } = useStyles();
  const linkStyles = css({
    color: color || theme.colors.contentPrimary,
    textDecoration: 'none',
  });

  return (
    <Link
      {...props}
      className={`${linkStyles} ${className || ''}`}
    >
      {children}
    </Link>
  );
}
