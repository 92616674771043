import { ContentContainer } from '@gorila-shared-ui/components';
import { DeviceModelCommandsList } from '../../deviceModelsCommands/DeviceModelCommandsList';

export function DeviceModelCommandsPage() {
  return (
    <ContentContainer>
      <DeviceModelCommandsList />
    </ContentContainer>
  );
}
