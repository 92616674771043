import { atom, selector } from "recoil";
import { SAFEGPT_DEFAULTS, SAFEGPT_TOUCHED_DEFAULTS } from "../constants/safeGpt";
import { AlarmTypes, SafeGptConfigRequest, SafeGptConfigRequestTouched } from "../types/safeGpt";


export const alarmTypesState = atom<AlarmTypes[] | undefined>({
  key: 'app.safeGpt.alarmTypes',
  default: undefined,
});

export const safeGptConfigSearchState = atom<string | undefined>({
  key: 'app.safeGptConfig.search',
  default: undefined,
});

export const editSafeGptConfigState = atom<SafeGptConfigRequest>({
  key: 'safeGptConfig.edit',
  default: SAFEGPT_DEFAULTS,
});

export const editSafeGptConfigTouchedState = atom<SafeGptConfigRequestTouched>({
  key: 'safeGptConfig.edit.touched',
  default: SAFEGPT_TOUCHED_DEFAULTS,
});

export const editSafeGptConfigValidState = selector<{ [key in keyof SafeGptConfigRequest]: boolean }>({
  key: 'safeGptConfig.edit.valid',
  get: ({ get }) => {
    const fields = get(editSafeGptConfigState);

    // SET ALL FIELDS TO BE VALID
    const validFields = Object.fromEntries(Object.keys(fields).map((key) => [key, true])) as {
      [key in keyof SafeGptConfigRequest]: boolean;
    };

    // VALIDATE REQUIRED FIELDS
    if (!fields.name) {
      validFields.name = false;
    }
    if (typeof fields.timeWindow !== 'number') {
      validFields.timeWindow = false;
    }
    if (typeof fields.totalEvents !== 'number' || fields.totalEvents === 0) {
      validFields.totalEvents = false;
    }
    if (typeof fields.totalDistinct !== 'number' || fields.totalDistinct === 0) {
      validFields.totalDistinct = false;
    }
    if (!fields.alarmTypes || fields.alarmTypes.length === 0) {
      validFields.alarmTypes = false;
    }
    if (typeof fields.continuousDriving !== 'number' || fields.continuousDriving === 0) {
      validFields.continuousDriving = false;
    }
    if (typeof fields.cooldown !== 'number') {
      validFields.cooldown = false;
    }

    return validFields;
  },
});

export const editSafeGptConfigErrorState = selector({
  key: 'safeGptConfig.edit.error',
  get: ({ get }) => {
    const validFields = get(editSafeGptConfigValidState);
    return Object.values(validFields).some((valid) => !valid);
  },
});