import { FlexRow, MaterialIcon, StyledButton, StyledSearchBar, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { memo } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { useDebouncedSearch } from '../../hooks/useDebouncedSearch';
import useEffectOnce from '../../hooks/useEffectOnce';
import { useModal } from '../../hooks/useModal';
import { useStyles } from '../../hooks/useStyles';
import { phoneBlReloadListState, phoneBlSearchState } from '../../storage/PhoneBlacklist';
import AddedPhoneToBlModal from './modal/AddedPhoneToBlModal';

function PhoneTableFilters() {
  const { css, theme } = useStyles();
  const { search, setSearch } = useDebouncedSearch(useRecoilState(phoneBlSearchState));
  const resetSearch = useResetRecoilState(phoneBlSearchState);
  const setReloadPage = useSetRecoilState(phoneBlReloadListState);
  const { isLarge } = useBreakpoints();
  const { showModal, openModal, closeModal } = useModal();

  useEffectOnce(() => {
    return () => {
      resetSearch();
    };
  });

  return (
    <FlexRow classNames={css({ alignItems: 'center' })}>
      <div
        className={css({
          flex: '1',
          display: 'flex',
          flexDirection: !isLarge ? 'row' : 'column',
          gap: '16px',
        })}
      >
        <FlexRow classNames={css({ flex: '1' })}>
          <span className={css({ flexGrow: '0.5' })}>
            <StyledSearchBar
              name={'convoy-search'}
              value={search}
              onChange={setSearch}
              clearable
            />
          </span>
        </FlexRow>
      </div>
      <StyledTooltip
        content={'Actualizar lista'}
        showArrow={false}
      >
        <Button
          size="mini"
          shape="round"
          onClick={() => setReloadPage((prev) => !prev)}
          kind="tertiary"
        >
          <MaterialIcon
            name={'refresh'}
            size="mini"
          />
        </Button>
      </StyledTooltip>
      <StyledButton
        onClick={openModal}
        startEnhancer={
          <MaterialIcon
            name="add"
            size="xs-mini"
            color={theme.colors.contentInversePrimary}
          />
        }
      >
        Agregar
      </StyledButton>
      <AddedPhoneToBlModal
        isOpen={showModal}
        onClose={(reloadPage) => {
          if (reloadPage) setReloadPage((prev) => !prev);
          closeModal();
        }}
      />
    </FlexRow>
  );
}

export default memo(PhoneTableFilters);
