import { useState } from 'react';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import { useRecoilState } from 'recoil';
import { SafeGptConfigFiltersBar } from '../../safeGpt/SafeGptConfigFiltersBar';
import { safeGptConfigSearchState } from '../../../storage/safeGpt';
import SafeGptProvider from '../../providers/SafeGptProvider';
import { SafeGptConfigList } from '../../safeGpt/SafeGptConfigList';
import { ContentContainer } from '@gorila-shared-ui/components';

export function SafeGptConfigListPage() {
  const { search, setSearch } = useDebouncedSearch(useRecoilState(safeGptConfigSearchState));
  const [reloadPage, setReloadPage] = useState(false);

  return (
    <ContentContainer>
      <SafeGptProvider/>
      <SafeGptConfigFiltersBar
        search={search}
        onChangeSearch={setSearch}
        onReloadPage={() => setReloadPage(!reloadPage)}
      />
      <SafeGptConfigList
        search={search}
        reloadPage={reloadPage}
      />
    </ContentContainer>
  );
}
