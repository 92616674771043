const forbiddenPasswords = ['Password', 'password', 'Contraseña', 'contraseña'];

export const validatePassword = (
  password: string,
  changePassword?: { oldPassword?: string; repeatPassword?: string }
) => {
  const passwordValidation: {
    [key: string]: string[];
  } = {};

  const errors: string[] = [];
  const repeatPasswordErrors: string[] = [];

  if (!password) {
    return;
  }

  if (password.length < 8) {
    errors.push('La contraseña debe de ser de al menos 8 caracteres');
  }

  if (forbiddenPasswords.indexOf(password) !== -1) {
    errors.push('Contraseña poco segura');
  }

  if (password === password.toLowerCase()) {
    errors.push('La contraseña debe contener al menos una mayúscula');
  }

  if (password === password.toUpperCase()) {
    errors.push('La contraseña debe contener al menos una minúscula');
  }

  if (!/\d/.test(password)) {
    errors.push('La contraseña debe incluir al menos un numéro');
  }

  if (!/\W/.test(password)) {
    errors.push('La contraseña debe incluir al menos un caracter especial "@, $, !, %, *, ?, &, -, _"');
  }

  if (/\s/.test(password)) {
    errors.push('La contraseña no debe contener espacios');
  }

  if (changePassword) {
    if (changePassword.repeatPassword && changePassword.repeatPassword !== password) {
      repeatPasswordErrors.push('Las contraseñas deben coincidir');
    }
    if (changePassword.oldPassword && password === changePassword.oldPassword) {
      errors.push('la contraseñas no debe ser igual a la actual');
    }
  }

  if (errors.length > 0) {
    passwordValidation['errors'] = errors;
  }
  if (repeatPasswordErrors.length > 0) {
    passwordValidation['repeatPasswordErrors'] = repeatPasswordErrors;
  }
  if (passwordValidation['repeatPasswordErrors'] || passwordValidation['errors']) {
    return passwordValidation;
  }
};

// Fixme!!
// export const userPasswordErrors = (user: User, errors: string) => {
//   if (!user.password) return;
//   const hasErrors = validatePassword(user.password, { repeatPassword: user.confirmPassword });
//   if (!hasErrors) return;
//   return hasErrors[errors];
// };
