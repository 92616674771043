import { TreeNodeData } from 'baseui/tree-view';
import { useRecoilValue } from 'recoil';
import { userPermissionsDisabledState, userPermissionsEnabledState } from '../../../../storage/permissions';
import { AppPermission } from '../../../../types/appPermission';
import { Permissions } from '../../../../types/permissions';
import { PermissionTreeItem } from '../PermissionTreeItem';

export function usePermissionsTree(useFilteredPermission = false) {
  const enabledPermissionsTemplate = useRecoilValue(userPermissionsEnabledState(useFilteredPermission));
  const disabledPermissionsTemplate = useRecoilValue(userPermissionsDisabledState(useFilteredPermission));

  const isEnabled = (permission: string, userPermissions: Permissions) => {
    if (userPermissions[permission]) return userPermissions[permission].enabled;
    else if (permission === 'permissions') {
      if (!Object.keys(userPermissions).length) return false;
      if (
        !Object.keys(userPermissions)
          .map((p) => userPermissions[p])
          .find((p) => !p.enabled)
      )
        return true;
    } else if (userPermissions['commands']?.availableCommands?.includes(permission)) return true;
    else {
      if (userPermissions['settings']?.availableSections) {
        if (userPermissions['settings'].availableSections[permission])
          return userPermissions['settings'].availableSections[permission].enabled;
      }
      if (userPermissions['dashboard']?.availableActions) {
        if (userPermissions['dashboard'].availableActions[permission])
          return userPermissions['dashboard'].availableActions[permission].enabled;
      }
    }
    return false;
  };

  const isIndeterminate = (permission: AppPermission, userPermissions: Permissions): boolean => {
    if (permission.name === 'settings' && userPermissions['settings']?.availableSections) {
      const availableSections = Object.keys(permission.children || {}).map(
        (s) => userPermissions['settings'].availableSections && userPermissions['settings'].availableSections[s]
      );
      if (availableSections.find((p) => !p?.enabled) && availableSections.find((p) => p?.enabled)) return true;
    } else if (permission.name === 'dashboard' && userPermissions['dashboard']?.availableActions) {
      const availableActions = Object.keys(permission.children || {}).map(
        (s) => userPermissions['dashboard'].availableActions && userPermissions['dashboard'].availableActions[s]
      );
      if (availableActions.find((p) => !p?.enabled) && availableActions.find((p) => p?.enabled)) return true;
    } else if (permission.name === 'commands' && userPermissions['commands']?.availableCommands) {
      if (permission.children) {
        return (
          !!userPermissions[permission.name].availableCommands?.length &&
          Object.keys(permission.children).length !== userPermissions[permission.name].availableCommands?.length
        );
      }
    } else if (permission.name === 'permissions') {
      if (
        Object.values(userPermissions).find((p) => !p.enabled) &&
        Object.values(userPermissions).find((p) => p.enabled)
      ) {
        return true;
      } else {
        if (permission.children)
          return (
            isIndeterminate(permission.children['settings'], userPermissions) ||
            isIndeterminate(permission.children['dashboard'], userPermissions) ||
            isIndeterminate(permission.children['commands'], userPermissions)
          );
      }
    }
    return false;
  };

  const getPermissionTreeData = ({
    permission,
    userPermissions,
    disabled = true,
    onChangePermissions,
    parent,
  }: {
    permission: AppPermission;
    userPermissions: Permissions;
    disabled?: boolean;
    onChangePermissions?: (newPermissions: Permissions) => void;
    parent?: AppPermission;
  }): TreeNodeData => {
    return {
      label: () => (
        <PermissionTreeItem
          label={permission.label}
          value={isEnabled(permission.name, userPermissions)}
          setValue={(checked) => {
            if (!onChangePermissions) return;
            let newPermissions: Permissions = { ...userPermissions };
            if (permission.name === 'permissions') {
              // ENABLE/DISABLE ALL
              newPermissions = checked ? { ...enabledPermissionsTemplate } : { ...disabledPermissionsTemplate };
            } else if (
              permission.name === 'commands' ||
              permission.name === 'settings' ||
              permission.name === 'dashboard'
            ) {
              // ENABLE/DISABLE ALL IN SECTION
              newPermissions[permission.name] = checked
                ? { ...enabledPermissionsTemplate[permission.name] }
                : { ...disabledPermissionsTemplate[permission.name] };
            } else if (parent?.name === 'commands') {
              // ADD OR REMOVE AVAILABLE COMMAND
              let availableCommands = [...(newPermissions[parent.name].availableCommands || [])];
              if (checked) {
                availableCommands.push(permission.name);
              } else {
                availableCommands = availableCommands.filter((command) => command !== permission.name);
              }
              newPermissions[parent.name] = {
                enabled: !!availableCommands.length,
                availableCommands,
              };
            } else if (parent?.name === 'settings') {
              // ADD OR REMOVE AVAILABLE SECTION
              let availableSections = { ...(newPermissions[parent.name].availableSections || {}) };
              availableSections[permission.name] = {
                enabled: checked,
              };
              newPermissions[parent.name] = {
                enabled: Object.values(availableSections).some(({ enabled }) => !!enabled),
                availableSections,
              };
            } else if (parent?.name === 'dashboard') {
              // ADD OR REMOVE AVAILABLE ACTIONS
              let availableActions = { ...(newPermissions[parent.name].availableActions || {}) };
              availableActions[permission.name] = {
                enabled: checked,
              };
              newPermissions[parent.name] = {
                enabled: Object.values(availableActions).some(({ enabled }) => !!enabled),
                availableActions,
              };
            } else {
              // ENABLE/DISABLE PERMISSION
              newPermissions[permission.name] = {
                enabled: checked,
              };
            }
            onChangePermissions(newPermissions);
          }}
          isIndeterminate={isIndeterminate(permission, userPermissions)}
          disabled={disabled}
        />
      ),
      id: permission.name,
      children: Object.values(permission.children || {}).map((childPermission) =>
        getPermissionTreeData({
          permission: childPermission,
          userPermissions,
          disabled,
          onChangePermissions,
          parent: permission,
        })
      ),
      isExpanded: false,
    };
  };

  return {
    getPermissionTreeData,
  };
}
