import { ApiError } from '../types/apiResponseError';
import { MinimalWebService } from '../types/webService';
import { WebService } from './../types/webService';
import { getRequest, postRequest } from './api';
import { URLS } from './urls/urls';

export const getWebServices = async ({
  page,
  q,
  subClientId,
}: {
  page?: number;
  q?: string;
  subClientId?: string;
}): Promise<{ webServices?: WebService[]; error?: ApiError; total: number; hasNext: boolean }> => {
  const params = {
    page: page ?? 1,
    q,
    subClientId,
  };
  const response = await getRequest(URLS.webService.all, {
    queryParams: params,
  });
  return {
    webServices: response?.data?.items,
    total: response?.data?.total ?? 0,
    hasNext: response?.data?.hasNext ?? false,
    error: response?.error,
  };
};

export const createWebService = async (webService: MinimalWebService): Promise<{ id?: string; error?: ApiError }> => {
  const body: { [key: string]: any } = {
    ...webService,
    _id: undefined,
    subClient: undefined,
    subClientId: webService.subClient?._id,
  };
  const response = await postRequest(URLS.webService.create, {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const updateWebService = async (webService: MinimalWebService): Promise<{ id?: string; error?: ApiError }> => {
  const body: { [key: string]: any } = {
    ...webService,
    client: undefined,
    subClient: undefined,
    subClientId: webService.subClient?._id,
  };
  const response = await postRequest(URLS.webService.update(webService._id), {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const getWebService = async (webServiceId: string): Promise<{ webService?: WebService; error?: ApiError }> => {
  const response = await getRequest(URLS.webService.detail(webServiceId));
  return {
    webService: response?.data,
    error: response?.error,
  };
};
