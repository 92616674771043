import { atom } from 'recoil';

export const subClientsSearchFilterState = atom<string | undefined>({
  key: 'app.subClients.search.filter',
  default: undefined,
});

export const subClientAssetsSearchFilterState = atom<string | undefined>({
  key: 'app.subClients.assets.search.filter',
  default: undefined,
});
