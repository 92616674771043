import { FlexColumn, FlexRow, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useStyles } from '../../../hooks/useStyles';
import { Command } from '../../../types/command';
import { DeviceModel } from '../../../types/deviceModel';
import { CommandForm } from '../CommandForm';
import { CommandInfo } from '../CommandInfo';

type Props = {
  isOpen: boolean;
  deviceModel: DeviceModel;
  selectedCommand?: number;
  onClose: (update?: boolean) => void;
};
function DeviceModelCommandDetailModal({ onClose, isOpen, selectedCommand, deviceModel }: Props) {
  const { css } = useStyles();
  const [command, setCommand] = useState<Command>();
  const [editingMode, setEditingMode] = useState(false);

  useEffect(() => {
    if (isOpen && selectedCommand !== undefined && selectedCommand !== null) setEditingMode(false);
  }, [isOpen]);

  useEffect(() => {
    if (selectedCommand === undefined || selectedCommand === null) {
      setCommand(undefined);
      setEditingMode(true);
      return;
    }
    const com = deviceModel.commands![selectedCommand!];
    if (!com) {
      setCommand(undefined);
      setEditingMode(true);
      return;
    } else {
      setCommand(com);
    }
  }, [selectedCommand]);

  return createPortal(
    <Modal
      animate
      closeable
      onClose={() => onClose()}
      isOpen={isOpen}
      size="auto"
    >
      <FlexRow
        gap={0}
        classNames={css({
          justifyContent: 'space-between',
          margin: '6px 2rem 0px 0px',
        })}
      >
        <ModalHeader
          className={css({
            marginBottom: '0 !important',
          })}
        >
          {command?.commandName ?? 'Nuevo'}
        </ModalHeader>
        {!editingMode && (
          <StyledTooltip
            content={'Editar'}
            showArrow={false}
          >
            <Button
              size="compact"
              shape="square"
              onClick={() => setEditingMode(true)}
              kind="tertiary"
            >
              <MaterialIcon
                name={'edit'}
                size="xs-mini"
              />
            </Button>
          </StyledTooltip>
        )}
      </FlexRow>
      <ModalBody>
        <FlexColumn
          classNames={css({
            width: '70vw',
            maxWidth: '1200px',
            minWidth: '350px',
            boxSizing: 'border-box',
            padding: '1rem',
            borderTop: '1px solid rgb(226, 226, 226)',
            maxHeight: '80vh',
          })}
        >
          {!editingMode && command && <CommandInfo command={command} />}
          {editingMode && (
            <CommandForm
              deviceModel={deviceModel}
              commandIndex={selectedCommand}
              onCancel={() => setEditingMode(false)}
              afterSave={() => onClose(true)}
            />
          )}
        </FlexColumn>
      </ModalBody>
    </Modal>,
    document.body
  );
}

export default memo(DeviceModelCommandDetailModal);
