import { PaginatedColumn } from "../types/app";
import { SafeGptConfigRequest, SafeGptConfigRequestTouched } from "../types/safeGpt";

export const SAFEGPT_TABLE_COLUMNS_NAME: PaginatedColumn[] = [
  {
    label: 'Nombre',
    key: 'name',
    minWidth: 200,
    flex: 1
  },
  {
    label: 'Ventana de Tiempo',
    key: 'timeWindows',
    centered: true,
    minWidth: 140,
    maxWidth: 160,
  },
  {
    label: '# Eventos',
    key: 'totalEvents',
    centered: true,
    minWidth: 90,
    maxWidth: 110,
  },
  {
    label: 'Mismo tipo',
    key: 'totalDistinct',
    centered: true,
    minWidth: 90,
    maxWidth: 110,
  },
  {
    label: 'Encendido',
    key: 'ignitionStatus',
    centered: true,
    minWidth: 100,
    maxWidth: 100,
  },
  {
    label: 'Manejo Continuo',
    key: 'continuousDriving',
    centered: true,
    minWidth: 140,
    maxWidth: 140,
  },
  {
    label: 'Activo',
    key: 'active',
    centered: true,
    minWidth: 100,
    maxWidth: 120,
  },
  {
    label: 'Acciones',
    key: 'actions',
    centered: true,
    minWidth: 120,
    maxWidth: 120,
  },
];

export const SAFEGPT_DEFAULTS: SafeGptConfigRequest = {
  name: '',
  active: true,
  alarmTypes: [],
  continuousDriving: 0,
  cooldown: 0,
  timeWindow: 0,
  totalDistinct: 0,
  totalEvents: 0,
  ignitionStatus: null,
  subClients: [],
  inclusiveEvaluation: false,
};

export const SAFEGPT_TOUCHED_DEFAULTS: SafeGptConfigRequestTouched = {
  nameTouched: false,
  timeWindowTouched: false,
  totalEventsTouched: false,
  totalDistinctTouched: false,
  alarmTypesTouched: false,
  continuousDrivingTouched: false,
  cooldownTouched: false,
};
