import { useSetRecoilState } from "recoil";
import { alarmTypesState } from "../../storage/safeGpt";
import { getAlarmTypeList } from "../../services/safeGptService";
import useEffectOnce from "../../hooks/useEffectOnce";


function SafeGptProvider() {
  const setAlarmTypes= useSetRecoilState(alarmTypesState);

  useEffectOnce(() => {
    const getAlarmTypes = async () => {
      const { alarmTypes } = await getAlarmTypeList();
      if (alarmTypes) setAlarmTypes(alarmTypes);
      else setAlarmTypes(undefined);
    };
    getAlarmTypes();
  });

  return null;
}

export default SafeGptProvider;
