import { FlexColumn, FlexRow, LabeledInput, StyledButton } from '@gorila-shared-ui/components';
import { useEffect, useMemo, useState } from 'react';
import { areEqual } from 'react-window';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../constants/app';
import { EMPTY_SUB_CLIENT } from '../../constants/subClients';
import { useFeedback } from '../../hooks/useFeedback';
import { useLoading } from '../../hooks/useLoading';
import { useStyles } from '../../hooks/useStyles';
import { createSubClient, updateSubClient } from '../../services/subClientService';
import { ClientCore } from '../../types/client';
import { MinimalSubClient, SubClient } from '../../types/subClient';
import { ContactForm } from '../ui/ContactForm';

type Props = {
  clientId?: string;
  subClient?: SubClient;
  onCancel: () => void;
  afterSave: () => void;
};
export function SubClientForm({ subClient, clientId, onCancel, afterSave }: Readonly<Props>) {
  const { css, theme } = useStyles();
  const [editingSubClient, setEditingSubClient] = useState<MinimalSubClient>(EMPTY_SUB_CLIENT);
  const { startLoading, stopLoading, loading } = useLoading(false);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();
  const [isValidContact, setIsValidContact] = useState(false);

  useEffect(() => {
    if (subClient) {
      setEditingSubClient(subClient);
    }
    if (clientId) {
      setEditingSubClient((prev) => ({ ...prev, client: { _id: clientId } as ClientCore }));
    }
  }, [subClient, clientId]);

  const hasUpdates = !areEqual(subClient ?? {}, editingSubClient);

  const canSubmit = useMemo(() => {
    if (!!editingSubClient.name && !!editingSubClient.tradeName && !!editingSubClient.rfc && isValidContact)
      return true;
    return false;
  }, [editingSubClient, isValidContact]);

  const onInputChange = (value: any, field: keyof MinimalSubClient) => {
    setEditingSubClient((prev) => ({ ...prev, [field]: value }));
  };

  const onSave = async () => {
    startLoading();
    const { id, error } = subClient ? await updateSubClient(editingSubClient) : await createSubClient(editingSubClient);
    if (!error && id) {
      afterSave();
      showPositiveFeedback(
        subClient
          ? FEEDBACK.edited(FEEDBACK_PREFIXES.subClient, editingSubClient.name)
          : FEEDBACK.created(FEEDBACK_PREFIXES.subClient)
      );
    } else {
      showFailFeedback(
        error || subClient
          ? FEEDBACK.failedEdition(FEEDBACK_PREFIXES.subClient, editingSubClient.name)
          : FEEDBACK.failedCreation(FEEDBACK_PREFIXES.subClient)
      );
    }
    stopLoading();
  };

  return (
    <FlexColumn
      classNames={css({
        justifyContent: 'space-between',
        overflow: 'hidden',
      })}
    >
      <FlexColumn
        classNames={css({
          overflow: 'auto',
          height: '100%',
        })}
      >
        <LabeledInput
          label="Nombre:"
          value={editingSubClient.name}
          onChange={(value) => onInputChange(value, 'name')}
          required
        />
        <LabeledInput
          label="Nombre comercial:"
          value={editingSubClient.tradeName}
          onChange={(value) => onInputChange(value, 'tradeName')}
          required
        />
        <LabeledInput
          label="RFC:"
          value={editingSubClient.rfc}
          onChange={(value) => onInputChange(value, 'rfc')}
          maxLength={14}
          required
        />
        <div
          className={css({
            borderTop: '1px solid lightGray',
            paddingTop: '1em',
          })}
        >
          <ContactForm
            label="Contacto de emergencia"
            contact={editingSubClient.contact}
            onChange={(value) => onInputChange(value, 'contact')}
            isValid={(valid) => setIsValidContact(valid)}
          />
        </div>
      </FlexColumn>
      <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'flex-end' })}`}>
        <FlexRow gap={theme.sizing.scale300}>
          {subClient?._id && (
            <StyledButton
              kind="tertiary"
              onClick={onCancel}
            >
              Cancelar
            </StyledButton>
          )}
          <StyledButton
            onClick={onSave}
            isLoading={loading}
            disabled={!canSubmit || !hasUpdates}
          >
            Guardar
          </StyledButton>
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  );
}
