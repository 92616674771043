import { Centered, FlexColumn, FlexRow } from '@gorila-shared-ui/components';
import { DisplayLarge, DisplayMedium, HeadingMedium } from 'baseui/typography';
import { useStyles } from '../../hooks/useStyles';

export function ServerErrorPage() {
  const { theme, css } = useStyles();

  return (
    <Centered>
      <FlexColumn>
        <FlexRow
          classNames={css({ alignItems: 'end' })}
          centered
        >
          <DisplayLarge color={theme.colors.negative}>500</DisplayLarge>
          <DisplayMedium marginBottom={'10px'}>Server Error</DisplayMedium>
        </FlexRow>
        <HeadingMedium
          marginBottom={0}
          color={theme.colors.contentSecondary}
        >
          ¡Vaya! Algo salió mal.
        </HeadingMedium>
        <HeadingMedium
          margin={0}
          color={theme.colors.contentSecondary}
        >
          Trata de volver a cargar la página o contacta al soporte técnico
        </HeadingMedium>
      </FlexColumn>
    </Centered>
  );
}
