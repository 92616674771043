import { TabOverrides, TabsOverrides } from 'baseui/tabs-motion';
import { themedUseStyletron } from '../theme/customTheme';

export function useStyles() {
  const [css, theme] = themedUseStyletron();

  const fillParent = css({
    width: '100%',
    height: '100%',
  });

  const horizontalFlex = css({
    display: 'flex',
  });

  const verticalFlex = css({
    display: 'flex',
    flexDirection: 'column',
  });

  const centeredFlex = css({
    alignItems: 'center',
    justifyContent: 'center',
  });

  const centeredStart = css({
    alignItems: 'center',
    justifyContent: 'start',
  });

  const centeredEnd = css({
    alignItems: 'center',
    justifyContent: 'end',
  });

  const flexFill = css({
    flex: '1 1 100%',
  });

  const ellipseText = ({ maxLines = 3, breakAll = false }) =>
    css({
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': maxLines,
      display: '-webkit-box',
      overflow: 'hidden',
      wordBreak: breakAll ? 'break-all' : 'break-word',
    });

  const warn = css({
    color: theme.colors.negative,
  });

  const success = css({
    color: theme.colors.positive,
  });

  const colors = {
    warn,
    success,
  };

  const dataHeaderClass = css({
    paddingTop: theme.sizing.scale200,
    paddingBottom: theme.sizing.scale200,
    paddingLeft: theme.sizing.scale500,
    paddingRight: theme.sizing.scale500,
    backgroundColor: theme.colors.backgroundTertiary,
  });

  const dataContentClass = css({
    paddingLeft: theme.sizing.scale500,
    paddingRight: theme.sizing.scale500,
  });

  const customField = css({
    paddingTop: theme.sizing.scale300,
    paddingBottom: theme.sizing.scale300,
    paddingLeft: theme.sizing.scale550,
    paddingRight: theme.sizing.scale550,
    borderRadius: theme.borders.inputBorderRadius,
    backgroundColor: theme.colors.backgroundTertiary,
    width: '100%',
  });

  const flexible = (flex: number) =>
    css({
      flex,
    });

  const classes = {
    fillParent,
    horizontalFlex,
    verticalFlex,
    centeredFlex,
    centeredStart,
    centeredEnd,
    flexFill,
    ellipseText,
    flexible,
    dataHeaderClass,
    dataContentClass,
    customField,
  };

  const tabsOverrides: TabsOverrides = {
    Root: {
      style: () => ({
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
      }),
    },
    TabBorder: {
      style: { flexShrink: 0 },
    },
  };

  const tabOverrides: TabOverrides = {
    TabPanel: {
      style: {
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        overflow: 'hidden',
      },
    },
  };

  const tabOverflowOverrides: TabOverrides = {
    TabPanel: {
      style: {
        overflow: 'hidden',
      },
    },
  };

  const overrides = {
    tabsOverrides,
    tabOverrides,
    tabOverflowOverrides,
  };

  const concatenateClasses = (...classes: string[]) => {
    return classes.join(' ');
  };

  return { css, theme, classes, colors, overrides, concatenateClasses };
}
