export const DEVICE_MODEL_TABLE_COLUMNS_NAME = [
  {
    label: 'Nombre',
    key: 'name',
  },
  {
    label: 'Marca',
    key: 'brand',
  },
  {
    label: 'Acciones',
    key: 'actions',
  },
];

export const DEVICE_MODEL_COMMANDS_TABLE_COLUMNS_NAME = [
  {
    label: 'Nombre',
    key: 'name',
  },
  {
    label: 'Descripción',
    key: 'description',
  },
  {
    label: 'Comando',
    key: 'command',
  },
  {
    label: 'Acciones',
    key: 'actions',
  },
];