import { FlexRow, FullContainer, MaterialIcon } from '@gorila-shared-ui/components';
import { StyledDivider } from 'baseui/divider';
import { Tab, Tabs } from 'baseui/tabs-motion';
import { LabelSmall } from 'baseui/typography';
import { Navigate, generatePath, useNavigate, useParams } from 'react-router-dom';
import { CLIENTS_PAGE, CLIENT_DETAIL_PAGE } from '../../../constants/app';
import { ACCOUNT_SECTION, STREAMAX_SECTION, SUB_CLIENTS_SECTION, USERS_SECTION } from '../../../constants/clients';
import { useStyles } from '../../../hooks/useStyles';
import { ClientTab } from '../../clients/client/ClientTab';
import { StreamaxTab } from '../../clients/client/StreamaxTab';
import { SubClientsTab } from '../../clients/client/SubClientsTab';
import { UsersTab } from '../../clients/client/UsersTab';
import { StyledRouterLink } from '../../ui/StyledRouterLink';

export function ClientPage() {
  const {
    theme,
    css,
    overrides: { tabOverrides, tabsOverrides },
  } = useStyles();
  const { clientId, tabName } = useParams();
  const navigate = useNavigate();

  if (!clientId) {
    return (
      <Navigate
        to={CLIENTS_PAGE.route}
        replace
      />
    );
  }

  if (!tabName) {
    return (
      <Navigate
        to={generatePath(CLIENT_DETAIL_PAGE.route, { clientId, tabName: ACCOUNT_SECTION })}
        replace={false}
      />
    );
  }

  const activeKey = tabName;

  return (
    <FullContainer verticalFlex>
      <FlexRow
        gap={0}
        classNames={css({
          padding: theme.sizing.scale500,
          gap: theme.sizing.scale500,
          boxSizing: 'border-box',
          alignItems: 'center',
        })}
      >
        <StyledRouterLink
          to={CLIENTS_PAGE.route}
          className={css({ display: 'flex', alignItems: 'center' })}
        >
          <LabelSmall>Clientes</LabelSmall>
        </StyledRouterLink>
        <MaterialIcon
          name={'navigate_next'}
          size="mini"
        />
        <LabelSmall>Cliente</LabelSmall>
      </FlexRow>
      <StyledDivider
        style={{ width: '100%', margin: 0 }}
        $size="cell"
      />
      <Tabs
        activeKey={activeKey}
        onChange={({ activeKey }) => {
          const route = generatePath(CLIENT_DETAIL_PAGE.route, { clientId, tabName: activeKey });
          navigate(route, { replace: false });
        }}
        activateOnFocus
        overrides={tabsOverrides}
      >
        <Tab
          title="Cuenta"
          overrides={tabOverrides}
          key={ACCOUNT_SECTION}
        >
          <ClientTab clientId={clientId} />
        </Tab>
        <Tab
          title="Subclientes"
          overrides={tabOverrides}
          key={SUB_CLIENTS_SECTION}
        >
          <SubClientsTab clientId={clientId} />
        </Tab>
        <Tab
          title="Usuarios"
          overrides={tabOverrides}
          key={USERS_SECTION}
        >
          <UsersTab clientId={clientId} />
        </Tab>
        <Tab
          title="Streamax"
          overrides={tabOverrides}
          key={STREAMAX_SECTION}
        >
          <StreamaxTab clientId={clientId} />
        </Tab>
      </Tabs>
    </FullContainer>
  );
}
