import { getCurrentUser, signIn, signOut, } from "aws-amplify/auth";
import { ApiError } from '../types/apiResponseError';

export const authSignIn = async (username: string, password: string): Promise<{ user?: any; error?: ApiError }> => {
  try {
    const user = await signIn({ username, password });
    return { user };
  } catch (error) {
    return {
      error: 'Credenciales incorrectas.',
    };
  }
};

export const autoLogin = async (): Promise<{ user?: any; error?: ApiError }> => {
  try {
    // const { attributes, username } = await getCurrentUser();
    const { username } = await getCurrentUser();
    return { user: { username } };
  } catch (err) {
    return { error: 'Not authenticated' };
  }
};

export const authSignOut = async (): Promise<void> => {
  await signOut();
};
