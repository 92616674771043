import { useSetRecoilState } from 'recoil';
import {
  autoLogin,
  authSignIn,
  authSignOut,
} from '../services/auth';
import { authUserState, isLoggedInState } from '../storage/app';
import { useClearStorage } from './useClearStorage';

export function useAuth() {
  const setAuthUser = useSetRecoilState(authUserState);
  const setLoggedIn = useSetRecoilState(isLoggedInState);
  const clearStorage = useClearStorage();


  const login = async (username: string, password: string) => {
    const { error } = await authSignIn(username, password);
    if (!error) {
      await initApp();
    } else {
      return { error };
    }
  };

  const initApp = async () => {
    const { user, error } = await autoLogin();
    if (user && !error) {
      setLoggedIn(true);
      setAuthUser(user);
    } else {
      logout();
    }
  };

  const logout = async () => {
    await authSignOut();
    clearStorage();
    setLoggedIn(false);
  };

  return {
    login,
    logout,
    initApp,
  };
}
