import { ApiError } from '../types/apiResponseError';
import { Device } from '../types/device';
import { DeviceBrand } from '../types/deviceBrand';
import { DeviceModel } from '../types/deviceModel';
import { getRequest, postRequest } from './api';
import { URLS } from './urls/urls';

// Devices
export const getDevicesList = async (
  page?: number,
  q?: string,
  clientId?: string,
  subClientId?: string
): Promise<{ devices?: Device[]; error?: ApiError; total: number; hasNext: boolean }> => {
  const params = {
    page: page ?? 1,
    q,
    clientId,
    subClientId,
  };
  const response = await getRequest(URLS.devices.all, {
    queryParams: params,
  });
  return {
    devices: response?.data?.items,
    total: response?.data?.total ?? 0,
    hasNext: response?.data?.hasNext ?? false,
    error: response?.error,
  };
};

export const postDevice = async (device: Device): Promise<{ id?: string; error?: ApiError }> => {
  const body = {
    brandId: device.brand._id,
    modelId: device.model._id,
    ident: device.ident,
    carrier: device.carrier,
    imei: device.imei,
    sim: device.sim,
  };
  const response = await postRequest(URLS.devices.create, {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const updateDevice = async (device: Device): Promise<{ id?: string; error?: ApiError }> => {
  const body = {
    brandId: device.brand._id,
    modelId: device.model._id,
    ident: device.ident,
    carrier: device.carrier,
    imei: device.imei,
    sim: device.sim,
  };
  const response = await postRequest(URLS.devices.update(device._id), {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

// Devices models
export const getDeviceModelsList = async (
  page?: number,
  q?: string,
  deviceBrandId?: string
): Promise<{ models?: DeviceModel[]; error?: ApiError; total: number; hasNext: boolean }> => {
  const params = {
    page: page ?? 1,
    q,
    deviceBrandId,
  };
  const response = await getRequest(URLS.devices.modelList, {
    queryParams: params,
  });
  return {
    models: response?.data?.items,
    total: response?.data?.total ?? 0,
    hasNext: response?.data?.hasNext ?? false,
    error: response?.error,
  };
};

export const getDeviceModel = async (deviceModelId: string): Promise<{ model: DeviceModel; error?: ApiError }> => {
  const response = await getRequest(URLS.devices.detailModel(deviceModelId), {});
  return {
    model: response?.data,
    error: response?.error,
  };
};

export const postDevicesModel = async (deviceModel: DeviceModel): Promise<{ id?: string; error?: ApiError }> => {
  const body = {
    name: deviceModel.name,
    brandId: deviceModel.brand._id,
  };
  const response = await postRequest(URLS.devices.createModel, {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const updateDevicesModel = async (deviceModel: DeviceModel): Promise<{ id?: string; error?: ApiError }> => {
  const body = {
    name: deviceModel.name,
    brandId: deviceModel.brand._id,
    commands: deviceModel.commands,
  };
  const response = await postRequest(URLS.devices.updateModel(deviceModel._id), {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

// Device brands
export const getDeviceBrandsList = async (
  page?: number,
  q?: string
): Promise<{ brands?: DeviceBrand[]; error?: ApiError; total: number; hasNext: boolean }> => {
  const params = {
    page: page ?? 1,
    q,
  };
  const response = await getRequest(URLS.devices.brandList, {
    queryParams: params,
  });
  return {
    brands: response?.data?.items,
    total: response?.data?.total ?? 0,
    hasNext: response?.data?.hasNext ?? false,
    error: response?.error,
  };
};

export const postDevicesBrand = async (deviceBrand: DeviceBrand): Promise<{ id?: string; error?: ApiError }> => {
  const body = {
    name: deviceBrand.name,
  };
  const response = await postRequest(URLS.devices.createBrand, {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const updateDevicesBrand = async (deviceBrand: DeviceBrand): Promise<{ id?: string; error?: ApiError }> => {
  const body = {
    name: deviceBrand.name,
  };
  const response = await postRequest(URLS.devices.updateBrand(deviceBrand._id), {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};
