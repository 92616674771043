import { PaginatedColumn } from '../types/app';
import { AssetMinimal } from '../types/asset';

export const ASSET_TYPE_VEHICLE = 'vehicle';
export const ASSET_TYPE_CONTAINER = 'container';
export const ASSET_TYPE_PHONE = 'phone';

export const ASSET_TYPE = {
  [ASSET_TYPE_VEHICLE]: 'Vehículo',
  [ASSET_TYPE_CONTAINER]: 'Contenedor',
  [ASSET_TYPE_PHONE]: 'celular',
};

export const ASSETS_TABLE_COLUMNS_NAME: PaginatedColumn[] = [
  {
    label: 'Ident',
    key: 'ident',
  },
  {
    label: 'Tipo Activo',
    key: 'assetType',
  },
  {
    label: 'Etiqueta',
    key: 'label',
  },
  {
    label: 'Cliente',
    key: 'client',
  },
  {
    label: 'Sub Cliente',
    key: 'subClient',
  },
  {
    label: 'Ult. Posición',
    key: 'cs',
  },
  {
    label: 'Seguimiento',
    key: 'monitoring',
  },
  {
    label: 'Eliminado',
    key: 'deleted',
  },
  {
    label: 'Acciones',
    key: 'actions',
    minWidth: 120,
    maxWidth: 120,
    centered: true,
  },
];

export const DEFAULT_ASSET_MINIMAL: AssetMinimal = {
  _id: '',
  label: '',
  assetType: 'vehicle',
  deleted: false,
  monitoring: true,
}