import { FixedMarkerOverrides, FloatingMarker } from 'baseui/map-marker';
import { memo, useEffect, useState } from 'react';
import { Marker } from 'react-map-gl';
import { useRecoilValue } from 'recoil';
import { useCamera } from '../../../hooks/useCamera';
import { isValidLatitude, isValidLongitude } from '../../../utils/map';
import { useStyles } from '../../../hooks/useStyles';
import { selectDeviceState } from '../../../storage/devices';

function DeviceMarker() {
  const { theme } = useStyles();
  const deviceSelected = useRecoilValue(selectDeviceState);
  const { fitToLngLats } = useCamera();
  const [devicePoints, setDevicePoints] = useState<{label: string, point: number[]}[]>([]);

  useEffect(() => {
    let points: {label: string, point: number[]}[] =[] 
    if (deviceSelected?.cs?.position_latitude && deviceSelected?.cs?.position_longitude) {
      points.push({
        label: 'Estado actual',
        point: [deviceSelected?.cs?.position_longitude, deviceSelected?.cs?.position_latitude]
      })
    }
    if (deviceSelected?.hsWithoutAsset?.position_latitude && deviceSelected?.hsWithoutAsset?.position_longitude) {
      points.push({
        label: 'Posición sin asset',
        point: [deviceSelected?.hsWithoutAsset?.position_longitude, deviceSelected?.hsWithoutAsset?.position_latitude]
      })
    }
    points = points.filter((devicePoint) => {
      return isValidLatitude(devicePoint.point[1]) && isValidLongitude(devicePoint.point[0]);
    })
    fitToLngLats(points.map((devicePoint) => devicePoint.point));
    setDevicePoints(points);
  }, [deviceSelected]);

  const floatingMarkerOverrides: FixedMarkerOverrides = {
    Root: {
      style: () => ({
        transform: `translateY(-50%)`,
      }),
    },
    OuterAnchor: {
      style: { backgroundColor: theme.colors.negative },
    },
  };

  if (!devicePoints?.length) return null;

  return (
    devicePoints.map((device, i) => {
      return (
        <Marker
          key={`device-${i}`}
          latitude={device.point[1]}
          longitude={device.point[0]}
        >
          <FloatingMarker
            label={device.label}
            overrides={floatingMarkerOverrides}
            size="small"
            anchorType="circle"
          />
        </Marker>
      )
    })
  );
}

export default memo(DeviceMarker);
