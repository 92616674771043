import { EllipsedText, FlexColumn, LabeledIconData } from '@gorila-shared-ui/components';
import { Block } from 'baseui/block';
import { StyledDivider } from 'baseui/divider';
import { ParagraphSmall } from 'baseui/typography';
import { useMemo, useState } from 'react';
import { ASSET_TYPE } from '../../constants/asset';
import {
  BULK_ASSETS_FILE,
  BULK_CONTAINER_ASSETS_TABLE_COLUMNS,
  BULK_DEVICES_FILE,
  BULK_DEVICES_TABLE_COLUMNS,
  BULK_DISABLE_MONITORING_FILE,
  BULK_DISABLE_MONITORING_TABLE_COLUMNS,
  BULK_FILE_TYPES,
  BULK_LINK_DEVICE_ASSETS_FILE,
  BULK_LINK_DEVICE_ASSETS_TABLE_COLUMNS,
  BULK_VEHICLE_ASSETS_TABLE_COLUMNS,
} from '../../constants/bulk';
import { useStyles } from '../../hooks/useStyles';
import { Bulk } from '../../types/bulk';
import { StyledPaginatedTable } from '../shared/StyledPaginatedTable';

type Props = {
  bulkFile: Bulk;
};
export function BulkInfo({ bulkFile }: Readonly<Props>) {
  const { theme } = useStyles();
  const [page, setPage] = useState(1);
  const labelWidth = '160px';

  const bulkRowsColumnNames = (() => {
    switch (bulkFile.metadata.filetype) {
      case BULK_LINK_DEVICE_ASSETS_FILE:
        return BULK_LINK_DEVICE_ASSETS_TABLE_COLUMNS;
      case BULK_ASSETS_FILE:
        if (bulkFile.metadata.assettype === 'vehicle') return BULK_VEHICLE_ASSETS_TABLE_COLUMNS;
        else if (bulkFile.metadata.assettype === 'container') return BULK_CONTAINER_ASSETS_TABLE_COLUMNS;
        return [];
      case BULK_DEVICES_FILE:
        return BULK_DEVICES_TABLE_COLUMNS;
      case BULK_DISABLE_MONITORING_FILE:
        return BULK_DISABLE_MONITORING_TABLE_COLUMNS;
      default:
        return [];
    }
  })();

  const bulkData = useMemo(() => {
    return bulkFile.rows.map((row, i) => {
      let items: any[] = [];
      switch (bulkFile.metadata.filetype) {
        case BULK_LINK_DEVICE_ASSETS_FILE:
          items = [
            <EllipsedText key={`ident-${i}`}>{row.ident?.toString() ?? '-'}</EllipsedText>,
            <EllipsedText key={`identifier-${i}`}>{row.identifier?.toString() ?? '-'}</EllipsedText>,
          ];
          break;
        case BULK_ASSETS_FILE:
          if (bulkFile.metadata.assettype === 'vehicle')
            items = [
              row.licensePlate,
              <EllipsedText key={`label-${i}`}>{row.label?.toString() ?? '-'}</EllipsedText>,
              row.brand,
              row.subBrand,
              row.year,
              row.color,
              row.version,
              row.vin,
              row.vehicleType,
            ];
          else if (bulkFile.metadata.assettype === 'container')
            items = [
              <EllipsedText key={`identifier-${i}`}>{row.identifier?.toString() ?? '-'}</EllipsedText>,
              <EllipsedText key={`label-${i}`}>{row.label?.toString() ?? '-'}</EllipsedText>,
              <EllipsedText key={`ident-${i}`}>{row.ident?.toString() ?? '-'}</EllipsedText>,
            ];
          break;
        case BULK_DEVICES_FILE:
          items = [
            <EllipsedText key={`ident-${i}`}>{row.ident?.toString() ?? '-'}</EllipsedText>,
            row.imei,
            row.sim,
            row.carrier,
          ];
          break;
        case BULK_DISABLE_MONITORING_FILE:
          items = [<EllipsedText key={`ident-${i}`}>{row.ident?.toString() ?? '-'}</EllipsedText>];
          break;
      }
      items.push(
        <ParagraphSmall
          key={`errors-${i}`}
          margin={0}
          color={theme.colors.negative}
        >
          {row.error}
        </ParagraphSmall>
      );
      return {
        items,
      };
    });
  }, [bulkFile.metadata.assettype, bulkFile.metadata.filetype, bulkFile.rows]);

  return (
    <>
      <FlexColumn gap={theme.sizing.scale500}>
        <LabeledIconData
          label="Tipo de archivo:"
          data={BULK_FILE_TYPES[bulkFile.metadata.filetype] ?? 'No válido'}
          icon="file_present"
          labelWidth={labelWidth}
        />
        <FlexColumn gap={theme.sizing.scale300}>
          <LabeledIconData
            label="Errores:"
            data={bulkFile.errors.length?.toString()}
            icon="warning"
            labelWidth={labelWidth}
          />
          {!!bulkFile.errors.length && (
            <ParagraphSmall
              margin={0}
              color={theme.colors.negative}
            >
              {bulkFile.errors}
            </ParagraphSmall>
          )}
        </FlexColumn>
      </FlexColumn>
      <StyledDivider
        $size="cell"
        $style={{ width: '100%' }}
      />
      {bulkFile.metadata.filetype === BULK_ASSETS_FILE && (
        <FlexColumn gap={theme.sizing.scale500}>
          <LabeledIconData
            label="Cliente:"
            data={bulkFile.client?.name ?? 'No encontrado'}
            icon="business_center"
            labelWidth={labelWidth}
          />
          <LabeledIconData
            label="Subcliente:"
            data={bulkFile.subClient?.name ?? 'No encontrado'}
            icon="business_center"
            labelWidth={labelWidth}
          />
          <LabeledIconData
            label="Tipo de activo:"
            data={bulkFile.metadata.assettype ? ASSET_TYPE[bulkFile.metadata.assettype] : '-'}
            icon="transportation"
            labelWidth={labelWidth}
          />
        </FlexColumn>
      )}
      {bulkFile.metadata.filetype === BULK_DEVICES_FILE && (
        <FlexColumn gap={theme.sizing.scale500}>
          <LabeledIconData
            label="Marca:"
            data={bulkFile.deviceBrand?.name ?? 'Sin marca'}
            icon="devices_other"
            labelWidth={labelWidth}
          />
          <LabeledIconData
            label="Modelo:"
            data={bulkFile.deviceModel?.name ?? 'Sin modelo'}
            icon="device_hub"
            labelWidth={labelWidth}
          />
        </FlexColumn>
      )}
      {bulkFile.metadata.filetype === BULK_DISABLE_MONITORING_FILE && (
        <FlexColumn gap={theme.sizing.scale500}>
          <LabeledIconData
            label="Cliente:"
            data={bulkFile.client?.name ?? 'No encontrado'}
            icon="business_center"
            labelWidth={labelWidth}
          />
          <LabeledIconData
            label="Subcliente:"
            data={bulkFile.subClient?.name ?? 'No encontrado'}
            icon="business_center"
            labelWidth={labelWidth}
          />
          {!!bulkFile.errors.length && (
            <LabeledIconData
              label="Campos requeridos:"
              data={bulkFile.requiredFields}
              icon="insert_drive_file"
              labelWidth="labelWidth"
            />
          )}
        </FlexColumn>
      )}

      {!!bulkFile.rows.length && (
        <Block height={'240px'}>
          <StyledPaginatedTable
            columns={bulkRowsColumnNames}
            data={bulkData}
            totalItems={bulkFile.rows.length}
            page={page}
            onPageChange={setPage}
            loading={false}
            minColumnWidth={
              bulkFile.metadata.filetype === BULK_ASSETS_FILE && bulkFile.metadata.assettype === 'vehicle'
                ? '190px'
                : undefined
            }
          />
        </Block>
      )}
    </>
  );
}
