import { EmptyState, FlexColumn, Loading, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { EMISSIONS_REPORTS_TABLE_COLUMNS_NAME } from '../../constants/reports';
import { useFeedback } from '../../hooks/useFeedback';
import { useLoading } from '../../hooks/useLoading';
import { useMoment } from '../../hooks/useMoment';
import { useStyles } from '../../hooks/useStyles';
import { downloadEmissionReportFile, getEmissionsReportsList } from '../../services/reportsService';
import { reportDateRangeState, reportReloadListState } from '../../storage/reports';
import { EmissionReport } from '../../types/report';
import { StyledPaginatedTable } from '../shared/StyledPaginatedTable';

export function ReportsList() {
  const { css, classes } = useStyles();
  const [reports, setReports] = useState<EmissionReport[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true);
  const { formatDateFromTs } = useMoment();
  const { showFailFeedback } = useFeedback();
  const reloadPage = useRecoilValue(reportReloadListState);
  const dateRange = useRecoilValue(reportDateRangeState);

  useEffect(() => {
    if (initialLoad) return;
    setPage(0);
  }, [reloadPage]);

  useEffect(() => {
    if (dateRange && dateRange[0] && dateRange[1]) {
      setPage(0);
    } else if (!dateRange) {
      setPage(0);
    }
  }, [dateRange]);

  const loadReport = async () => {
    startLoading();
    const { reports, error, total } = await getEmissionsReportsList(
      page,
      dateRange && dateRange[0],
      dateRange && dateRange[1]
    );
    if (!error) {
      setReports(reports);
      setTotalItems(total);
    } else {
      showFailFeedback(error);
      setReports(undefined);
      setTotalItems(0);
    }
    stopLoading();
  };

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    loadReport();
    setInitialLoad(false);
  }, [page]);

  const downloadReport = async (reportKey: string) => {
    const { url, error } = await downloadEmissionReportFile(reportKey);
    if (!error && url) {
      window.open(url, '_blank');
    } else {
      showFailFeedback(error || 'Ocurrió un error al descargar el reporte, inténtalo más tarde.');
    }
  };

  return (
    <FlexColumn classNames={`${classes.flexFill} ${css({ overflow: 'hidden' })}`}>
      {!reports && !loading && <EmptyState title="Todavía no tienes ningun reporte" />}
      {!reports && loading && <Loading />}
      {reports && (
        <>
          {!!reports.length && (
            <StyledPaginatedTable
              columns={EMISSIONS_REPORTS_TABLE_COLUMNS_NAME}
              data={reports.map((report, i) => {
                return {
                  items: [
                    report.name || '-',
                    report.status || '-',
                    report.created ? formatDateFromTs(report.created) : '-',
                    report.status === 'Terminado' && (
                      <StyledTooltip
                        key={`ws-action-${i}`}
                        content={'Descargar'}
                        showArrow={false}
                      >
                        <Button
                          size="compact"
                          shape="square"
                          onClick={() => downloadReport(report._id)}
                          kind="tertiary"
                        >
                          <MaterialIcon name={'download'} />
                        </Button>
                      </StyledTooltip>
                    ),
                  ],
                };
              })}
              totalItems={totalItems}
              page={page}
              onPageChange={setPage}
              loading={loading}
            />
          )}
          {!reports.length && (
            <EmptyState
              title="No se encontraron resultados"
              description="Intenta con otra busqueda"
            />
          )}
        </>
      )}
    </FlexColumn>
  );
}
