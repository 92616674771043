import { EnhancedLabeledSelect, FlexRow } from '@gorila-shared-ui/components';
import { OnChangeParams } from 'baseui/select';
import { useRecoilState } from 'recoil';
import { WS_TYPE, WS_TYPE_RECURSO_CONFIABLE, WS_TYPE_UNIGIS } from '../../constants/webService';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { useStyles } from '../../hooks/useStyles';
import { wsTypeState } from '../../storage/logs';
import { WsType } from '../../types/webService';
import { ClientSelect } from '../shared/selects/ClientSelect';
import { SubClientSelect } from '../shared/selects/SubClientSelect';

type Props = {
  selectedClientId?: string;
  selectedSubClientId?: string;
  setSelectedClientId: (clientId?: string) => void;
  setSelectedSubClientId: (subClientId?: string) => void;
};
export function WebServiceLogsFilters({
  selectedClientId,
  selectedSubClientId,
  setSelectedSubClientId,
  setSelectedClientId,
}: Readonly<Props>) {
  const { css, theme, classes } = useStyles();
  const [selectedWsType, setSelectedWsType] = useRecoilState(wsTypeState);
  const { isSmall } = useBreakpoints();

  const wsTypeOptions = [
    {
      id: undefined,
      label: 'Todos',
    },
    {
      id: WS_TYPE_RECURSO_CONFIABLE,
      label: WS_TYPE[WS_TYPE_RECURSO_CONFIABLE],
    },
    {
      id: WS_TYPE_UNIGIS,
      label: WS_TYPE[WS_TYPE_UNIGIS],
    },
  ];

  const onWsTypeChange = (params: OnChangeParams) => {
    params.option?.id ? setSelectedWsType(params.option.id as WsType) : setSelectedWsType(undefined);
  };

  const handleClientChange = (clientId?: string) => {
    if (clientId !== selectedClientId) {
      setSelectedClientId(clientId);
      setSelectedSubClientId(undefined);
    }
  };

  return (
    <FlexRow
      gap={theme.sizing.scale300}
      classNames={`${classes.fillParent} ${css({
        flexDirection: isSmall ? 'column' : 'row',
      })}`}
    >
      <EnhancedLabeledSelect
        label="Tipo:"
        options={wsTypeOptions}
        value={[{ id: selectedWsType }]}
        onChange={onWsTypeChange}
        fullWidth
        inline
        inset
      />
      <ClientSelect
        selectedClientId={selectedClientId}
        onChangeClientId={handleClientChange}
        inline
      />
      <SubClientSelect
        selectedClientId={selectedClientId}
        selectedSubClientId={selectedSubClientId}
        onChangeSubClientId={setSelectedSubClientId}
        disabled={!selectedClientId}
        inline
      />
    </FlexRow>
  );
}
