import moment from 'moment';
import { DAY, HOUR } from '../constants/app';

export function useMoment() {
  const defaultDateFormat = 'DD/MM/yyyy HH:mm:ss';

  const formatDateFromTs = (ts: number, format?: string) => {
    return moment(new Date(ts)).format(format ?? defaultDateFormat);
  };

  const timeMinusDays = (ts: number, days: number) => {
    return ts - days * DAY;
  };

  const timeMinusHours = (ts: number, hours: number) => {
    return ts - hours * HOUR;
  };

  const formatHourByNumber = (hour: number) => {
    const hours = Math.trunc(hour);
    let minutes = hour - hours;
    if (minutes > 0) minutes = minutes * 60;
    return `${moment().hour(hour).format('HH')}:${moment().minutes(minutes).format('mm')}`;
  };

  const convertHourToNumber = (hourStr: string) => {
    const hour = hourStr.split(':');
    let hours = +hour[0];
    const minutes = +hour[1];
    if (minutes > 0) hours += +(minutes / 60).toFixed(2);
    return hours;
  };

  return { formatDateFromTs, timeMinusHours, formatHourByNumber, convertHourToNumber, timeMinusDays };
}
