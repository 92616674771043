import { FlexColumn, FlexRow, LabeledData, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { LabelMedium } from 'baseui/typography';
import { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useStyles } from '../../../hooks/useStyles';
import { Version } from '../../../types/version';
import { VersionForm } from '../VersionForm';

type Props = {
  isOpen: boolean;
  selectedVersion?: Version;
  onClose: (update?: boolean) => void;
};

function VersionDetailModal({ onClose, isOpen, selectedVersion }: Props) {
  const { css } = useStyles();
  const [version, setVersion] = useState<Version>();
  const [editingMode, setEditingMode] = useState(false);

  useEffect(() => {
    if (isOpen && selectedVersion) setEditingMode(false);
  }, [isOpen]);

  useEffect(() => {
    if (!selectedVersion) {
      setVersion(undefined);
      setEditingMode(true);
      return;
    } else {
      setVersion(selectedVersion);
    }
  }, [selectedVersion]);

  return createPortal(
    <Modal
      animate
      closeable
      onClose={() => onClose()}
      isOpen={isOpen}
      size="auto"
    >
      <FlexRow
        gap={0}
        classNames={css({
          justifyContent: 'space-between',
          margin: '6px 2rem 0px 0px',
        })}
      >
        <ModalHeader
          className={css({
            marginBottom: '0 !important',
          })}
        >
          {version?.name ?? 'Nuevo'}
        </ModalHeader>
        {!editingMode && (
          <StyledTooltip
            content={'Editar'}
            showArrow={false}
          >
            <Button
              size="compact"
              shape="square"
              onClick={() => setEditingMode(true)}
              kind="tertiary"
            >
              <MaterialIcon
                name={'edit'}
                size="xs-mini"
              />
            </Button>
          </StyledTooltip>
        )}
      </FlexRow>
      <ModalBody>
        <FlexColumn
          classNames={css({
            width: '70vw',
            maxWidth: '1200px',
            minWidth: '350px',
            boxSizing: 'border-box',
            padding: '1rem',
            borderTop: '1px solid rgb(226, 226, 226)',
            maxHeight: '80vh',
          })}
        >
          {!editingMode && (
            <>
              {version?.brand.name && (
                <LabeledData
                  label={<LabelMedium minWidth={'110px'}>{'Marca:'}</LabelMedium>}
                  content={version?.brand.name}
                />
              )}
              {version?.subBrand.name && (
                <LabeledData
                  label={<LabelMedium minWidth={'110px'}>{'Submarca:'}</LabelMedium>}
                  content={version?.subBrand.name}
                />
              )}
            </>
          )}
          {editingMode && (
            <VersionForm
              version={version}
              onCancel={() => setEditingMode(false)}
              afterSave={() => onClose(true)}
            />
          )}
        </FlexColumn>
      </ModalBody>
    </Modal>,
    document.body
  );
}

export default memo(VersionDetailModal);
