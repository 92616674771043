import { FlexRow, MaterialIcon, StyledButton, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useResetRecoilState } from 'recoil';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import { useModal } from '../../../../hooks/useModal';
import { useStyles } from '../../../../hooks/useStyles';
import { clientStreamaxSearchFilterState } from '../../../../storage/clients';
import WebhookDetailModal from '../../../webhooks/modal/WebhookDetailModal';

type Props = {
  clientId: string;
  onReloadPage: () => void;
};
export function ClientStreamaxFiltersBar({ clientId, onReloadPage }: Readonly<Props>) {
  const { css, theme } = useStyles();
  const { openModal: openFormModal, closeModal: closeFormModal, showModal: showFormModal } = useModal();
  const resetSearch = useResetRecoilState(clientStreamaxSearchFilterState);

  useEffectOnce(() => {
    return () => {
      resetSearch();
    };
  });

  return (
    <FlexRow
      classNames={css({
        justifyContent: 'space-between',
        width: '100%',
      })}
    >
      <FlexRow
        gap={theme.sizing.scale300}
        classNames={css({
          width: '70%',
        })}
      >
        <></>
        {/* TODO: uncomment to enable search bar
        <FlexRow
          classNames={css({
            width: '60%',
          })}
        >
          <StyledSearchBar
            value={search ?? ''}
            onChange={setSearch}
            clearable
          />
        </FlexRow> */}
      </FlexRow>
      <FlexRow gap={theme.sizing.scale300}>
        <StyledTooltip
          content={'Recargar tabla'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={onReloadPage}
            kind="tertiary"
          >
            <MaterialIcon name={'refresh'} />
          </Button>
        </StyledTooltip>
        <StyledButton
          onClick={openFormModal}
          startEnhancer={
            <MaterialIcon
              name="add"
              size="xs-mini"
              color={theme.colors.contentInversePrimary}
            />
          }
        >
          Nuevo
        </StyledButton>
      </FlexRow>
      {showFormModal && (
        <WebhookDetailModal
          clientId={clientId}
          onClose={(updateTable) => {
            if (updateTable) {
              onReloadPage();
            }
            closeFormModal();
          }}
          isOpen={showFormModal}
        />
      )}
    </FlexRow>
  );
}
