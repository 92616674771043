import { FlexColumn, FlexRow, LabeledData, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { LabelMedium } from 'baseui/typography';
import { useBreakpoints } from '../../../hooks/useBreakpoints';
import { useStyles } from '../../../hooks/useStyles';
import { Client } from '../../../types/client';

type Props = {
  client: Client;
  changeMode: () => void;
};
export function ClientDetail({ client, changeMode }: Readonly<Props>) {
  const { css } = useStyles();
  const { isXSmall } = useBreakpoints();

  return (
    <FlexColumn
      classNames={css({
        height: '100%',
        width: '100%',
        maxWidth: '900px',
        gap: 0,
        padding: '0px 2em',
        boxSizing: 'border-box',
      })}
    >
      <FlexRow
        classNames={css({
          justifyContent: 'end',
          paddingRight: '1em',
        })}
      >
        <StyledTooltip
          content={'Editar'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={changeMode}
            kind="tertiary"
          >
            <MaterialIcon name={'edit'} />
          </Button>
        </StyledTooltip>
      </FlexRow>
      <FlexRow breakPoint="isXSmall">
        <FlexColumn
          classNames={css({
            width: isXSmall ? '100%' : '45%',
          })}
        >
          <LabelMedium>Datos generales</LabelMedium>
          <LabeledData
            label="Nombre:"
            content={client?.name}
          />
          <LabeledData
            label="Nombre comercial:"
            content={client?.tradeName}
          />
          <LabeledData
            label="RFC:"
            content={client?.rfc}
          />
          <LabeledData
            label="Día de pago:"
            content={client?.paymentDay}
          />
          <LabeledData
            label="Pagado:"
            content={client?.paid ? 'Si' : 'No'}
          />
        </FlexColumn>
        <FlexColumn>
          <LabelMedium>Contacto de emergencia</LabelMedium>
          <LabeledData
            label="Nombre:"
            content={client?.contact?.fullName}
          />
          <LabeledData
            label="Correo:"
            content={client?.contact?.email}
          />
          <LabeledData
            label="Teléfono:"
            content={client?.contact?.phone}
          />
          <LabelMedium>Contacto de instalación</LabelMedium>
          <LabeledData
            label="Nombre:"
            content={client?.installationContact?.fullName}
          />
          <LabeledData
            label="Correo:"
            content={client?.installationContact?.email}
          />
          <LabeledData
            label="Teléfono:"
            content={client?.installationContact?.phone}
          />
          <LabelMedium>Contacto de facturación</LabelMedium>
          <LabeledData
            label="Nombre:"
            content={client?.invoiceContact?.fullName}
          />
          <LabeledData
            label="Correo:"
            content={client?.invoiceContact?.email}
          />
          <LabeledData
            label="Teléfono:"
            content={client?.invoiceContact?.phone}
          />
        </FlexColumn>
      </FlexRow>
    </FlexColumn>
  );
}
