import { FixedMarkerOverrides, FloatingMarker } from 'baseui/map-marker';
import { memo, useEffect } from 'react';
import { Marker, MarkerDragEvent } from 'react-map-gl';
import { useRecoilState } from 'recoil';
import { useCamera } from '../../../hooks/useCamera';
import { useStyles } from '../../../hooks/useStyles';
import { editingInstallationState } from '../../../storage/Installations';
import { getAddressFromPoint } from '../../../utils/geocode';
import { isValidLatitude, isValidLongitude } from '../../../utils/map';

type Props = {
  onlyView?: boolean;
};
function InstallationDetailMarker({ onlyView = false }: Readonly<Props>) {
  const { theme } = useStyles();
  const [editingInstallation, setEditingInstallation] = useRecoilState(editingInstallationState);
  const { zoomTo } = useCamera();

  useEffect(() => {
    if (!editingInstallation?.latitude || !editingInstallation?.longitude) return;
    const point = {
      lng: editingInstallation.longitude,
      lat: editingInstallation.latitude,
    };
    const isValidPoint = isValidLatitude(point.lat) && isValidLongitude(point.lng);
    if (isValidPoint) {
      zoomTo(point);
    }
  }, [editingInstallation]);

  if (!editingInstallation?.latitude || !editingInstallation?.longitude) return null;

  const floatingMarkerOverrides: FixedMarkerOverrides = {
    Root: {
      style: () => ({
        transform: `translateY(-50%)`,
      }),
    },
    OuterAnchor: {
      style: { backgroundColor: theme.colors.negative },
    },
  };

  const point = {
    lng: editingInstallation.longitude,
    lat: editingInstallation.latitude,
  };

  const isValidPoint = isValidLatitude(point.lat) && isValidLongitude(point.lng);

  if (!isValidPoint) return null;

  const onDragEnd = async (event: MarkerDragEvent) => {
    const address = await getAddressFromPoint(event.lngLat);
    setEditingInstallation((prev) => ({
      ...prev!,
      georeference: address ?? '',
      latitude: event.lngLat.lat,
      longitude: event.lngLat.lng,
    }));
  };

  return (
    <Marker
      latitude={point.lat}
      longitude={point.lng}
      draggable={!onlyView}
      onDragEnd={onlyView ? () => {} : onDragEnd}
    >
      <FloatingMarker
        label={editingInstallation.georeference.trim() ? editingInstallation.georeference : 'Sin nombre'}
        overrides={floatingMarkerOverrides}
        size="small"
        anchorType="circle"
      />
    </Marker>
  );
}

export default memo(InstallationDetailMarker);
