import {
  EmptyState,
  FlexColumn,
  FlexRow,
  FullContainer,
  MaterialIcon,
  StyledSearchBar,
} from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { LabelMedium } from 'baseui/typography';
import { useEffect, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { DEFAULT_LIST_ITEM_HEIGHT } from '../../../constants/app';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import { useLoading } from '../../../hooks/useLoading';
import { useStyles } from '../../../hooks/useStyles';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { AssetMinimal } from '../../../types/asset';
import AssetItem from './AssetItem';
import { useAssets } from './hooks/useAssets';

type Props = {
  clientId?: string;
  subClientId?: string;
  assignedAssetIds: string[];
  onAssignedChange: (assetIds: string[]) => void;
  required?: boolean;
};
export function AssetListPicker({
  clientId,
  subClientId,
  assignedAssetIds,
  onAssignedChange,
  required,
}: Readonly<Props>) {
  const { theme, css, classes } = useStyles();
  const { getSelectedAssets, getUnselectedAssets } = useAssets();
  const [selectedAssets, setSelectedAssets] = useState<AssetMinimal[]>();
  const [selectedAssetHasNext, setSelectedAssetHasNext] = useState(false);
  const [selectedAssetPage, setSelectedAssetPage] = useState(1);
  const {
    startLoading: startSelectedAssetLoading,
    stopLoading: stopSelectedAssetLoading,
    loading: selectedAssetLoading,
  } = useLoading();
  const [unselectedAssets, setUnselectedAssets] = useState<AssetMinimal[]>();
  const [unselectedAssetHasNext, setUnselectedAssetHasNext] = useState(false);
  const [unselectedAssetPage, setUnselectedAssetPage] = useState(1);
  const {
    startLoading: startUnselectedAssetLoading,
    stopLoading: stopUnselectedAssetLoading,
    loading: unselectedAssetLoading,
  } = useLoading();
  const { search: searchAsset, setSearch: setSearchAsset, debouncedSearch } = useDebouncedSearch('');

  const isAssetsSelectLoaded = (index: number) => !selectedAssetHasNext || index < (selectedAssets?.length || 0);
  const assetSelectedCount = selectedAssetHasNext ? (selectedAssets?.length ?? 0) + 1 : selectedAssets?.length ?? 0;
  const loadMoreSelectedAsset =
    selectedAssetLoading || !selectedAssetHasNext
      ? () => {}
      : () => {
          setSelectedAssetPage(selectedAssetPage + 1);
        };

  const isAssetsUnselectLoaded = (index: number) => !unselectedAssetHasNext || index < (unselectedAssets?.length || 0);
  const assetUnselectedCount = unselectedAssetHasNext
    ? (unselectedAssets?.length ?? 0) + 1
    : unselectedAssets?.length ?? 0;
  const loadMoreUnselectAssets =
    unselectedAssetLoading || !unselectedAssetHasNext
      ? () => {}
      : () => {
          setUnselectedAssetPage(unselectedAssetPage + 1);
        };

  useUpdateEffect(() => {
    setSelectedAssetPage(0);
    setUnselectedAssetPage(0);
  }, [assignedAssetIds, debouncedSearch, clientId, subClientId]);

  useEffect(() => {
    if (!assignedAssetIds.length) {
      setSelectedAssets([]);
      setSelectedAssetPage(1);
      return;
    }
    if (selectedAssetPage === 0) {
      setSelectedAssetPage(1);
      return;
    }
    const loadSelectedAssets = async () => {
      const response = await getSelectedAssets(clientId, subClientId, selectedAssetPage, searchAsset, assignedAssetIds);
      if (response) {
        setSelectedAssets(response.items);
        setSelectedAssetHasNext(response.hasNext);
      } else {
        // TODO error action
        setSelectedAssets([]);
        setSelectedAssetHasNext(false);
      }
    };

    loadSelectedAssets();
  }, [selectedAssetPage]);

  useEffect(() => {
    if (unselectedAssetPage === 0) {
      setUnselectedAssetPage(1);
      return;
    }
    const loadUnselectedAssets = async () => {
      const response = await getUnselectedAssets(
        clientId,
        subClientId,
        unselectedAssetPage,
        searchAsset,
        assignedAssetIds
      );
      if (response) {
        setUnselectedAssets(response.items);
        setUnselectedAssetHasNext(response.hasNext);
      } else {
        // TODO error action
        setSelectedAssets([]);
        setSelectedAssetHasNext(false);
      }
    };

    loadUnselectedAssets();
  }, [unselectedAssetPage]);

  if (!selectedAssets && !unselectedAssets) return null;

  const onSelectAsset = (assetId: string) => {
    const assetsSelection = [...assignedAssetIds, assetId];
    onAssignedChange(assetsSelection);
  };

  const onDeselectAsset = (assetId: string) => {
    const assetsSelection = assignedAssetIds?.filter((id) => assetId !== id);
    onAssignedChange(assetsSelection || []);
  };

  const onSelectAllAssets = () => {
    onAssignedChange([...(assignedAssetIds || []), ...(unselectedAssets?.map((asset) => asset._id) || [])]);
    setSearchAsset('');
  };

  const onDeselectAllAssets = () => {
    if (searchAsset) {
      const assetsSelection = assignedAssetIds?.filter((id) => !selectedAssets?.some((asset) => asset._id === id));
      onAssignedChange(assetsSelection || []);
    } else {
      onAssignedChange([]);
    }
    setSearchAsset('');
  };

  return (
    <FullContainer
      flexible
      classNames={`${classes.flexFill} ${classes.verticalFlex} ${css({ gap: theme.sizing.scale400 })}`}
    >
      <StyledSearchBar
        placeholder="Buscar activo"
        name="assets-search"
        value={searchAsset}
        onChange={(event) => setSearchAsset(event)}
        clearable
      />
      <FlexRow classNames={`${classes.flexFill}`}>
        <FlexColumn classNames={`${classes.flexFill} ${css({ gap: theme.sizing.scale300 })}`}>
          <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'space-between' })}`}>
            <LabelMedium>Disponibles</LabelMedium>
            <Button
              size="mini"
              shape="round"
              kind="tertiary"
              disabled={!unselectedAssets?.length}
              onClick={onSelectAllAssets}
            >
              <MaterialIcon
                name="playlist_add"
                size="mini"
              />
            </Button>
          </FlexRow>
          {unselectedAssets && (
            <>
              {!!unselectedAssets?.length && (
                <ul
                  className={css({
                    margin: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    height: '100%',
                  })}
                >
                  <AutoSizer>
                    {({ width, height }) => (
                      <InfiniteLoader
                        isItemLoaded={isAssetsUnselectLoaded}
                        itemCount={assetUnselectedCount}
                        loadMoreItems={loadMoreUnselectAssets}
                      >
                        {({ onItemsRendered, ref }) => (
                          <List
                            height={height ? height : 500}
                            itemCount={assetUnselectedCount}
                            itemSize={() => DEFAULT_LIST_ITEM_HEIGHT}
                            width={width ? width : 200}
                            itemData={{
                              assets: unselectedAssets,
                              type: 'selectable',
                              onClick: onSelectAsset,
                              isLoaded: isAssetsUnselectLoaded,
                            }}
                            onItemsRendered={onItemsRendered}
                            overscanCount={4}
                            ref={ref}
                          >
                            {AssetItem}
                          </List>
                        )}
                      </InfiniteLoader>
                    )}
                  </AutoSizer>
                </ul>
              )}
              {searchAsset && !unselectedAssets.length && (
                <EmptyState
                  title="No se encontraron resultados"
                  description="Intenta con otra busqueda"
                  centered={false}
                />
              )}
              {!searchAsset && !unselectedAssets.length && (
                <EmptyState
                  title="No tienes ningun activo por asignar"
                  centered={false}
                />
              )}
            </>
          )}
        </FlexColumn>
        <FlexColumn classNames={`${classes.flexFill}`}>
          <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'space-between' })}`}>
            <FlexRow gap={theme.sizing.scale300}>
              <LabelMedium>Asignados {required && '*'}</LabelMedium>
              <LabelMedium color={theme.colors.contentSecondary}>({assignedAssetIds.length})</LabelMedium>
            </FlexRow>
            <Button
              size="mini"
              shape="round"
              kind="tertiary"
              disabled={!selectedAssets?.length}
              onClick={onDeselectAllAssets}
            >
              <MaterialIcon
                name="playlist_remove"
                size="mini"
              />
            </Button>
          </FlexRow>
          {selectedAssets && (
            <>
              {!!selectedAssets?.length && (
                <ul
                  className={css({
                    margin: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    height: '100%',
                  })}
                >
                  <AutoSizer>
                    {({ width, height }) => (
                      <InfiniteLoader
                        isItemLoaded={isAssetsSelectLoaded}
                        itemCount={100}
                        loadMoreItems={loadMoreSelectedAsset}
                      >
                        {({ onItemsRendered, ref }) => (
                          <List
                            height={height ? height : 500}
                            itemCount={assetSelectedCount}
                            itemSize={() => DEFAULT_LIST_ITEM_HEIGHT}
                            width={width ? width : 200}
                            itemData={{
                              assets: selectedAssets,
                              type: 'deselectable',
                              onClick: onDeselectAsset,
                              isLoaded: isAssetsSelectLoaded,
                            }}
                            onItemsRendered={onItemsRendered}
                            overscanCount={4}
                            ref={ref}
                          >
                            {AssetItem}
                          </List>
                        )}
                      </InfiniteLoader>
                    )}
                  </AutoSizer>
                </ul>
              )}
              {searchAsset && !selectedAssets.length && (
                <EmptyState
                  title="No se encontraron resultados"
                  description="Intenta con otra busqueda"
                  centered={false}
                />
              )}
              {!searchAsset && !selectedAssets.length && (
                <EmptyState
                  title="No tienes ningun activo asignado"
                  centered={false}
                />
              )}
            </>
          )}
        </FlexColumn>
      </FlexRow>
    </FullContainer>
  );
}
