import { ContentContainer } from '@gorila-shared-ui/components';
import { useState } from 'react';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import { SubBrandsFiltersBar } from '../../subBrands/SubBrandsFiltersBar';
import { SubBrandsList } from '../../subBrands/SubBrandsList';

export function SubBrandsPage() {
  const { search, setSearch, debouncedSearch } = useDebouncedSearch('');
  const [reloadPage, setReloadPage] = useState(false);
  const [brandId, setBrandId] = useState<string>();

  return (
    <ContentContainer>
      <SubBrandsFiltersBar
        search={search}
        onChangeSearch={setSearch}
        onReloadPage={() => setReloadPage(!reloadPage)}
        selectedBrandId={brandId}
        setSelectedBrandId={setBrandId}
      />
      <SubBrandsList
        search={debouncedSearch}
        reloadPage={reloadPage}
        brandId={brandId}
      />
    </ContentContainer>
  );
}
