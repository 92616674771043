import { IMAGE_EXTENSIONS } from '../constants/app';

export function getFileExtension(filename: string) {
  return filename.substring(filename.lastIndexOf('.'), filename.length) || filename;
}

export function isValidImage(filename: string) {
  const fileExtension = getFileExtension(filename);
  return IMAGE_EXTENSIONS.includes(fileExtension);
}

export function getFileNameFromS3Url(url: string) {
  return url.split('/').pop()?.split('?')[0];
}

export function getFileNameFromPath(path: string) {
  const lastIndex = path.lastIndexOf('/');
  return path.substring(lastIndex + 1);
}

export function download(url: string, filename: string) {
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
}

export function blobToFile(theBlob: Blob, fileName:string): File {
  const b: any = theBlob;
  b.lastModifiedDate = new Date();
  b.name = fileName;
  return theBlob as File;
}
