import { ASSET_TYPE_CONTAINER, ASSET_TYPE_VEHICLE } from '../constants/asset';
import { Asset, AssetMinimal } from '../types/asset';

export const getAssetTitle = (asset: Asset | AssetMinimal, showContainerIdent = false) => {
  let title = '-';
  if (asset.assetType === ASSET_TYPE_VEHICLE) {
    if (asset.vehicle?.brand && asset.vehicle?.subBrand) {
      title = `${asset.vehicle?.brand.name} ${asset.vehicle?.subBrand.name}, ${asset.vehicle?.licensePlate}`.trim();
    } else {
      title = asset.vehicle?.licensePlate ?? '-';
    }
  } else if (asset.assetType === ASSET_TYPE_CONTAINER && asset.container?.identifier) {
    title = 'Contenedor';
    if (showContainerIdent) title += ` - ${asset.container.identifier}`
  }
  return title;
};

export function isArrayOfAssets(assets: any[]): assets is Asset[] {
  return !!(assets[0] as Asset)?._id;
}
