import { FlexRow, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../../../constants/app';
import { CLIENT_STREAMAX_TABLE_COLUMNS_NAME, WEBHOOK_METHODS, WEBHOOK_TYPES } from '../../../../constants/webhooks';
import { useFeedback } from '../../../../hooks/useFeedback';
import { useLoading } from '../../../../hooks/useLoading';
import { useModal } from '../../../../hooks/useModal';
import useUpdateEffect from '../../../../hooks/useUpdateEffect';
import { getWebhooks, removeWebhook } from '../../../../services/webhookService';
import { clientStreamaxSearchFilterState } from '../../../../storage/clients';
import { Webhook } from '../../../../types/webhook';
import { ListWrapper } from '../../../shared/ListWrapper';
import WebhookDetailModal from '../../../webhooks/modal/WebhookDetailModal';

type Props = {
  clientId: string;
  reloadPage?: boolean;
};

export function ClientStreamaxList({ clientId, reloadPage }: Readonly<Props>) {
  const [webhooks, setWebhooks] = useState<Webhook[]>();
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const { loading, startLoading, stopLoading } = useLoading();
  const [selectedWebhook, setSelectedWebhook] = useState<Webhook>();
  const { openModal, closeModal, showModal } = useModal();
  const search = useRecoilValue(clientStreamaxSearchFilterState);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage, search]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    const loadWebhooksTableData = async () => {
      startLoading();
      const response = await getWebhooks({
        clientId,
        page,
        q: search,
      });
      if (response?.error) {
        setWebhooks(undefined);
        setTotalItems(0);
      } else {
        setWebhooks(response?.webhooks);
        setTotalItems(response?.total ?? 0);
      }
      stopLoading();
    };
    loadWebhooksTableData();
  }, [page]);

  const openWebhookModal = (webhook: Webhook) => {
    setSelectedWebhook(webhook);
    openModal();
  };

  const closeWebhookModal = (updateTable?: boolean) => {
    setSelectedWebhook(undefined);
    closeModal();
    if (updateTable) setPage(0);
  };

  const deleteWebhook = async (webhookId: string) => {
    const response = await removeWebhook(clientId, webhookId);
    if (response?.error) {
      showFailFeedback(FEEDBACK.deleted(FEEDBACK_PREFIXES.webhook));
    } else {
      showPositiveFeedback(FEEDBACK.deleted(FEEDBACK_PREFIXES.webhook));
      setPage(0);
    }
  };

  return (
    <>
      <ListWrapper
        columns={CLIENT_STREAMAX_TABLE_COLUMNS_NAME}
        data={webhooks?.map((webhook, i) => {
          return {
            items: [
              webhook.webhookType ? WEBHOOK_TYPES[webhook.webhookType] : '-',
              webhook.method ? WEBHOOK_METHODS[webhook.method] : '-',
              webhook.endpoint ?? '-',
              <FlexRow key={`webhook-action-${i}`}>
                <StyledTooltip
                  content={'Ver detalles'}
                  showArrow={false}
                >
                  <Button
                    size="compact"
                    shape="square"
                    onClick={() => openWebhookModal(webhook)}
                    kind="tertiary"
                  >
                    <MaterialIcon name={'visibility'} />
                  </Button>
                </StyledTooltip>
                <StyledTooltip
                  content={'Borrar'}
                  showArrow={false}
                >
                  <Button
                    size="compact"
                    shape="square"
                    onClick={() => deleteWebhook(webhook._id)}
                    kind="tertiary"
                  >
                    <MaterialIcon name={'delete'} />
                  </Button>
                </StyledTooltip>
                ,
              </FlexRow>,
            ],
          };
        })}
        // hasData={webhooks !== undefined} TODO: uncomment if enable search bar
        hasData={!webhooks?.length || webhooks?.length !== 0} // TODO: comment if enable search bar
        // isEmpty={!webhooks?.length} TODO: uncomment if enable search bar
        isEmpty={false} // TODO: comment if enable search bar
        loading={loading}
        noDataDescription="Todavía no tienes ningun webhook"
        onPageChange={setPage}
        page={page}
        totalItems={totalItems}
      />
      {showModal && (
        <WebhookDetailModal
          onClose={(updateTable) => closeWebhookModal(updateTable)}
          isOpen={showModal}
          webhook={selectedWebhook}
          clientId={clientId}
        />
      )}
    </>
  );
}
