import { FlexRow, MaterialIcon, StyledButton, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useStyles } from '../../hooks/useStyles';
import { CommsReportFilters } from './CommsReportFilters';
import { useModal } from '../../hooks/useModal';
import CommsReportModal from './modal/CommsReportModal';

type Props = {
  onReloadPage: () => void;
  enabledDownload?: boolean;
};
export function CommsReportFiltersBar({ onReloadPage, enabledDownload = false }: Readonly<Props>) {
  const { css, theme } = useStyles();
  const { showModal, openModal, closeModal } = useModal();

  return (
    <FlexRow
      classNames={css({
        justifyContent: 'space-between',
        width: '100%',
      })}
    >
      <FlexRow
        gap={theme.sizing.scale300}
        classNames={css({
          width: '70%',
        })}
      >
        <CommsReportFilters />
      </FlexRow>
      <FlexRow gap={theme.sizing.scale300}>
        <span>
          <StyledButton
            onClick={openModal}
            startEnhancer={
              <MaterialIcon
                name="add"
                size="xs-mini"
                color={theme.colors.contentInversePrimary}
              />
            }
          >
            Nuevo Reporte Offline
          </StyledButton>
        </span>
        <StyledTooltip
          content={'Recargar tabla'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={onReloadPage}
            kind="tertiary"
          >
            <MaterialIcon name={'refresh'} />
          </Button>
        </StyledTooltip>
      </FlexRow>
      <CommsReportModal  
        isOpen={showModal}
        onClose={closeModal}
      />
    </FlexRow>
  );
}
