import { ContentContainer } from '@gorila-shared-ui/components';
import { useState } from 'react';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import { DevicesBrandsFiltersBar } from '../../devicesBrands/DevicesBrandsFiltersBar';
import { DevicesBrandsList } from '../../devicesBrands/DevicesBrandsList';

export function DevicesBrandsPage() {
  const { search, setSearch, debouncedSearch } = useDebouncedSearch('');
  const [reloadPage, setReloadPage] = useState(false);

  return (
    <ContentContainer>
      <DevicesBrandsFiltersBar
        search={search}
        onChangeSearch={setSearch}
        onReloadPage={() => setReloadPage(!reloadPage)}
      />
      <DevicesBrandsList
        search={debouncedSearch}
        reloadPage={reloadPage}
      />
    </ContentContainer>
  );
}
