import { FlexRow, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { DatePicker } from 'baseui/datepicker';
import { useStyles } from '../../../hooks/useStyles';
import { useCommandsReports } from '../hooks/useCommandsReports';

export function CommandsReportFiltersBar() {
  const { css } = useStyles();
  const { selectedCommandsDateRange, resetFilters, onReload, onChangeDates } = useCommandsReports();

  const startDate =
    selectedCommandsDateRange && selectedCommandsDateRange[0] ? new Date(selectedCommandsDateRange[0]) : undefined;
  const endDate =
    selectedCommandsDateRange && selectedCommandsDateRange[1] ? new Date(selectedCommandsDateRange[1]) : undefined;

  return (
    <FlexRow
      classNames={css({ alignItems: 'center' })}
      gap={'4px'}
    >
      <FlexRow
        classNames={css({ flex: '1', alignItems: 'center' })}
        gap={'4px'}
      >
        <div>
          <DatePicker
            placeholder="Fecha inicio - Fecha fin"
            maxDate={new Date()}
            range
            size="compact"
            value={[startDate, endDate]}
            onChange={({ date: dates }) => {
              if (dates instanceof Array) {
                if (dates[0] && dates[1]) {
                  onChangeDates([dates[0].getTime(), dates[1].getTime()]);
                } else if (dates[0]) {
                  onChangeDates([dates[0].getTime(), null]);
                }
              }
            }}
          />
        </div>
        <StyledTooltip
          content={'Limpiar filtros'}
          showArrow={false}
        >
          <Button
            size="mini"
            shape="round"
            onClick={resetFilters}
            kind="tertiary"
          >
            <MaterialIcon
              name={'clear'}
              size="mini"
            />
          </Button>
        </StyledTooltip>
      </FlexRow>
      <StyledTooltip
        content={'Actualizar lista'}
        showArrow={false}
      >
        <Button
          size="mini"
          shape="round"
          onClick={onReload}
          kind="tertiary"
        >
          <MaterialIcon
            name={'refresh'}
            size="mini"
          />
        </Button>
      </StyledTooltip>
    </FlexRow>
  );
}
