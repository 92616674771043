import { ApiError } from '../types/apiResponseError';
import { WebServiceLog, MinimalWebServiceLog } from '../types/webServiceLogs';
import { WsType } from '../types/webService';
import { getRequest } from './api';
import { getRequest as getEmissionRequest, postRequest as postEmissionRequest } from './emissionsApi';
import { URLS } from './urls/urls';
import { LogsTypes, InstallationLog, InstallationLogRequest } from '../types/logs';

export const getWebServiceLogs = async (
  clientId?: string,
  subClientId?: string,
  page?: number,
  q?: string,
  wsType?: WsType
): Promise<{ webServiceLogs?: MinimalWebServiceLog[]; error?: ApiError; total?: number }> => {
  const params = {
    clientId,
    subClientId,
    page,
    q,
    wsType,
  };
  const response = await getRequest(URLS.logs.webServiceList, {
    queryParams: params,
  });
  return {
    webServiceLogs: response?.data?.items,
    total: response?.data?.total,
    error: response?.error,
  };
};

export const getWebServiceLog = async (
  webServiceLogId: string
): Promise<{ webService?: WebServiceLog; error?: ApiError }> => {
  const response = await getRequest(URLS.logs.webServiceDetail(webServiceLogId));
  return {
    webService: response?.data,
    error: response?.error,
  };
};

// emissions
export const getLogsTypesList = async (): Promise<{
  logsTypes?: LogsTypes;
  error?: ApiError;
}> => {
  const response = await getEmissionRequest(URLS.logs.emissionsTypes);
  return {
    logsTypes: response?.data,
    error: response?.error,
  };
};

export const getInstallationLogsList = async (
  installationId: string
): Promise<{
  logs?: InstallationLog[];
  error?: ApiError;
}> => {
  const response = await getEmissionRequest(
    URLS.installations.logsList(installationId)
  );
  return {
    logs: response?.data,
    error: response?.error,
  };
};

export const createLog = async (
  installationId: string,
  installationLogRequest: InstallationLogRequest
): Promise<{ error?: ApiError }> => {
  const response = await postEmissionRequest(
    URLS.installations.newLog(installationId),
    {
      body: installationLogRequest,
    }
  );
  return {
    error: response?.error,
  };
};