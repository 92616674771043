import { FlexColumn, LabeledInput, MaterialIcon } from '@gorila-shared-ui/components';
import { useState } from 'react';
import { REQUIRED_FIELD_TEXT } from '../../constants/app';
import { usePasswordValidation } from '../../hooks/usePasswordValidation';
import { useStyles } from '../../hooks/useStyles';

type Props = {
  password?: string;
  setPassword: (password: string) => void;
  confirmPassword?: string;
  setConfirmPassword: (confirmPassword: string) => void;
};

export function PasswordFormFields({ password, setPassword, confirmPassword, setConfirmPassword }: Readonly<Props>) {
  const { css } = useStyles();
  const { passwordValidation } = usePasswordValidation(password, confirmPassword);
  const [passwordTouched, setPasswordTouched] = useState<boolean>(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState<boolean>(false);

  return (
    <FlexColumn classNames={css({ flex: 1 })}>
      <LabeledInput
        label="Contraseña"
        placeholder="Ingresa tu contraseña"
        value={password}
        onChange={(e) => {
          setConfirmPassword('');
          setPassword(e);
        }}
        type="password"
        startEnhancer={<MaterialIcon name="lock" />}
        onBlur={() => setPasswordTouched(true)}
        error={(!!password && passwordValidation('errors')) || (passwordTouched && !password && REQUIRED_FIELD_TEXT)}
        required
      />
      <LabeledInput
        label="Repetir contraseña"
        placeholder="Confirma tu contraseña"
        value={confirmPassword}
        onChange={setConfirmPassword}
        type="password"
        startEnhancer={<MaterialIcon name="lock" />}
        disabled={!password}
        disabledViewOption
        onBlur={() => setConfirmPasswordTouched(true)}
        error={
          (!!confirmPassword && passwordValidation('repeatPasswordErrors')) ||
          (confirmPasswordTouched && !confirmPassword && REQUIRED_FIELD_TEXT)
        }
        required
      />
    </FlexColumn>
  );
}
