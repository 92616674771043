import { Source, Layer, LineLayer } from 'react-map-gl';
import { useRecoilValue } from 'recoil';
import { showTrafficLayerState } from '../../../storage/map';

export function TrafficLayer() {
  const showTrafficLayer = useRecoilValue(showTrafficLayerState);

  if (!showTrafficLayer) {
    return null;
  }

  const TRAFFIC_LAYER_STYLE: LineLayer = {
    id: 'traffic',
    source: 'mapbox-traffic',
    'source-layer': 'traffic',
    type: 'line',
    paint: {
      'line-width': 1.5,
      'line-color': [
        'case',
        ['==', 'low', ['get', 'congestion']],
        '#aab7ef',
        ['==', 'moderate', ['get', 'congestion']],
        '#4264fb',
        ['==', 'heavy', ['get', 'congestion']],
        '#ee4e8b',
        ['==', 'severe', ['get', 'congestion']],
        '#b43b71',
        '#000000',
      ],
    },
  };

  return (
    <Source
      type="vector"
      url="mapbox://mapbox.mapbox-traffic-v1"
    >
      <Layer {...TRAFFIC_LAYER_STYLE} />
    </Source>
  );
}
