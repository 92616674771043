import { Checkbox } from 'baseui/checkbox';
import { TreeLabelInteractable } from 'baseui/tree-view';

type Props = {
  label: string;
  value: boolean;
  setValue: (val: boolean) => void;
  isIndeterminate: boolean;
  disabled: boolean;
};

export function PermissionTreeItem({ label, value, setValue, isIndeterminate, disabled }: Props) {
  return (
    <TreeLabelInteractable
      overrides={{
        LabelInteractable: {
          component: () => (
            <Checkbox
              disabled={disabled}
              isIndeterminate={isIndeterminate}
              checked={value}
              overrides={{
                Root: {
                  props: {
                    onClick: (event: Event) => {
                      event.stopPropagation();
                      setValue(!value);
                    },
                  },
                },
              }}
            >
              {label}
            </Checkbox>
          ),
        },
      }}
    />
  );
}
