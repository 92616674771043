import { FlexColumn, FlexRow, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useResetRecoilState } from 'recoil';
import { useStyles } from '../../../hooks/useStyles';
import { editUserState, editUserTouchedState } from '../../../storage/adminUsers';
import { AdminUser } from '../../../types/user';
import { UserForm } from '../UserForm';
import { UserInfo } from '../UserInfo';

type Props = {
  isOpen: boolean;
  selectedUser?: AdminUser;
  onClose: (update?: boolean) => void;
};
function UserDetailModal({ onClose, isOpen, selectedUser }: Props) {
  const { css } = useStyles();
  const [user, setUser] = useState<AdminUser>();
  const [editingMode, setEditingMode] = useState(false);
  const resetEditUserState = useResetRecoilState(editUserState);
  const resetEditUserTouchedState = useResetRecoilState(editUserTouchedState);

  useEffect(() => {
    if (isOpen && user) setEditingMode(false);
    return () => {
      clearStates();
    };
  }, [isOpen]);

  const clearStates = () => {
    resetEditUserState();
    resetEditUserTouchedState();
  };

  useEffect(() => {
    if (!selectedUser) {
      setUser(undefined);
      setEditingMode(true);
      return;
    }
    setUser(selectedUser);
  }, [selectedUser]);

  return createPortal(
    <Modal
      animate
      closeable
      onClose={() => onClose()}
      isOpen={isOpen}
      size="auto"
    >
      <FlexRow
        gap={0}
        classNames={css({
          justifyContent: 'space-between',
          margin: '6px 2rem 0px 0px',
        })}
      >
        <ModalHeader
          className={css({
            marginBottom: '0 !important',
          })}
        >
          {user?.username ?? 'Nuevo usuario'}
        </ModalHeader>
        {!editingMode && (
          <StyledTooltip
            content={'Editar'}
            showArrow={false}
          >
            <Button
              size="compact"
              shape="square"
              onClick={() => setEditingMode(true)}
              kind="tertiary"
            >
              <MaterialIcon
                name={'edit'}
                size="xs-mini"
              />
            </Button>
          </StyledTooltip>
        )}
      </FlexRow>
      <ModalBody>
        <FlexColumn
          classNames={css({
            maxWidth: '1200px',
            minWidth: '550px',
            boxSizing: 'border-box',
            padding: '1rem',
            borderTop: '1px solid rgb(226, 226, 226)',
            maxHeight: '80vh',
            overflow: 'auto',
          })}
        >
          {!editingMode && user && <UserInfo user={user} />}
          {editingMode && (
            <UserForm
              user={user}
              onCancel={() => setEditingMode(false)}
              afterSave={() => onClose(true)}
            />
          )}
        </FlexColumn>
      </ModalBody>
    </Modal>,
    document.body
  );
}

export default memo(UserDetailModal);
