import { PaginatedColumn } from '../types/app';

export const WEBHOOK_TYPE_POSITION = 'streamax-positions';
export const WEBHOOK_TYPE_ALARM = 'streamax-alarms';

export const WEBHOOK_TYPES = {
  [WEBHOOK_TYPE_POSITION]: 'Posición',
  [WEBHOOK_TYPE_ALARM]: 'Alarma',
};

export const WEBHOOK_METHOD_GET = 'GET';
export const WEBHOOK_METHOD_POST = 'POST';

export const WEBHOOK_METHODS = {
  [WEBHOOK_METHOD_GET]: 'Get',
  [WEBHOOK_METHOD_POST]: 'Post',
};


export const CLIENT_STREAMAX_TABLE_COLUMNS_NAME: PaginatedColumn[] = [
  {
    label: 'Tipo',
    key: 'webhookType',
  },
  {
    label: 'Metodo',
    key: 'method',
  },
  {
    label: 'url',
    key: 'endpoint',
  },
  {
    label: 'Acciones',
    key: 'actions',
    minWidth: 120,
    maxWidth: 120,
    centered: true,
  },
];

// export const EMPTY_USER: UserMinimal = {
//   _id: '',
//   username: '',
//   name: '',
//   email: '',
//   permissions: {},
//   client: undefined,
//   subClient: undefined,
//   subAccount: undefined,
//   password: undefined,
//   confirmPassword: undefined,
// };
