import { EnhancedLabeledSelect } from '@gorila-shared-ui/components';
import { OnChangeParams } from 'baseui/select';
import { useMemo, useState } from 'react';
import { GRATED_PROJECTS } from '../../../constants/users';
import { REQUIRED_FIELD_TEXT } from '../../../constants/app';


type Props = {
  selectedProjects?: string[];
  onChangeProjects?: (projects?: string[]) => void;
  label?: string;
  placeholder?: string;
  maxDropdownHeight?: string;
  dropdownOffset?: string;
  required?: boolean;
};
export function ProjectGrantedSelect({
  selectedProjects,
  onChangeProjects,
  label = 'Proyectos con acceso concedido',
  placeholder = 'Selecione los proyecto a los cuales tendra acceso',
  maxDropdownHeight,
  dropdownOffset,
  required = true,
}: Readonly<Props>) {
  const hasError = required ? selectedProjects?.length === 0 : false;
  console.log(selectedProjects?.length);
  

  const gratedProjectsOptions = useMemo(() => {
    return Object.entries(GRATED_PROJECTS).map(([key, project]) => ({
      id: key,
      label: project,
    }))
  }, [GRATED_PROJECTS])

  const onProjectChange = (params: OnChangeParams) => {
    if (params.value.length) {
      onChangeProjects && onChangeProjects(params.value.map((val) => val.id).filter((id) => id !== undefined) as string[]);
    } else {
      onChangeProjects && onChangeProjects([]);
    }
  };

  return (
    <EnhancedLabeledSelect
      label={label}
      placeholder={placeholder}
      options={gratedProjectsOptions}
      value={selectedProjects ? selectedProjects?.map((project) => {
        return { id: project };
      }) : undefined}
      onChange={onProjectChange}
      searchable
      maxDropdownHeight={maxDropdownHeight}
      smallContainer={!!dropdownOffset}
      dropdownOffset={dropdownOffset}
      required={required}
      fullWidth
      isMulti
      error={hasError ? REQUIRED_FIELD_TEXT : undefined}
    />
  );
}
