import { useEffect, useRef, useState } from 'react';

export function useElementWidth() {
  const elementRef = useRef<HTMLDivElement>(null);
  const [divRef, refCallback] = useState<HTMLDivElement | null>(null);
  const [elementWidth, setElementWidth] = useState(0);

  useEffect(() => {
    const wrapper = elementRef.current || divRef;
    const onResize = ([
      {
        contentRect: { width },
      },
    ]: { contentRect: { width: number } }[]) => {
      setElementWidth(width);
    };

    let reziseObserver: ResizeObserver;

    if (wrapper) {
      reziseObserver = new ResizeObserver(onResize);
      reziseObserver.observe(wrapper);
    }

    return () => {
      if (wrapper) {
        reziseObserver?.unobserve(wrapper);
      }
    };
  }, [elementRef, divRef]);

  return {
    elementRef,
    elementWidth,
    refCallback,
  };
}
