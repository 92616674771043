import { FlexColumn, FlexRow, FullContainer, Loading } from '@gorila-shared-ui/components';
import { StyledDivider } from 'baseui/divider';
import { TriangleDown, TriangleUp } from 'baseui/icon';
import {
  SortableHeadCell,
  StyledBody,
  StyledCell,
  StyledHead,
  StyledHeadCell,
  StyledRow,
  StyledTable,
} from 'baseui/table';
import { ParagraphSmall } from 'baseui/typography';
import { Fragment } from 'react/jsx-runtime';
import { useStyles } from '../../hooks/useStyles';
import { PaginatedColumn } from '../../types/app';

type Props = {
  data?: {
    items: any[];
    id?: string;
    onClick?: () => void;
  }[];
  columns: PaginatedColumn[];
  onSort?: (column: string) => void;
  loading?: boolean;
  activeId?: string;
  minColumnWidth?: string;
};
export function StyledNoPaginatedTable({
  data,
  columns,
  loading = false,
  activeId,
  onSort,
  minColumnWidth,
}: Readonly<Props>) {
  const { css, theme } = useStyles();

  const tableWidthFromColumns = columns.reduce((accummulated, column) => accummulated + (column.minWidth ?? 0), 0);
  const tableWidth = tableWidthFromColumns > 600 ? tableWidthFromColumns : 600;

  return (
    <FullContainer
      verticalFlex
      classNames={`${css({ gap: theme.sizing.scale300, overflow: 'hidden' })}`}
    >
      <StyledTable>
        <StyledHead
          $width={`${tableWidth}px`}
          style={{ minWidth: '100%' }}
        >
          {columns.map((column, index) => (
            <Fragment key={index}>
              {column.sorteable && onSort && (
                <SortableHeadCell
                  key={column.key}
                  title={column.label}
                  direction={column.sortDirection}
                  onSort={() => onSort(column.label)}
                  fillClickTarget
                  overrides={{
                    SortableLabel: {
                      component: ({ children }) => {
                        return (
                          <FlexRow
                            gap={'4px'}
                            classNames={css({ flex: 1 })}
                          >
                            <ParagraphSmall
                              className={css({ flex: 1 })}
                              margin={0}
                            >
                              {children[1]}
                            </ParagraphSmall>
                            <FlexColumn gap={0}>
                              <TriangleUp
                                size={'12px'}
                                color={
                                  children[0].props.direction === 'ASC' ? undefined : theme.colors.buttonDisabledText
                                }
                              />
                              <TriangleDown
                                size={'12px'}
                                color={
                                  children[0].props.direction === 'DESC' ? undefined : theme.colors.buttonDisabledText
                                }
                              />
                            </FlexColumn>
                          </FlexRow>
                        );
                      },
                    },
                    HeadCell: {
                      style: () => ({
                        minWidth: minColumnWidth ?? column.minWidth ? `${column.minWidth}px` : undefined,
                        maxWidth: column.maxWidth ? `${column.maxWidth}px` : undefined,
                        flex: column.flex ? column.flex : 1,
                        boxSizing: 'border-box',
                        justifyContent: column.centered ? 'center' : undefined,
                        textAlign: column.centered ? 'center' : undefined,
                        alignItems: 'center',
                        padding: '12px 6px',
                      }),
                    },
                  }}
                />
              )}
              {!column.sorteable && (
                <StyledHeadCell
                  key={column.key}
                  style={{
                    minWidth: minColumnWidth ?? column.minWidth ? `${column.minWidth}px` : undefined,
                    maxWidth: column.maxWidth ? `${column.maxWidth}px` : undefined,
                    flex: column.flex ? column.flex : 1,
                    boxSizing: 'border-box',
                    justifyContent: column.centered ? 'center' : undefined,
                    textAlign: column.centered ? 'center' : undefined,
                    alignItems: 'center',
                    padding: '12px 6px',
                  }}
                >
                  <ParagraphSmall
                    className={css({ flex: 1 })}
                    margin={0}
                  >
                    {column.label}
                  </ParagraphSmall>
                </StyledHeadCell>
              )}
            </Fragment>
          ))}
        </StyledHead>
        <StyledBody
          $width={`${tableWidth}px`}
          style={{ minWidth: '100%', overflowY: 'auto' }}
        >
          {loading && <Loading />}
          {!loading &&
            data?.map((row, rowIndex) => (
              <Fragment key={rowIndex}>
                <StyledRow
                  key={`${rowIndex}`}
                  onClick={() => row.onClick && row.onClick()}
                  className={`
                    ${row.onClick ? css({ cursor: 'pointer' }) : ''} 
                    ${activeId && row.id === activeId ? css({ backgroundColor: theme.colors.primary100 }) : ''}
                    ${css({ backgroundColor: (rowIndex + 1) % 2 === 0 ? theme.colors.backgroundSecondary : '' })}
                  `}
                >
                  {row.items.map((item, itemIndex) => (
                    <StyledCell
                      key={`${rowIndex}-${itemIndex}`}
                      style={{
                        minWidth:
                          minColumnWidth ?? columns[itemIndex].minWidth
                            ? `${columns[itemIndex].minWidth}px`
                            : undefined,
                        maxWidth: columns[itemIndex].maxWidth ? `${columns[itemIndex].maxWidth}px` : undefined,
                        flex: columns[itemIndex].flex ? columns[itemIndex].flex : undefined,
                        boxSizing: 'border-box',
                        justifyContent: columns[itemIndex].centered ? 'center' : undefined,
                        padding: '8px 6px',
                      }}
                    >
                      {item}
                    </StyledCell>
                  ))}
                </StyledRow>
                <StyledDivider style={{ width: '100%', marginTop: 0, marginBottom: 0 }} />
              </Fragment>
            ))}
        </StyledBody>
      </StyledTable>
    </FullContainer>
  );
}
