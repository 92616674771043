import { FlexColumn, FlexRow } from '@gorila-shared-ui/components';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { memo } from 'react';
import { createPortal } from 'react-dom';
import { useStyles } from '../../../hooks/useStyles';
import { ClientForm } from '../client/ClientForm';

type Props = {
  isOpen: boolean;
  onClose: (update?: boolean) => void;
};
function NewClientModal({ onClose, isOpen }: Props) {
  const { css } = useStyles();

  return createPortal(
    <Modal
      animate
      closeable
      onClose={() => onClose()}
      isOpen={isOpen}
      size="auto"
    >
      <FlexRow
        gap={0}
        classNames={css({
          justifyContent: 'space-between',
          margin: '6px 2rem 0px 0px',
        })}
      >
        <ModalHeader
          className={css({
            marginBottom: '0 !important',
          })}
        >
          Nuevo
        </ModalHeader>
      </FlexRow>
      <ModalBody>
        <FlexColumn
          classNames={css({
            width: '70vw',
            maxWidth: '1200px',
            minWidth: '350px',
            boxSizing: 'border-box',
            padding: '1rem',
            borderTop: '1px solid rgb(226, 226, 226)',
            maxHeight: '80vh',
          })}
        >
          <ClientForm
            onCancel={onClose}
            afterSave={() => onClose(true)}
          />
        </FlexColumn>
      </ModalBody>
    </Modal>,
    document.body
  );
}

export default memo(NewClientModal);
