import { Block } from 'baseui/block';
import { useStyles } from '../../../hooks/useStyles';
import { GenericComponent } from '../../../types/components';

export function StyledCardBodyWrapper({
  children,
  classNames = '',
  height = 'auto',
}: GenericComponent & { height?: string }) {
  const { css, concatenateClasses, theme } = useStyles();

  const baseStyles = css({
    padding: theme.sizing.scale600,
    height: height,
  });

  return <Block className={concatenateClasses(baseStyles, classNames)}>{children}</Block>;
}
