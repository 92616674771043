export const VEHICLE_TYPE_TRACT = 'tracto';
export const VEHICLE_TYPE_BUS = 'autobus';
export const VEHICLE_TYPE_TRAILER = 'remolque';
export const VEHICLE_TYPE_CAR = 'carro';
export const VEHICLE_TYPE_MOTORCYCLE = 'moto';
export const VEHICLE_TYPE_VAN = 'van';
export const VEHICLE_TYPE_TRUCK = 'camion';
export const VEHICLE_TYPE_PICKUP = 'pickup';
export const VEHICLE_TYPE_MACHINERY = 'maquinaria';

export const VEHICLE_TYPES_OPTIONS = [
  VEHICLE_TYPE_TRACT,
  VEHICLE_TYPE_BUS,
  VEHICLE_TYPE_TRAILER,
  VEHICLE_TYPE_CAR,
  VEHICLE_TYPE_MOTORCYCLE,
  VEHICLE_TYPE_VAN,
  VEHICLE_TYPE_TRUCK,
  VEHICLE_TYPE_PICKUP,
  VEHICLE_TYPE_MACHINERY
]

export const VEHICLE_COLOR_OPTIONS = [
  {
    name: 'Negro',
    value: '#000000',
  },
  {
    name: 'Blanco',
    value: '#F2EEF2',
  },
  {
    name: 'Gris',
    value: '#A6ACAF',
  },
  {
    name: 'Amarillo',
    value: '#F7DC6F',
  },
  {
    name: 'Rojo',
    value: '#E74C3C',
  },
  {
    name: 'Verde',
    value: '#27AE60',
  },
  {
    name: 'Azul',
    value: '#3498DB',
  },
  {
    name: 'Morado',
    value: '#7D3C98',
  },
];