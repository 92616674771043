import { atom, AtomEffect, RecoilState } from 'recoil';

export function persistedAtom<T>(key: string, val: T): RecoilState<T> {
  return atom({
    key: key,
    default: val,
    effects: [localStorageEffect],
  });
}

export const localStorageEffect: AtomEffect<any> = ({ setSelf, onSet, node }) => {
  const savedValue = localStorage.getItem(node.key);
  if (savedValue !== null && savedValue !== 'undefined') {
    setSelf(JSON.parse(savedValue));
  }
  onSet((newValue, _, isReset) => {
    isReset ? localStorage.removeItem(node.key) : localStorage.setItem(node.key, JSON.stringify(newValue));
  });
};
