import { EmptyState, FlexColumn, Loading, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { COMMANDS_REPORTS_TABLE_COLUMNS_NAME } from '../../../constants/commands';
import { useFeedback } from '../../../hooks/useFeedback';
import { useMoment } from '../../../hooks/useMoment';
import { useStyles } from '../../../hooks/useStyles';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { downloadCommandsReportFile } from '../../../services/commandService';
import { CommandReportOffline } from '../../../types/command';
import { StyledPaginatedTable } from '../../shared/StyledPaginatedTable';
import { useCommandsReports } from '../hooks/useCommandsReports';

export function CommandsReportList() {
  const { css, classes } = useStyles();
  const [reports, setReports] = useState<CommandReportOffline[]>();
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const { formatDateFromTs } = useMoment();
  const [loading, setLoading] = useState(true);
  const { reload, loadCommandsReports, selectedCommandsDateRange } = useCommandsReports();
  const { showFailFeedback } = useFeedback();

  useUpdateEffect(() => {
    setPage(0);
  }, [reload]);

  useUpdateEffect(() => {
    if (
      selectedCommandsDateRange &&
      (selectedCommandsDateRange[0] || selectedCommandsDateRange[1]) &&
      (!selectedCommandsDateRange[0] || !selectedCommandsDateRange[1])
    ) {
      return;
    }
    setPage(0);
  }, [selectedCommandsDateRange]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    setLoading(true);
    const loadCommandsTableData = async () => {
      const response = await loadCommandsReports(
        page,
        selectedCommandsDateRange && selectedCommandsDateRange[0],
        selectedCommandsDateRange && selectedCommandsDateRange[1]
      );
      if (response?.error) {
        setReports(undefined);
        setTotalItems(0);
      } else {
        setReports(response?.commands);
        setTotalItems(response?.total ?? 0);
      }
      setLoading(false);
    };
    loadCommandsTableData();
  }, [page]);

  const downloadReport = async (reportId: string) => {
    const { url, error } = await downloadCommandsReportFile(reportId);
    if (!error && url) {
      window.open(url, '_blank');
    } else {
      showFailFeedback(error || 'Ocurrió un error al descargar el reporte, inténtalo más tarde.');
    }
  };

  return (
    <FlexColumn classNames={`${classes.flexFill} ${css({ overflow: 'hidden' })}`}>
      {!reports && !loading && <EmptyState title="Todavía no tienes ningun reporte" />}
      {!reports && loading && <Loading />}
      {reports && (
        <>
          {!!reports.length && (
            <StyledPaginatedTable
              columns={COMMANDS_REPORTS_TABLE_COLUMNS_NAME}
              data={reports.map((report, i) => {
                return {
                  items: [
                    report.name || '-',
                    report.status || '-',
                    report.created ? formatDateFromTs(report.created) : '-',
                    report.status === 'Terminado' && (
                      <StyledTooltip
                        key={`ws-action-${i}`}
                        content={'Descargar'}
                        showArrow={false}
                      >
                        <Button
                          size="compact"
                          shape="square"
                          onClick={() => downloadReport(report._id)}
                          kind="tertiary"
                        >
                          <MaterialIcon name={'download'} />
                        </Button>
                      </StyledTooltip>
                    ),
                  ],
                };
              })}
              totalItems={totalItems}
              page={page}
              onPageChange={setPage}
              loading={loading}
            />
          )}
          {!reports.length && (
            <EmptyState
              title="No se encontraron resultados"
              description="Intenta con otra busqueda"
            />
          )}
        </>
      )}
    </FlexColumn>
  );
}
