import { SetterOrUpdater } from 'recoil';
import { REQUIRED_FIELD_TEXT } from '../constants/app';

export function useForms<T1, T2>(setForm: SetterOrUpdater<T1>, setFormTouched: SetterOrUpdater<T2>) {
  const onChangeInput = (key: keyof T1, value: any) => {
    setForm((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onTouched = (key: keyof T2) => {
    setFormTouched((prev) => ({
      ...prev,
      [key]: true,
    }));
  };

  const onValidateField = (isTouched: boolean, isValid: boolean, hasValue?: boolean, customMsg?: React.ReactNode) => {
    if (isTouched && !isValid) {
      if (hasValue) 
        return customMsg ?? REQUIRED_FIELD_TEXT;
      else 
        return REQUIRED_FIELD_TEXT;
    }
    return; 
  }

  return {
    onChangeInput,
    onTouched,
    onValidateField
  };
}
