import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { getAvailableCommands } from '../../services/commandService';
import { commandsState } from '../../storage/commands';

function AvailableCommandsProvider() {
  const setCommand = useSetRecoilState(commandsState);

  useEffect(() => {
    const loadCommandTypes = async () => {
      const { commands } = await getAvailableCommands();
      if (commands) {
        setCommand(commands)
      }
    };
    loadCommandTypes();
  });

  return null;
}

export default AvailableCommandsProvider;
