import { ContentContainer } from '@gorila-shared-ui/components';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import { whatsappsSearchState } from '../../../storage/whatsapp';
import { WhatsappFiltersBar } from '../../whatsapp/WhatsappFiltersBar';
import { WhatsappList } from '../../whatsapp/WhatsappList';

export function WhatsappListPage() {
  const { search, setSearch } = useDebouncedSearch(useRecoilState(whatsappsSearchState));
  const [reloadPage, setReloadPage] = useState(false);

  return (
    <ContentContainer>
      <WhatsappFiltersBar
        search={search}
        onChangeSearch={setSearch}
        onReloadPage={() => setReloadPage(!reloadPage)}
      />
      <WhatsappList
        search={search}
        reloadPage={reloadPage}
      />
    </ContentContainer>
  );
}
