import { atom } from 'recoil';

export const commandsReportDateRangeState = atom<(number | null)[] | undefined>({
  key: 'app.commands.offline.filter.dateRange',
  default: undefined,
});

export const commandsReportReloadListState = atom<boolean>({
  key: 'app.commands.offline.list.reload',
  default: false,
});
