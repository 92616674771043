import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { installationsStatusState } from "../../storage/Installations";
import { getInstallationsStatusList } from "../../services/installationService";

function InsatallationStatusProvider() {
  const setInstallationsStatus = useSetRecoilState(installationsStatusState);

  useEffect(() => {
    const getInstallationStatus = async () => {
      const { installationsStatus } = await getInstallationsStatusList();
      if (installationsStatus) setInstallationsStatus(installationsStatus);
      else setInstallationsStatus(undefined);
    };
    getInstallationStatus();
  });

  return null;
}

export default InsatallationStatusProvider;
