import { ALL_PAGES } from '../constants/app';

export const getPageTitle = (pageId: string) => {
  return ALL_PAGES.find((page) => page.id === pageId)?.name;
};

export const getPageNavKey = (pageId: string) => {
  return ALL_PAGES.find((page) => page.id === pageId)?.navKey;
};

export const getPageRouteByNavKey = (navKey: string) => {
  return ALL_PAGES.find((page) => page.navKey === navKey)?.route;
};

export const getPageRoute = (id: string) => {
  return ALL_PAGES.find((page) => page.id === id)?.route;
};

export const getPageIdByReportTypeRoute = (reportTypeRoute: string) => {
  return ALL_PAGES.find((page) => page.route === reportTypeRoute)?.id
}
