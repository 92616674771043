import { MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { SUB_ACCOUNTS_TABLE_COLUMNS_NAME } from '../../../constants/subAccounts';
import { useLoading } from '../../../hooks/useLoading';
import { useModal } from '../../../hooks/useModal';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { getSubAccounts } from '../../../services/subAccountService';
import { subAccountsSearchFilterState } from '../../../storage/subAccounts';
import { SubAccount } from '../../../types/subAccount';
import { ListWrapper } from '../../shared/ListWrapper';
import SubAccountDetailModal from './modal/SubAccountDetailModal';

type Props = {
  clientId: string;
  subClientId: string;
  reloadPage?: boolean;
};

export function SubAccountList({ clientId, subClientId, reloadPage }: Readonly<Props>) {
  const [subAccounts, setSubAccounts] = useState<SubAccount[]>();
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const { loading, startLoading, stopLoading } = useLoading();
  const search = useRecoilValue(subAccountsSearchFilterState);
  const [selectedSubAccountId, setSelectedSubAccountId] = useState<string>();
  const { openModal, closeModal, showModal } = useModal();

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage, search]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadSubAccountsTableData = async () => {
      const response = await getSubAccounts({
        subClientId,
        page,
        q: search,
      });
      if (response?.error) {
        setSubAccounts(undefined);
        setTotalItems(0);
      } else {
        setSubAccounts(response?.subAccounts);
        setTotalItems(response?.total ?? 0);
      }
      stopLoading();
    };
    loadSubAccountsTableData();
  }, [page]);

  const openSubAccountModal = (subAccountId: string) => {
    setSelectedSubAccountId(subAccountId);
    openModal();
  };

  const closeSubAccountModal = (updateTable?: boolean) => {
    setSelectedSubAccountId(undefined);
    closeModal();
    if (updateTable) setPage(0);
  };

  return (
    <>
      <ListWrapper
        columns={SUB_ACCOUNTS_TABLE_COLUMNS_NAME}
        data={subAccounts?.map((subAccount, i) => {
          return {
            items: [
              subAccount.name,
              <StyledTooltip
                key={`subAccount-action-${i}`}
                content={'Ver detalles'}
                showArrow={false}
              >
                <Button
                  size="compact"
                  shape="square"
                  onClick={() => openSubAccountModal(subAccount._id)}
                  kind="tertiary"
                >
                  <MaterialIcon name={'visibility'} />
                </Button>
              </StyledTooltip>,
            ],
          };
        })}
        hasData={subAccounts !== undefined}
        isEmpty={!subAccounts?.length}
        loading={loading}
        noDataDescription="Todavía no tienes ninguna subcuenta"
        onPageChange={setPage}
        page={page}
        totalItems={totalItems}
      />
      {showModal && (
        <SubAccountDetailModal
          onClose={(updateTable) => closeSubAccountModal(updateTable)}
          isOpen={showModal}
          selectedSubAccountId={selectedSubAccountId}
          clientId={clientId}
          subClientId={subClientId}
        />
      )}
    </>
  );
}
