import { atom } from "recoil";

export const phoneBlSearchState = atom<string | undefined>({
  key: "app.phone.blacklist.filter.search",
  default: undefined,
});

export const phoneBlReloadListState = atom<boolean>({
  key: "app.phone.blacklist.list.reload",
  default: false,
});
