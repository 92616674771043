import { LogsEvent, datadogLogs } from "@datadog/browser-logs";
import { ApiError as AwsApiError } from "aws-amplify/api";
import { ApiError, ValidationError } from "../types/apiResponseError";

const omittedErrors = ["Fetch is aborted", "api.mapbox.com"]; // TODO: Add 'api.mapbox.com' if needed

const getErrorValidationMessage = (data: any): ApiError => {
  if (data.detail) {
    return data.detail.map((e: ValidationError) => `Error: ${e.msg},`);
  } else if (typeof data === "string") {
    return `Error: ${data}`;
  }
  return "Ups algo salio mal";
};

const getErrorClientMessage = (
  data: any
): { errMsg: ApiError; datadogLog: { msg: string; logMsg: {} } } => {
  if (typeof data === "string") {
    return {
      errMsg: `Error: ${data}`,
      datadogLog: {
        msg: data,
        logMsg: {},
      },
    };
  }
  return {
    errMsg: data.msg ? `Error: ${data.msg}` : "Ups algo salio mal",
    datadogLog: {
      msg: data.msg,
      logMsg: data.logMsg,
    },
  };
};

export const getErrorMessage = (error: any, metadata = {}): ApiError => {
  if (error instanceof AwsApiError && error.response?.body) {
    const { body, statusCode } = error.response;
    const data = JSON.parse(body);

    //The error message when is validation error
    if (statusCode === 422) {
      const errMsg = getErrorValidationMessage(data);
      datadogLogs.logger.warn(
        errMsg.toString(),
        { logMsg: {}, metadata },
        error
      );
      return errMsg;
    }

    //The error message when the error is from the client
    if (statusCode >= 400 && statusCode <= 500) {
      const { errMsg, datadogLog } = getErrorClientMessage(data);
      datadogLogs.logger.warn(
        datadogLog.msg,
        { logMsg: datadogLog.logMsg, metadata },
        error
      );
      return errMsg;
    }
  }

  //Default error message
  datadogLogs.logger.error("", { metadata }, error as Error);
  return "Ups algo salio mal";
};

export const discardError = (log: LogsEvent): boolean => {
  // Discard errors
  if (omittedErrors.some((e) => log.error?.stack?.includes(e))) return true;
  // Discard mapbox network errors
  // TODO: uncomment next line if needed
  // if (log.origin === 'network' && log.http?.url.includes('api.mapbox.com')) return true;
  return false;
};
