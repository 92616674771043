import { Centered, FullScreenContainer } from '@gorila-shared-ui/components';
import { useState } from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import {
  ASSETS_PAGE,
  BRANDS_PAGE,
  BULK_PAGE,
  CLIENTS_PAGE,
  CLIENT_DETAIL_PAGE,
  CLIENT_PAGE,
  COMMANDS_PAGE,
  COMMANDS_REPORT_OFFLINE_PAGE,
  COMMS_REPORT_OFFLINE_PAGE,
  DEVICES_PAGE,
  DEVICE_BRANDS_PAGE,
  DEVICE_MODELS_PAGE,
  DEVICE_MODEL_COMMANDS_PAGE,
  EMISSION_REPORT_PAGE,
  INSTALLATIONS_DETAIL_PAGE,
  INSTALLATIONS_PAGE,
  PHONE_BLACKLIST_PAGE,
  REPORTS_PANELS_PAGE,
  SAFEGPT_CONFIG_PAGE,
  SUB_BRANDS_PAGE,
  SUB_CLIENT_DETAIL_PAGE,
  SUB_CLIENT_PAGE,
  USERS_ADMIN_PAGE,
  USERS_PAGE,
  VERSIONS_PAGE,
  WEB_SERVICE_LOGS_PAGE,
  WHATSAPP_PAGE,
} from '../../constants/app';
import useEffectOnce from '../../hooks/useEffectOnce';
import { useStyles } from '../../hooks/useStyles';
import { CrmLayout } from '../layout/CrmLayout';
import { AdminUsersPage } from '../pages/admin/AdminUsersPage';
import { WhatsappListPage } from '../pages/campaigns/WhatsappListPage';
import { CommandsPage } from '../pages/devices/CommandsPage';
import { CommandsReportPage } from '../pages/devices/CommandsReportPage';
import { DeviceModelCommandsPage } from '../pages/devices/DeviceModelCommandsPage';
import { DevicesBrandsPage } from '../pages/devices/DevicesBrandsPage';
import { DevicesModelsPage } from '../pages/devices/DevicesModelsPage';
import { DevicesPage } from '../pages/devices/DevicesPage';
import { EmissionsReportPage } from '../pages/installations/EmissionsReportPage';
import { InstallationPage } from '../pages/installations/InstallationPage';
import { InstallationsPage } from '../pages/installations/InstallationsPage';
import { PhonesBlacklistPage } from '../pages/installations/PhonesBlacklistPage';
import { LoginPage } from '../pages/LoginPage';
import { AssetsPage } from '../pages/platform/AssetsPage';
import { BulkPage } from '../pages/platform/BulkPage';
import { ClientPage } from '../pages/platform/ClientPage';
import { ClientsPage } from '../pages/platform/ClientsPage';
import { CommsReportOfflinePage } from '../pages/platform/CommsReportOfflinePage';
import { SubClientPage } from '../pages/platform/SubClientPage';
import { UsersPage } from '../pages/platform/UsersPage';
import { WebServiceLogsPage } from '../pages/platform/WebServiceLogsPage';
import { ReportsPanelsPage } from '../pages/reports/reportsPanelsPage';
import { ServerErrorPage } from '../pages/ServerErrorPage';
import { BrandsPage } from '../pages/vehicles/BrandsPage';
import { SubBrandsPage } from '../pages/vehicles/SubBrandsPage';
import { VersionsPage } from '../pages/vehicles/VersionsPage';
import { ErrorElementBoundary } from '../shared/ErrorBoundary';
import { SafeGptConfigListPage } from '../pages/safeGpt/SafeGptConfigListPage';

const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />,
    errorElement: <ErrorElementBoundary />,
  },
  {
    id: 'home',
    element: <CrmLayout />,
    errorElement: <ErrorElementBoundary />,
    children: [
      {
        id: CLIENTS_PAGE.id,
        path: CLIENTS_PAGE.route,
        element: <ClientsPage />,
      },
      {
        id: CLIENT_PAGE.id,
        path: CLIENT_PAGE.route,
        element: <ClientPage />,
        children: [
          {
            id: CLIENT_DETAIL_PAGE.id,
            path: CLIENT_DETAIL_PAGE.route,
            element: <ClientPage />,
          },
        ],
      },
      {
        id: SUB_CLIENT_PAGE.id,
        path: SUB_CLIENT_PAGE.route,
        element: <SubClientPage />,
        children: [
          {
            id: SUB_CLIENT_DETAIL_PAGE.id,
            path: SUB_CLIENT_DETAIL_PAGE.route,
            element: <SubClientPage />,
          },
        ],
      },
      {
        id: USERS_PAGE.id,
        path: USERS_PAGE.route,
        element: <UsersPage />,
      },
      {
        id: ASSETS_PAGE.id,
        path: ASSETS_PAGE.route,
        element: <AssetsPage />,
      },
      {
        id: BULK_PAGE.id,
        path: BULK_PAGE.route,
        element: <BulkPage />,
      },
      {
        id: BRANDS_PAGE.id,
        path: BRANDS_PAGE.route,
        element: <BrandsPage />,
      },
      {
        id: SUB_BRANDS_PAGE.id,
        path: SUB_BRANDS_PAGE.route,
        element: <SubBrandsPage />,
      },
      {
        id: VERSIONS_PAGE.id,
        path: VERSIONS_PAGE.route,
        element: <VersionsPage />,
      },
      {
        id: DEVICES_PAGE.id,
        path: DEVICES_PAGE.route,
        element: <DevicesPage />,
      },
      {
        id: DEVICE_MODELS_PAGE.id,
        path: DEVICE_MODELS_PAGE.route,
        element: <DevicesModelsPage />,
      },
      {
        id: DEVICE_MODEL_COMMANDS_PAGE.id,
        path: DEVICE_MODEL_COMMANDS_PAGE.route,
        element: <DeviceModelCommandsPage />,
      },
      {
        id: DEVICE_BRANDS_PAGE.id,
        path: DEVICE_BRANDS_PAGE.route,
        element: <DevicesBrandsPage />,
      },
      {
        id: COMMANDS_PAGE.id,
        path: COMMANDS_PAGE.route,
        element: <CommandsPage />,
      },
      {
        id: COMMANDS_REPORT_OFFLINE_PAGE.id,
        path: COMMANDS_REPORT_OFFLINE_PAGE.route,
        element: <CommandsReportPage />,
      },
      {
        id: COMMS_REPORT_OFFLINE_PAGE.id,
        path: COMMS_REPORT_OFFLINE_PAGE.route,
        element: <CommsReportOfflinePage />,
      },
      {
        id: WEB_SERVICE_LOGS_PAGE.id,
        path: WEB_SERVICE_LOGS_PAGE.route,
        element: <WebServiceLogsPage />,
      },
      {
        id: INSTALLATIONS_PAGE.id,
        path: INSTALLATIONS_PAGE.route,
        element: <InstallationsPage />,
      },
      {
        id: INSTALLATIONS_DETAIL_PAGE.id,
        path: INSTALLATIONS_DETAIL_PAGE.route,
        element: <InstallationPage />,
      },
      {
        id: PHONE_BLACKLIST_PAGE.id,
        path: PHONE_BLACKLIST_PAGE.route,
        element: <PhonesBlacklistPage />,
      },
      {
        id: EMISSION_REPORT_PAGE.id,
        path: EMISSION_REPORT_PAGE.route,
        element: <EmissionsReportPage />,
      },
      {
        id: USERS_ADMIN_PAGE.id,
        path: USERS_ADMIN_PAGE.route,
        element: <AdminUsersPage />,
      },
      {
        id: REPORTS_PANELS_PAGE.id,
        path: REPORTS_PANELS_PAGE.route,
        element: <ReportsPanelsPage />,
      },
      {
        id: WHATSAPP_PAGE.id,
        path: WHATSAPP_PAGE.route,
        element: <WhatsappListPage />,
      },
      {
        id: SAFEGPT_CONFIG_PAGE.id,
        path: SAFEGPT_CONFIG_PAGE.route,
        element: <SafeGptConfigListPage />,
      },
      {
        id: 'main',
        path: '/',
        element: (
          <Navigate
            to={CLIENTS_PAGE.route}
            replace
          />
        ),
      },
    ],
  },
  {
    id: '404',
    path: '/*',
    element: <Centered>404</Centered>,
  },
  {
    id: '500',
    path: '/500',
    element: <ServerErrorPage />,
  },
]);

export function AppRoutes() {
  const { classes, theme } = useStyles();
  const [loading, setLoading] = useState(true);

  useEffectOnce(() => {
    document.fonts.ready.then(() => {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  });

  return (
    <FullScreenContainer>
      <RouterProvider router={router} />
      {loading && (
        <div
          className={`${classes.verticalFlex} ${classes.centeredFlex}`}
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            background: theme.colors.backgroundInversePrimary,
            zIndex: 100,
          }}
        >
          <img
            alt="Gorila MX"
            src="/img/login_logo.png"
            height={50}
            style={{
              animation: 'pulse 2s infinite',
            }}
          />
        </div>
      )}
    </FullScreenContainer>
  );
}
