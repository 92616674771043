import { useStyles } from '../../hooks/useStyles';

type Props = {
  height?: number;
  width?: number;
};

export function VerticalDivider({ height, width }: Props) {
  const { theme, css } = useStyles();
  return (
    <div
      className={css({
        width: `${width || 1}px`,
        height: height ? `${height}px` : theme.sizing.scale700,
        background: theme.colors.borderOpaque,
      })}
    />
  );
}
