import { FlexColumn } from '@gorila-shared-ui/components';
import { Avatar } from 'baseui/avatar';
import { LabelSmall } from 'baseui/typography';
import { useRecoilValue } from 'recoil';
import { useStyles } from '../../../hooks/useStyles';
import { authUserState, sidenavIsPinnedState } from '../../../storage/app';

export function UserCard() {
  const { css, theme } = useStyles();
  const user = useRecoilValue(authUserState);
  const sidenavIsPinned = useRecoilValue(sidenavIsPinnedState);

  if (!user) return null;

  return (
    <FlexColumn
      centered
      classNames={css({ padding: theme.sizing.scale600 })}
    >
      <Avatar
        name={user.username}
        size={sidenavIsPinned ? 'scale1600' : 'scale850'}
      />
      {sidenavIsPinned && <LabelSmall className={css({ textAlign: 'center' })}>{user.username}</LabelSmall>}
    </FlexColumn>
  );
}
