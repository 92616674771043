
import { LibraryOptions } from '@aws-amplify/core';
import { ResourcesConfig } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';
import { environment } from './environments/environment';

export const awsConf: ResourcesConfig = {
  API: {
    REST: {
      [environment.awsAPIEndpointName]: {
        endpoint: environment.awsAPIEndpointUrl ?? '',
      },
      [environment.awsAPIEmissionsEndpointName]: {
        endpoint: environment.awsAPIEmissionsEndpointUrl ?? '',
      },
    },
  },
  Auth: {
    Cognito: {
      userPoolId: environment.awsUserPoolsId ?? '', // Amazon Cognito User Pool ID
      userPoolClientId: environment.awsUserPoolsWebClientId ?? '', // Amazon Cognito Web Client ID
      identityPoolId: environment.awsCognitoIdentityPool ?? '', // Amazon Cognito Identity Pool ID
    },
  },
  Storage: {
    S3: {
      bucket: environment.awsMediaS3Bucket, // Amazon S3 bucket name
      region: environment.awsMediaS3BucketRegion, // Amazon service region
    },
  },
};

export const awsLibraryConf: LibraryOptions = {
  API: {
    REST: {
      headers: async () => {
        return { Authorization: `Bearer ${(await fetchAuthSession()).tokens?.idToken}` };
      },
    },
  },
}
