import { FlexRow, StyledButton } from '@gorila-shared-ui/components';
import { useStyles } from '../../hooks/useStyles';

type Props = {
  canSubmit: boolean;
  canContinue: boolean;
  loading: boolean;
  onSubmit: () => void;
  onContinue: () => void;
  onReturn?: () => void;
  onCancel?: () => void;
  editMode?: boolean;
  isLastStep?: boolean;
  submitText?: string;
  cancelText?: string;
  returnText?: string;
  continueText?: string;
};

export function FormStepsActionsRow({
  canSubmit,
  canContinue,
  loading,
  onSubmit,
  onReturn,
  onContinue,
  onCancel,
  editMode = false,
  submitText,
  cancelText = 'Cancelar',
  returnText = 'Regresar',
  continueText = 'Siguiente',
  isLastStep = false,
}: Readonly<Props>) {
  const { classes, theme } = useStyles();

  if (!submitText) {
    submitText = editMode ? 'Actualizar' : 'Crear';
  }

  return (
    <FlexRow classNames={classes.centeredEnd}>
      <FlexRow gap={theme.sizing.scale300}>
        {onReturn && (
          <StyledButton
            onClick={onReturn}
            kind={"tertiary"}
          >
            {returnText}
          </StyledButton>
        )}
        {onCancel && (
          <StyledButton
            onClick={onCancel}
            kind="tertiary"
          >
            {cancelText}
          </StyledButton>
        )}
        {!isLastStep && <StyledButton
          onClick={onContinue}
          disabled={!canContinue}
        >
          {continueText}
        </StyledButton>}
        {isLastStep && <StyledButton
          onClick={onSubmit}
          isLoading={loading}
          disabled={!canSubmit}
        >
          {submitText}
        </StyledButton>}
      </FlexRow>
    </FlexRow>
  );
}
