import { useEffect, useState } from 'react';
import { SetterOrUpdater } from 'recoil';
import { useDebounceValue } from 'usehooks-ts';
import useUpdateEffect from './useUpdateEffect';

export function useDebouncedSearch(searchInput: [string | undefined, SetterOrUpdater<string | undefined>] | string ) {
  const [searchLocal, setSearchLocal] = useState<string | undefined>(typeof searchInput === 'string' ? searchInput : searchInput[0]);
  const [debouncedSearch, setDebouncedSearch] = useDebounceValue<string | undefined>(searchLocal, 500);

  useUpdateEffect(() => {
    if (typeof searchInput !== 'string') {
      const setSearch = searchInput[1];
      setSearch(searchLocal);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    setDebouncedSearch(searchLocal);
  }, [searchLocal]);

  return { search: searchLocal, setSearch: setSearchLocal, debouncedSearch };
}
