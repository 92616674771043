import { EllipsedText, MaterialIcon, StyledTooltip } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { DEVICE_TABLE_COLUMNS_NAME } from '../../constants/device';
import { useLoading } from '../../hooks/useLoading';
import { useModal } from '../../hooks/useModal';
import { useMoment } from '../../hooks/useMoment';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { getDevicesList } from '../../services/deviceService';
import { Device } from '../../types/device';
import { ListWrapper } from '../shared/ListWrapper';
import DeviceDetailModal from './modal/DeviceDetailModal';

type Props = {
  search?: string;
  reloadPage?: boolean;
  clientId?: string;
  subClientId?: string;
};
export function DevicesList({ search, reloadPage, clientId, subClientId }: Readonly<Props>) {
  const [devices, setDevices] = useState<Device[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const { openModal, closeModal, showModal } = useModal();
  const [selectedDevice, setSelectedDevice] = useState<Device>();
  const { formatDateFromTs } = useMoment();

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage, search, clientId, subClientId]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadDevices = async () => {
      const { devices, error, total } = await getDevicesList(page, search, clientId, subClientId);
      if (!error && devices) {
        setDevices(devices);
        setTotalItems(total);
      } else {
        setDevices(undefined);
        setTotalItems(0);
      }
      stopLoading();
    };
    loadDevices();
  }, [page]);

  const openDetailModal = (device: Device) => {
    setSelectedDevice(device);
    openModal();
  };

  const closeDetailModal = (updateTable?: boolean) => {
    setSelectedDevice(undefined);
    closeModal();
    if (updateTable) setPage(0);
  };

  return (
    <>
      <ListWrapper
        columns={DEVICE_TABLE_COLUMNS_NAME}
        data={devices?.map((device, i) => {
          return {
            items: [
              <EllipsedText key={`device-ident-${i}`}>{device.ident}</EllipsedText>,
              <EllipsedText key={`device-model-${i}`}>{device.model.name}</EllipsedText>,
              <EllipsedText key={`device-brand-${i}`}>{device.brand.name}</EllipsedText>,
              <EllipsedText key={`device-client-${i}`}>{device.asset?.client?.name ?? '-'}</EllipsedText>,
              <EllipsedText key={`device-subClient-${i}`}>{device.asset?.subClient?.name ?? '-'}</EllipsedText>,
              <EllipsedText key={`device-cs-${i}`}>
                {device?.cs?.timestamp ? formatDateFromTs(device.cs.timestamp) : '-'}
              </EllipsedText>,
              <EllipsedText key={`device-hsWithoutAsset-${i}`}>
                {device?.hsWithoutAsset?.timestamp ? formatDateFromTs(device.hsWithoutAsset.timestamp) : '-'}
              </EllipsedText>,
              <StyledTooltip
                key={`details-${device._id}`}
                content={'Ver detalle'}
                showArrow={false}
              >
                <Button
                  size="compact"
                  shape="square"
                  onClick={() => openDetailModal(device)}
                  kind="tertiary"
                >
                  <MaterialIcon name={'visibility'} />
                </Button>
              </StyledTooltip>,
            ],
          };
        })}
        hasData={devices !== undefined}
        isEmpty={!devices?.length}
        loading={loading}
        noDataDescription="Todavía no tienes ningun dispositivo"
        onPageChange={setPage}
        page={page}
        totalItems={totalItems}
      />
      {showModal && (
        <DeviceDetailModal
          onClose={(updateTable) => closeDetailModal(updateTable)}
          isOpen={showModal}
          selectedDevice={selectedDevice}
        />
      )}
    </>
  );
}
