import { EmptyState } from '@gorila-shared-ui/components';
import { WebService } from '../../../types/webService';
import { SearchableAssetList } from '../../shared/SearchableAssetList';

type Props = {
  webService: WebService;
};
export function WebServiceInfo({ webService }: Readonly<Props>) {
  return webService.assignedAssets.length ? (
    <SearchableAssetList
      assetIds={webService.assignedAssets}
      subClientId={webService.subClient?._id}
    />
  ) : (
    <EmptyState description="No tiene activos asignados" />
  );
}
