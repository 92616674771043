import { EllipsedText, FlexColumn, LabeledData } from '@gorila-shared-ui/components';
import { LabelMedium } from 'baseui/typography';
import { WHATSAPP_RECORDS_TABLE_COLUMNS_NAME } from '../../constants/whatsapp';
import { useMoment } from '../../hooks/useMoment';
import { useStyles } from '../../hooks/useStyles';
import { Whatsapp } from '../../types/whatsapp';
import { ListWrapper } from '../shared/ListWrapper';

type Props = {
  whatsapp: Whatsapp;
};
export function WhatsappDetailData({ whatsapp }: Props) {
  const { theme, classes, css } = useStyles();
  const { formatDateFromTs } = useMoment();
  const labelWidht = '70px';

  return (
    <FlexColumn
      gap={theme.sizing.scale100}
      classNames={css({ overflow: 'hidden' })}
    >
      <LabeledData
        label={'Nombre:'}
        content={whatsapp.name ?? '-'}
        labelWidth={labelWidht}
      />
      <LabeledData
        label={'Ejecutado:'}
        content={whatsapp.executed ? 'Si' : 'No'}
        labelWidth={labelWidht}
      />
      <LabeledData
        label={'Creado:'}
        content={whatsapp.created ? formatDateFromTs(whatsapp.created, 'DD/MM/yyyy HH:mm') : '-'}
        labelWidth={labelWidht}
      />
      <FlexColumn classNames={css({ marginTop: theme.sizing.scale300, overflow: 'hidden' })}>
        <LabelMedium className={classes.dataHeaderClass}>Registros</LabelMedium>
        <ListWrapper
          columns={WHATSAPP_RECORDS_TABLE_COLUMNS_NAME}
          data={
            whatsapp.records?.map((record, i) => {
              return {
                items: [
                  <EllipsedText key={`whatsapp-name-${i}`}>{`${record.number || '-'}`}</EllipsedText>,
                  <EllipsedText key={`whatsapp-created-${i}`}>{record.error || '-'}</EllipsedText>,
                  <EllipsedText key={`whatsapp-executed-${i}`}>{record.isValid ? 'Si' : 'No'}</EllipsedText>,
                  <EllipsedText key={`whatsapp-created-${i}`}>
                    {record.processedAt ? formatDateFromTs(record.processedAt, 'DD/MM/yyyy HH:mm') : '-'}
                  </EllipsedText>,
                ],
              };
            }) ?? []
          }
          loading={false}
          hasData={whatsapp.records !== undefined}
          isEmpty={!whatsapp.records?.length}
          noDataDescription="Esta campaña no tiene ningun registro"
        />
      </FlexColumn>
    </FlexColumn>
  );
}
