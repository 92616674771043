export const PHONES_BLACKLIST_TABLE_COLUMNS_NAME = [
  {
    label: "Teléfono",
    key: "phone",
  },
  {
    label: "",
    key: "actions",
  },
];
