import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { logsTypesState } from "../../storage/Installations";
import { getLogsTypesList } from "../../services/logsService";


function LogsTypesProvider() {
  const setInstallationsStatus = useSetRecoilState(logsTypesState);

  useEffect(() => {
    const getLogTypes = async () => {
      const { logsTypes } = await getLogsTypesList();
      if (logsTypes) setInstallationsStatus(logsTypes);
      else setInstallationsStatus(undefined);
    };
    getLogTypes();
  });

  return null;
}

export default LogsTypesProvider;
