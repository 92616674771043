import { atom, selector } from 'recoil';
import { commandsState } from './commands';
import { CommandOrigin } from '../types/command';

export const availableCommandLabelsState = selector<{ [key: string]: string } | undefined>({
  key: 'app.commands.labels',
  get: ({ get }) => {
    const commands = get(commandsState);
    if (!commands?.length) return;
    const commandLabels: { [key: string]: string } = {};
    commands.forEach((command) => {
      commandLabels[command.commandName] = command.commandDescription;
    });
    return commandLabels;
  },
});

export const commandsReloadListState = atom<boolean>({
  key: 'app.commands.list.reload',
  default: false,
});

export const commandsDateRangeState = atom<(number | null)[] | undefined>({
  key: 'app.commands.filter.dateRange',
  default: undefined,
});

export const commandsSearchFilterState = atom<string | undefined>({
  key: 'app.commands.filter.search',
  default: undefined,
});

export const commandsStatusFilterState = atom<string | undefined>({
  key: 'app.commands.filter.status',
  default: undefined,
});

export const commandsTypeFilterState = atom<string | undefined>({
  key: 'app.commands.filter.type',
  default: undefined,
});

export const commandsHasAnyFilterState = selector<boolean>({
  key: 'app.commands.filter.any',
  get: ({ get }) => {
    const filterValues = [get(commandsDateRangeState), get(commandsStatusFilterState), get(commandsTypeFilterState)];
    return filterValues.some((value) => value !== undefined);
  },
});

export const commandsOriginFilterState = atom<CommandOrigin | undefined>({
  key: 'app.commands.filter.origin',
  default: undefined,
});

export const commandsDownloadListState = atom<boolean>({
  key: 'app.commands.list.download',
  default: false,
});
