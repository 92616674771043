import { selector, selectorFamily } from 'recoil';
import { Permissions } from '../types/permissions';
import { persistedAtom } from '../utils/persistence';
import { AppPermission } from './../types/appPermission';

export const appPermissionState = persistedAtom<AppPermission | undefined>('app.permission', undefined);

export const filteredAppPermissionState = selector<AppPermission | undefined>({
  key: 'app.permission.filtered',
  get: ({ get }) => {
    const appPermission = get(appPermissionState);
    if (!appPermission) return;
    const { children } = appPermission;
    if (!children) return;

    const permissions: { [key: string]: AppPermission } = {};
    Object.values(children).forEach((permission) => {
      const permissionName = permission.name;
      if (permissionName === 'settings') {
        if (permission.children) {
          const sections: { [key: string]: AppPermission } = {};
          Object.values(permission.children).forEach((section) => {
            if (section.name !== 'subAccount' && section.name !== 'users' && section.name !== 'webServices') {
              sections[section.name] = section;
            }
          });
          permissions[permissionName] = {
            ...permission,
            children: sections,
          };
        }
      } else {
        permissions[permissionName] = permission;
      }
    });   
    return {
      ...appPermission,
      children: permissions,
    };
  },
});

export const userPermissionsDisabledState = selectorFamily<Permissions | { [key: string]: any }, boolean>({
  key: 'app.userPermissions.disabled',
  get:
    (filtered) =>
    ({ get }) => {
      const appPermission = filtered ? get(filteredAppPermissionState) : get(appPermissionState);
      if (!appPermission) return {};
      const { children } = appPermission;
      if (!children) return {};

      const permissions: Permissions = {};
      Object.values(children).forEach((permission) => {
        const permissionName = permission.name;
        if (permissionName === 'commands') {
          permissions[permissionName] = {
            enabled: false,
            availableCommands: [],
          };
        } else if (permissionName === 'settings') {
          if (permission.children) {
            const availableSections: { [key: string]: { enabled: boolean } } = {};
            Object.values(permission.children).forEach((section) => {
              availableSections[section.name] = { enabled: false };
            });
            permissions[permissionName] = {
              enabled: false,
              availableSections,
            };
          }
        } else if (permissionName === 'dashboard') {
          if (permission.children) {
            const availableActions: { [key: string]: { enabled: boolean } } = {};
            Object.values(permission.children).forEach((section) => {
              availableActions[section.name] = { enabled: false };
            });
            permissions[permissionName] = {
              enabled: false,
              availableActions,
            };
          }
        } else {
          permissions[permissionName] = {
            enabled: false,
          };
        }
      });
      return permissions;
    },
});

export const userPermissionsEnabledState = selectorFamily<Permissions | { [key: string]: any }, boolean>({
  key: 'app.userPermissions.enabled',
  get:
    (filtered) =>
    ({ get }) => {
      const appPermission = filtered ? get(filteredAppPermissionState) : get(appPermissionState);
      if (!appPermission) return {};
      const { children } = appPermission;
      if (!children) return {};

      const permissions: Permissions = {};
      Object.values(children).forEach((permission) => {
        const permissionName = permission.name;
        if (permissionName === 'commands') {
          permissions[permissionName] = {
            enabled: true,
            availableCommands: appPermission.children
              ? Object.keys(appPermission.children['commands'].children || {})
              : [],
          };
        } else if (permissionName === 'settings') {
          if (permission.children) {
            const availableSections: { [key: string]: { enabled: boolean } } = {};
            Object.values(permission.children).forEach((section) => {
              availableSections[section.name] = { enabled: true };
            });
            permissions[permissionName] = {
              enabled: true,
              availableSections,
            };
          }
        } else if (permissionName === 'dashboard') {
          if (permission.children) {
            const availableActions: { [key: string]: { enabled: boolean } } = {};
            Object.values(permission.children).forEach((section) => {
              availableActions[section.name] = { enabled: true };
            });
            permissions[permissionName] = {
              enabled: true,
              availableActions,
            };
          }
        } else {
          permissions[permissionName] = {
            enabled: true,
          };
        }
      });
      return permissions;
    },
});
