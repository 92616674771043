import { LabelMedium } from 'baseui/typography';
import { useRecoilValue } from 'recoil';
import { useStyles } from '../../../hooks/useStyles';
import { pageState } from '../../../storage/app';
import { getPageTitle } from '../../../utils/pages';


export function PageTitle() {
  const { css } = useStyles();
  const page = useRecoilValue(pageState);
  const title = getPageTitle(page);

  if (!title) return <span className={css({ flex: 1 })} />;

  return <LabelMedium marginRight={'auto'}>{title}</LabelMedium>;
}
