import { FlexRow } from '@gorila-shared-ui/components';
import { useRecoilState } from 'recoil';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { useStyles } from '../../hooks/useStyles';
import {
  userTypeFilterState,
  usersClientIdFilterState,
  usersSubAccountIdFilterState,
  usersSubClientIdFilterState,
} from '../../storage/users';
import { RolePicker } from '../shared/RolePicker';
import { ClientSelect } from '../shared/selects/ClientSelect';
import { SubAccountSelect } from '../shared/selects/SubAccountSelect';
import { SubClientSelect } from '../shared/selects/SubClientSelect';

export function UserFilters() {
  const { css, theme, classes } = useStyles();
  const { isSmall } = useBreakpoints();

  const [selectedUserType, setSelectedUserType] = useRecoilState(userTypeFilterState);
  const [selectedClientId, setSelectedClientId] = useRecoilState(usersClientIdFilterState);
  const [selectedSubClientId, setSelectedSubClientId] = useRecoilState(usersSubClientIdFilterState);
  const [selectedSubAccountId, setSelectedSubAccountId] = useRecoilState(usersSubAccountIdFilterState);

  const handleClientChange = (clientId?: string) => {
    if (clientId !== selectedClientId) {
      setSelectedClientId(clientId);
      setSelectedSubClientId(undefined);
    }
  };

  return (
    <FlexRow
      gap={theme.sizing.scale300}
      classNames={`${classes.fillParent} ${css({
        flexDirection: isSmall ? 'column' : 'row',
      })}`}
    >
      <RolePicker
        onChange={setSelectedUserType}
        userType={selectedUserType}
        inline
        isFilter
      />
      <ClientSelect
        selectedClientId={selectedClientId}
        onChangeClientId={handleClientChange}
        inline
      />
      <SubClientSelect
        selectedClientId={selectedClientId}
        selectedSubClientId={selectedSubClientId}
        onChangeSubClientId={setSelectedSubClientId}
        disabled={!selectedClientId}
        inline
      />
      <SubAccountSelect
        selectedSubClientId={selectedSubClientId}
        selectedSubAccountId={selectedSubAccountId}
        onChangeSubAccount={setSelectedSubAccountId}
        disabled={!selectedSubClientId}
        inline
      />
    </FlexRow>
  );
}
